import {GET_MATCHIT_DATA} from '../actions/types';
// import { bindActionCreators } from 'redux';

const initialState = {
  data: [
    {
    name: 'mark',
    games_played: 3,
    invites: 3
    }
  ]
}

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_MATCHIT_DATA:
      return {
        ...state
      }
    default:
      return {...state}
  }
}