import {GET_TRANSACTION_HISTORY} from '../actions/types';

const initial_state = {
  transactionHistory: [],
  loading: false
}

export default function(state = initial_state, action) {
  switch(action.type) {
    case GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: action.payload,
        loading: action.loading
      }
    default:
      return {
        ...state
      }
  }
}