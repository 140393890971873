import {
  GET_MONEY,
  CHECK_EMAIL,
  CHECK_PENDING,
  POST_EMAIL,
  UPDATE_EMAIL,
} from "../actions/types";

const initial_state = {
  balance: [],
  checkEmail: "",
  checkPending: false,
  postEmailStatus: {},
  updateEmailStatus: {},
};

export default function (state = initial_state, action) {
  switch (action.type) {
    case GET_MONEY:
      return {
        ...state,
        balance: action.payload,
      };
    case CHECK_EMAIL:
      return {
        ...state,
        checkEmail: action.payload,
      };
    case CHECK_PENDING:
      return {
        ...state,
        checkPending: action.payload,
      };
    case POST_EMAIL:
      return {
        ...state,
        postEmailStatus: action.payload.data.success,
        checkEmail: { ...state.checkEmail, receiver: action.postedEmail },
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        updateEmailStatus: action.payload,
      };
    default:
      return state;
  }
}
