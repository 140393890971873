import {DISPLAY_ALL_GAME} from '../actions/types';

const initialState = {
  games: [
  ],
  loading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_ALL_GAME: {
      return {...state, games: action.payload, loading: action.loading}
    }
    default: {
      return {...state}
    }    
  }

}