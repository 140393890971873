import React from "react";
import "./App.css";
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import Game from "./components/pages/Game";
import PageNotAvailable from "./components/pages/PageNotAvailable";
import MatchItYoutube from "./components/pages/MatchItYoutube";
import Transaction from "./components/modal/payout/Transaction";
import TransactionHistory from "./components/modal/payout/TransactionHistory";
import TransactionPayout from "./components/modal/payout/TransactionPayout";
import TransactionBreakdown from "./components/modal/payout/TransactionBreakdown";
import TransactionSuccess from "./components/modal/payout/TransactionSuccess";
import MatchItAds from "./components/pages/MatchItAds";
import Profile from "./components/pages/Profile";
import GameGuide from "./components/pages/GameGuide";
import ErrorBoundary from "../src/components/pages/ErrorBoundary";
import NewGame from "../src/components/pages/NewGame";
import NewGameNumbers from "../src/components/pages/NewGameNumbers";
import SaveYourEmail from "../src/components/pages/SaveYourEmail";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/game/:id/:user_id?" component={Game} />
            <Route exact path="/howtoplay" component={MatchItYoutube} />
            <Route exact path="/gameads" component={MatchItAds} />
            <Route exact path="/guide" component={GameGuide} />
            <Route exact path="/errorboundary" component={ErrorBoundary} />
            <Route
              exact
              path="/pagenotavailable"
              component={PageNotAvailable}
            />
            <Route exact path="/newgame" component={NewGame} />
            <Route exact path="/newgamenum" component={NewGameNumbers} />

            {/* <Route path="*" component={() =>
              <React.Fragment>
              <h1 className="container text-center text-light mt-5 display-4">Session Expired!</h1>
               <p className="text-center text-light">
                  Please open the game through your app to play! 
               </p>
              </React.Fragment>
            }
            /> */}

            {/* For Test. Please remove afterwards*/}

            <Route exact path="/profile" component={Profile} />
            <Route exact path="/saveyouremail" component={SaveYourEmail} />

            <Route exact path="/transaction" component={Transaction} />
            <Route
              exact
              path="/transaction-history"
              component={TransactionHistory}
            />
            <Route
              exact
              path="/transaction-payout"
              component={TransactionPayout}
            />
            <Route
              exact
              path="/transaction-breakdown"
              component={TransactionBreakdown}
            />
            <Route
              exact
              path="/transaction-success"
              component={TransactionSuccess}
            />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
