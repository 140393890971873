import React from "react";
import ChooseYourGame from "../modal/ChooseYourGame";

const PickNumbers = props => {
  const {
    handleSecondClick,
    handleFirstClick,
    numbersDraw,
    id,
    name,
    user_id,
    start_date,
    apiToken,
    boardOrders,
    pickedNumbers,
    clickedClass,
    firstClickStatus,
    firstClickDrawId,
    firstClickDelay,
    secondClickChooseForMeLateRender,
    secondClickForNothing,
    drawIdHandle,
    redirectStillPlaysAds,
    number_picking,
    number_picking_cycle,
    handleNA
  } = props;
  return (
    <div>
      {(() => {
        if (boardOrders && numbersDraw == "" && pickedNumbers == "") {
          return (
            <React.Fragment>
              <ChooseYourGame
                game_id={id}
                api_token={apiToken}
                user_id={user_id}
                number_picking={number_picking}
                number_picking_cycle={number_picking_cycle}
              />
            </React.Fragment>
          );
        } else if (boardOrders && numbersDraw == "" && pickedNumbers) {
          return (
            <React.Fragment>
              {number_picking_cycle != 1 && (
                <div className="game-nogame">
                  {JSON.parse(sessionStorage.getItem("chooseForMe")).noGames}
                </div>
              )}
              <div className="game-notstarted">
                <h2>
                  {JSON.parse(sessionStorage.getItem("chooseForMe")).startOn}{" "}
                  <br />
                  {start_date}
                  &nbsp;{number_picking_cycle === 1 && "6:01AM"}
                </h2>
              </div>
              <div className="game-start-time not">
                <div className="container">
                  <div className="game-time-detail">
                    <div className="game-time-detail-container flex justify-content-around">
                      <div className="text-center number-of-hour taken">
                        <p>12:01AM</p>
                        <div className="number-container">
                          <div className="midnight bg-style-time"></div>
                          <h2></h2>
                        </div>
                      </div>
                      <div className="text-center number-of-hour taken">
                        <p>6:01AM</p>
                        <div className="number-container">
                          <div className="morning bg-style-time"></div>
                          <h2></h2>
                        </div>
                      </div>
                      <div className="text-center number-of-hour taken">
                        <p>12:01PM</p>
                        <div className="number-container">
                          <div className="afternoon bg-style-time"></div>
                          <h2></h2>
                        </div>
                      </div>
                      <div className="text-center number-of-hour taken">
                        <p>6:01PM</p>
                        <div className="number-container">
                          <div className="night bg-style-time"></div>
                          <h2></h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="game-time">
                    <div className="panel"></div>
                    <div className="panel"></div>
                    <div className="panel"></div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        } else if (boardOrders && numbersDraw && pickedNumbers) {
          return (
            <React.Fragment>
              <div className="game-start-time">
                <div className="container">
                  <div className="game-time-detail">
                    <div className="game-time-detail-container flex justify-content-around">
                      <div
                        className={
                          numbersDraw[0].picked_or_already_drawn
                            ? "text-center number-of-hour taken"
                            : "text-center number-of-hour"
                        }
                      >
                        <p>
                          {number_picking_cycle === 1
                            ? numbersDraw[0].time_to_display
                            : "12:01AM"}
                        </p>
                        <div
                          className="number-container"
                          onClick={
                            numbersDraw[0].picked_or_already_drawn ||
                            clickedClass === true
                              ? () => {
                                  "";
                                }
                              : firstClickStatus === true &&
                                firstClickDrawId == numbersDraw[0].data.id &&
                                (numbersDraw[0].is_dash === false ||
                                  !numbersDraw[0].is_dash)
                              ? handleSecondClick.bind(this, {
                                  user_id,
                                  id,
                                  apiToken,
                                  name,
                                  number_picking_cycle
                                })
                              : firstClickStatus === false &&
                                redirectStillPlaysAds === false &&
                                (numbersDraw[0].is_dash === false ||
                                  !numbersDraw[0].is_dash)
                              ? handleFirstClick.bind(this, {
                                  user_id,
                                  id,
                                  apiToken,
                                  name,
                                  number_picking_cycle
                                })
                              : handleNA
                          }
                        >
                          <div className="midnight bg-style-time"></div>
                          <h2
                            value={numbersDraw[0].data.draw_number}
                            name="box_1"
                            numberid={numbersDraw[0].data.id}
                            timemarker={numbersDraw[0].data.time_marker}
                          >
                            {numbersDraw[0].is_dash === true ? (
                              <p className="chooseNum pt-2">-</p>
                            ) : numbersDraw[0].picked_or_already_drawn ||
                              (firstClickDrawId == numbersDraw[0].data.id &&
                                firstClickDelay) ? (
                              numbersDraw[0].data.draw_number
                            ) : !numbersDraw[0].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : !numbersDraw[0].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == false &&
                              secondClickForNothing != true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : drawIdHandle == numbersDraw[0].data.id ? (
                              numbersDraw[0].data.draw_number
                            ) : (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            )}
                          </h2>
                        </div>
                      </div>
                      <div
                        className={
                          numbersDraw[1].picked_or_already_drawn
                            ? "text-center number-of-hour taken"
                            : "text-center number-of-hour"
                        }
                      >
                        <p>
                          {number_picking_cycle === 1
                            ? numbersDraw[1].time_to_display
                            : "6:01AM"}
                        </p>
                        <div
                          className="number-container"
                          onClick={
                            numbersDraw[1].picked_or_already_drawn ||
                            clickedClass === true
                              ? () => {
                                  "";
                                }
                              : firstClickStatus === true &&
                                firstClickDrawId == numbersDraw[1].data.id &&
                                (numbersDraw[1].is_dash === false ||
                                  !numbersDraw[1].is_dash)
                              ? handleSecondClick.bind(this, {
                                  user_id,
                                  id,
                                  apiToken,
                                  name,
                                  number_picking_cycle
                                })
                              : firstClickStatus === false &&
                                redirectStillPlaysAds === false &&
                                (numbersDraw[1].is_dash === false ||
                                  !numbersDraw[1].is_dash)
                              ? handleFirstClick.bind(this, {
                                  user_id,
                                  id,
                                  apiToken,
                                  name,
                                  number_picking_cycle
                                })
                              : handleNA
                          }
                        >
                          <div className="morning bg-style-time"></div>
                          <h2
                            value={numbersDraw[1].data.draw_number}
                            name="box_2"
                            numberid={numbersDraw[1].data.id}
                            timemarker={numbersDraw[1].data.time_marker}
                          >
                            {numbersDraw[1].is_dash === true ? (
                              <p className="chooseNum pt-2">-</p>
                            ) : numbersDraw[1].picked_or_already_drawn ||
                              (firstClickDrawId == numbersDraw[1].data.id &&
                                firstClickDelay) ? (
                              numbersDraw[1].data.draw_number
                            ) : !numbersDraw[1].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : !numbersDraw[1].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == false &&
                              secondClickForNothing != true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : drawIdHandle == numbersDraw[1].data.id ? (
                              numbersDraw[1].data.draw_number
                            ) : (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            )}
                          </h2>
                        </div>
                      </div>
                      <div
                        className={
                          numbersDraw[2].picked_or_already_drawn
                            ? "text-center number-of-hour taken"
                            : "text-center number-of-hour"
                        }
                        onClick={
                          numbersDraw[2].picked_or_already_drawn ||
                          clickedClass === true
                            ? () => {
                                "";
                              }
                            : firstClickStatus === true &&
                              firstClickDrawId == numbersDraw[2].data.id &&
                              (numbersDraw[2].is_dash === false ||
                                !numbersDraw[2].is_dash)
                            ? handleSecondClick.bind(this, {
                                user_id,
                                id,
                                apiToken,
                                name,
                                number_picking_cycle
                              })
                            : firstClickStatus === false &&
                              redirectStillPlaysAds === false &&
                              (numbersDraw[2].is_dash === false ||
                                !numbersDraw[2].is_dash)
                            ? handleFirstClick.bind(this, {
                                user_id,
                                id,
                                apiToken,
                                name,
                                number_picking_cycle
                              })
                            : handleNA
                        }
                      >
                        <p>
                          {number_picking_cycle === 1
                            ? numbersDraw[2].time_to_display
                            : "12:01PM"}
                        </p>
                        <div className="number-container">
                          <div className="afternoon bg-style-time"></div>
                          <h2
                            value={numbersDraw[2].data.draw_number}
                            name="box_3"
                            numberid={numbersDraw[2].data.id}
                            timemarker={numbersDraw[2].data.time_marker}
                          >
                            {numbersDraw[2].is_dash === true ? (
                              <p className="chooseNum pt-2">-</p>
                            ) : numbersDraw[2].picked_or_already_drawn ||
                              (firstClickDrawId == numbersDraw[2].data.id &&
                                firstClickDelay) ? (
                              numbersDraw[2].data.draw_number
                            ) : !numbersDraw[2].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : !numbersDraw[2].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == false &&
                              secondClickForNothing != true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : drawIdHandle == numbersDraw[2].data.id ? (
                              numbersDraw[2].data.draw_number
                            ) : (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            )}
                          </h2>
                        </div>
                      </div>
                      <div
                        className={
                          numbersDraw[3].picked_or_already_drawn
                            ? "text-center number-of-hour taken"
                            : "text-center number-of-hour"
                        }
                        onClick={
                          numbersDraw[3].picked_or_already_drawn ||
                          clickedClass === true
                            ? () => {
                                "";
                              }
                            : firstClickStatus === true &&
                              firstClickDrawId == numbersDraw[3].data.id &&
                              (numbersDraw[3].is_dash === false ||
                                !numbersDraw[3].is_dash)
                            ? handleSecondClick.bind(this, {
                                user_id,
                                id,
                                apiToken,
                                name,
                                number_picking_cycle
                              })
                            : firstClickStatus === false &&
                              redirectStillPlaysAds === false &&
                              (numbersDraw[3].is_dash === false ||
                                !numbersDraw[3].is_dash)
                            ? handleFirstClick.bind(this, {
                                user_id,
                                id,
                                apiToken,
                                name,
                                number_picking_cycle
                              })
                            : handleNA
                        }
                      >
                        <p>
                          {number_picking_cycle === 1
                            ? numbersDraw[3].time_to_display
                            : "6:01PM"}
                        </p>
                        <div className="number-container">
                          <div className="night bg-style-time"></div>
                          <h2
                            value={numbersDraw[3].data.draw_number}
                            name="box_4"
                            numberid={numbersDraw[3].data.id}
                            timemarker={numbersDraw[3].data.time_marker}
                          >
                            {numbersDraw[3].is_dash === true ? (
                              <p className="chooseNum pt-2">-</p>
                            ) : numbersDraw[3].picked_or_already_drawn ||
                              (firstClickDrawId == numbersDraw[3].data.id &&
                                firstClickDelay) ? (
                              numbersDraw[3].data.draw_number
                            ) : !numbersDraw[3].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : !numbersDraw[3].picked_or_already_drawn &&
                              secondClickChooseForMeLateRender == false &&
                              secondClickForNothing != true ? (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            ) : drawIdHandle == numbersDraw[3].data.id ? (
                              numbersDraw[3].data.draw_number
                            ) : (
                              <p className="chooseNum">
                                {
                                  JSON.parse(
                                    sessionStorage.getItem("gameTranslations")
                                  ).click4Number
                                }
                              </p>
                            )}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="game-time">
                    <div className="panel__main"></div>
                    {/* <div className="panel"></div>
                        <div className="panel"></div>
                        <div className="panel"></div>
                      */}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }
      })()}
    </div>
  );
};
export default PickNumbers;
