import { TRANSLATION } from '../actions/types';

import i18next from "i18next";

const initialState = {
  defaultLang: '',
  backToApp: '',
  language: '',
  viewVideo: '',
  totalLifelineWins: '',
  grossLifeline: '',
  charityDonation: '',
  personalCharity: '',
  dailyBreakdown: '',
  weeklyTreeWinnings: '',
  totalLifelineWinnings: '',
  personalGameWinnings: '',
  dailyQualifiedLifeline: '',
  weeklyQualifiedLifeline: '',
  lifelinePlayingForYou: '',
  totalPlayersLifeline: '',
  play: '',
  share: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSLATION: {
      return { ...action.translations }
    }
    default: {
      return { ...state }
    }
  }

}