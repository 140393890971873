import { GET_LIFELINE_STATS } from "../actions/types";

const initial_state = {
  lifelineStats: [],
  loading: false
};

export default function(state = initial_state, action) {
  switch (action.type) {
    case GET_LIFELINE_STATS:
      return {
        ...state,
        lifelineStats: action.payload,
        loading: action.loading
      };
    default:
      return {
        ...state
      };
  }
}
