import {
  CHOOSE_MYSELF,
  PICKED_NUMBERS,
  REMOVE_STATE_CHOOSE,
  CLEAR_CHOOSE_MYSELF_GAME_ID,
  CLEAR_WINNERS_DATA_FROM_CHOOSEFORME_STATE
} from "../actions/types";

const initialState = [
  { isLoading: false, matchForTiles: false },
  { forLoading: false }
];

export default function(state = initialState, action) {
  switch (action.type) {
    case CHOOSE_MYSELF: {
      const { game_id, mySelf } = action;
      return {
        ...state,
        game_id,
        mySelf
      };
    }
    case PICKED_NUMBERS: {
      return {
        ...state,
        isLoading: true,
        matchForTiles: true,
        winners: [action.payload]
      };
    }
    case REMOVE_STATE_CHOOSE: {
      return { ...state, mySelf: false };
    }
    case CLEAR_CHOOSE_MYSELF_GAME_ID: {
      return { ...state, game_id: "" };
    }
    case CLEAR_WINNERS_DATA_FROM_CHOOSEFORME_STATE: {
      return { ...state, winners: "" };
    }
    default: {
      return state;
    }
  }
}
