import {CLOSE_MODAL, SET_MODAL,CHOOSE_YOUR_GAME_MODAL ,CHOOSE_YOUR_GAME_MODAL_TRUE} from '../actions/types'; 

const initialState = {
  data: [
    { showModalPrizes: false, showModalWinners: false, showModalSettings: false, showModalHelp: false}
  ],
  modalComponent: '',
  chooseYourGameState: true
}

export default function(state = initialState, action){
    switch (action.type) {
      case CLOSE_MODAL: 
        return {
          ...state,
          modalComponent: ''
        }
      case SET_MODAL:
        return {
          ...state,
          modalComponent: action.payload
        }
      case CHOOSE_YOUR_GAME_MODAL: {
        return {
          ...state,
          chooseYourGameState: false
        }
      }
      case CHOOSE_YOUR_GAME_MODAL_TRUE: {
        return {
          ...state,
          chooseYourGameState: true
        }
      }
      default:
        return {
          ...state
        }
    }

}