import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

class TransactionSuccess extends PureComponent {
  render() {
    return (
      <div className="transaction__page">
        <div className="transaction__innerpage">
          <div className="box__inner">
            <h1 className="transaction__title">Payout Confirmation</h1>
            <div className="transaction__success">
              <i className="ion ion-ios-hourglass"></i>
              <p>
                {" "}
                Payout Request Pending! <br />
                You may <Link to="/">Return to Game List </Link>
                while your transaction is being processed. Thank you!{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransactionSuccess;
