import React, { PureComponent } from "react";
import axios from "axios";
import DisplayAllGames from "./DisplayAllGames";
import { Link, withRouter } from "react-router-dom";
import authHOC from "../HOC/AuthHOC";
import Menu from "../layout/Menu";
import queryString from "query-string";
import { connect } from "react-redux";
import logo from "../../static/gif/load_alt.svg";
import {
  displayAllgame,
  transactionHistory,
  lifelineStats,
  applyTranslation,
} from "../../actions/actions";
import { getMoney } from "../../actions/actions";
import DailyQualified from "../modal/qualified/DailyQualified";
import UpdateEmail from "../modal/updateEmail/UpdateEmail";
import WeeklyQualified from "../modal/qualified/WeeklyQualified";
import DailyWinnings from "../modal/winnings/Daily";
import WeeklyWinnings from "../modal/winnings/Weekly";
import Language from "../modal/language/Language";

import i18next from "i18next";

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.expand = this.expand.bind(this);
  }

  state = {
    homeUserInfo: {},
    isLoading: true,
    areExpanded: false,
    showDailyQualified: false,
    showWeeklyQualified: false,
    showDailyWinnings: false,
    showWeeklyWinnings: false,
    showLanguage: false,
    mobileID: 0,
    isEn: true,
    needToUpdateEmail: false,
    userData: {},
  };

  changeLang = (lang) => {
    i18next.changeLanguage(lang).then(() => {
      i18next.options.lng = lang;
      i18next.t("key");

      sessionStorage.setItem("defaultLang", lang);

      if (lang != "en") {
        this.setState({
          isEn: false,
        });
      } else {
        this.setState({
          isEn: true,
        });
      }
      this.props.changeTranslation({
        backToApp: i18next.t("text.back-to-app"),
        language: i18next.t("text.language"),
        viewVideo: i18next.t("text.view-video"),
        totalLifelineWins: i18next.t("text.total-weekly-lifelines"),
        grossLifeline: i18next.t("text.gross-lifeline"),
        charityDonation: i18next.t("text.charity-donation"),
        personalCharity: i18next.t("text.personal-charity-donation"),
        dailyBreakdown: i18next.t("text.daily-breakdown"),
        fullBreakdown: i18next.t("text.full-breakdown"),
        weeklyTreeWinnings: i18next.t("text.weekly-tree-winning"),
        totalLifelineWinnings: i18next.t("text.total-lifeline-winnings"),
        personalGameWinnings: i18next.t("text.personal-game-winnings"),
        dailyQualifiedLifeline: i18next.t("text.daily-qualified-lifeline"),
        weeklyQualifiedLifeline: i18next.t("text.weekly-qualified-lifeline"),
        lifelinePlayingForYou: i18next.t("text.lifeline-playing-for-you"),
        totalPlayersLifeline: i18next.t("text.total-players-lifeline"),
        play: i18next.t("text.play"),
        howToPlay: i18next.t("text.how-to-play"),
        prizes: i18next.t("text.prizes"),
        share: i18next.t("text.share"),
        winnersCircle: i18next.t("text.winners-circle"),
        settings: i18next.t("text.settings"),
        info: i18next.t("text.info"),
        dailyGameNotif: i18next.t("text.daily-game-begins"),
        weeklyGameNotif: i18next.t("text.sponsor-ads"),
        dailyGame: i18next.t("text.daily-game"),
        weeklyGame: i18next.t("text.weekly-game"),
        differenceWeekly: i18next.t("text.difference-weekly-game"),
        whereTheMoneyComesFrom: i18next.t("text.where-the-money-comes-from"),
        requirementsNeeded: i18next.t("text.requirements-needed"),
        pickLuckyNumber: i18next.t("text.pick-lucky-number"),
        ourGameCalled: i18next.t("text.our-game-called"),
        whatIsWinningCircle: i18next.t("text.what-is-winning-circle"),
        winningCirclePayout: i18next.t("text.winning-circle-payout"),
        winningCircleDiagram: i18next.t("text.winner-diagram"),
        exampleWinningPayout: i18next.t("text.example-winning"),
        fundraisingExplanation: i18next.t("text.fundraising-explanation"),
        fundraisingPayout: i18next.t("text.fundraising-payout"),
        circlePayout: i18next.t("text.circle-payout"),
        directory: i18next.t("text.directory"),
        settings: i18next.t("text.settings"),
        profile: i18next.t("text.profile"),
        winnings: i18next.t("text.winnings"),
        lifelineTree: i18next.t("text.lifeline-tree"),
        charities: i18next.t("text.charities"),
        rules: i18next.t("text.rules"),
        notifications: i18next.t("text.notifications"),
        enableNotifications: i18next.t("text.enable-notifications"),
        winningCombinations: i18next.t("text.winning-combination"),
        weekly: i18next.t("text.weekly"),
        daily: i18next.t("text.daily"),
        noPatternTwo: i18next.t("text.no-pattern-required"),
        eightMatched: i18next.t("text.8-matched"),
        nineMatched: i18next.t("text.9-matched"),
        tenMatched: i18next.t("text.10-matched"),
        elevenMatched: i18next.t("text.11-matched"),
        twelveMatched: i18next.t("text.12-matched"),
        horizontalLine: i18next.t("text.horizontal-line"),
        blockofSix: i18next.t("text.block-of-6"),
        sevenGame: i18next.t("text.7-number-game"),
        outerPoints: i18next.t("text.outer-points"),
        bullsEye: i18next.t("text.bulls-eye"),
        yPattern: i18next.t("text.y-pattern"),
        sevenOdds: i18next.t("text.7-odds"),
        sevenEvens: i18next.t("text.7-evens"),
        eightOdds: i18next.t("text.8-odds"),
        eightEvens: i18next.t("text.8-evens"),
        continueToPlay: i18next.t("text.continue-to-play"),
        thisIsQuiteSimple: i18next.t("text.this-is-quite-simple"),
        sharedWinnings: i18next.t("text.shared-winnings"),
        claimedNumbers: i18next.t("text.claimed-numbers"),
        maintainRequirements: i18next.t("text.maintaining-requirements"),
        yourResponsibility: i18next.t("text.your-responsibility"),
        name: i18next.t("text.name"),
        email: i18next.t("text.email"),
        phoneNumber: i18next.t("text.phone-number"),
        timezone: i18next.t("text.timezone"),
        city: i18next.t("text.city"),
        country: i18next.t("text.country"),
        state: i18next.t("text.state"),
        updateProfile: i18next.t("text.update-profile"),
        outerPoints: i18next.t("text.outer-points"),
        winnings: i18next.t("text.winnings"),
        requestPayout: i18next.t("text.request-payout"),
        transactionHistory: i18next.t("text.transaction-history"),
        currentWinnings: i18next.t("text.current-winnings"),
        clickNumber: i18next.t("text.click-number"),
        numberAvailable: i18next.t("text.number-becomes-available"),
        eachNumber: i18next.t("text.each-time"),
        winner: i18next.t("text.winner"),
        payoutSystem: i18next.t("text.payout-system"),
        numberPick: i18next.t("text.numbers-didnt-pick"),
        opportunityWin: i18next.t("text.opportunity-to-win"),
        winningPattern: i18next.t("text.winning-pattern"),
        winningNumber: i18next.t("text.winning-number"),
        ifWinning: i18next.t("text.if-winning-number"),
        everyHour: i18next.t("text.every-hour-notified"),
        matchingGame: i18next.t("text.matching-game"),
        opportunityEachDay: i18next.t("text.opportunity-each-day"),
        divideWinners: i18next.t("text.divide-winners"),
        playersAround: i18next.t("text.players-around-world"),
        prizeAmount: i18next.t("text.prize-amount"),
        inviteLifelines: i18next.t("text.invite-lifelines"),
        nationalCharitable: i18next.t("text.national-charitable"),
        goodLuck: i18next.t("text.good-luck"),
        shortAdvertisment: i18next.t("text.short-advertisment"),
        morePeople: i18next.t("text.more-people-play"),
        seeAdvertisments: i18next.t("text.see-advertisments"),
        referenceSoccer: i18next.t("text.reference-to-soccer"),
        rightAdvertisments: i18next.t("text.right-advertisments"),
        biggerPrizes: i18next.t("text.bigger-prizes"),
        charitableCause: i18next.t("text.charitable-cause"),
        recommendingGame: i18next.t("text.recommending-our-game"),
        repeatProcess: i18next.t("text.repeat-the-process"),
        weHave: i18next.t("text.we-have"),
        andA: i18next.t("text.and-a"),
        gratis: i18next.t("text.Free"),
        playGames: i18next.t("text.play-in-both-games"),
        differenceGame: i18next.t("text.difference-weekly-game"),
        numberPatterns: i18next.t("text.number-patterns"),
        tryingMatch: i18next.t("text.trying-match"),
        pickTwelve: i18next.t("text.pick-twelve"),
        theDailyGame: i18next.t("text.the-daily-game"),
        noPattern: i18next.t("text.no-pattern"),
        exampleOne: i18next.t("text.example-one"),
        exampleTwo: i18next.t("text.example-two"),
        exampleThree: i18next.t("text.example-three"),
        sixHours: i18next.t("text.six-hours"),
        moneyFrom: i18next.t("text.where-the-money-comes-from"),
        pCharity: i18next.t("text.personal-charity"),
        amountGenerated: i18next.t("text.amount-generated"),
        nationalCharity: i18next.t("text.national-charity"),
        chosenFiftyFifty: i18next.t("text.chosen-fifty-fifty"),
        totalPersonalWinnings: i18next.t("text.total-personal-winnings"),
        personalyWon: i18next.t("text.personaly-won"),
        dailyWinnings: i18next.t("text.daily-winnings"),
        generatedByLifeline: i18next.t("text.generated-by-lifeline"),
        weeklyWinnings: i18next.t("text.weekly-winnings"),
        generatedWeekly: i18next.t("text.generated-weekly"),
        totalWinnings: i18next.t("text.total-winnings"),
        thisTotalWinnings: i18next.t("text.this-total-winnings"),
        paypalVerification: i18next.t("text.paypal-verification"),
        chequeCheckout: i18next.t("text.cheque-checkout"),
        myDailyWinnings: i18next.t("text.my-daily-winnings"),
        myDailyLifelinetree: i18next.t("text.my-daily-lifeline-tree"),
        weeklyBreakdown: i18next.t("text.weekly-winnings-breakdown"),
        weeklyContribution: i18next.t("text.weekly-winnings-contribution"),
        weeklyLifelineTree: i18next.t("text.weekly-lifeline-tree"),
        sixHoursTwo: i18next.t("text.six-hours-two"),
        moneyFrom: i18next.t("text.where-the-money-comes-from"),
        moneyFromTwo: i18next.t("text.money-from"),
        opportunityFirst: i18next.t("text.opportunity-first"),
        matchNumbers: i18next.t("text.match-numbers"),
        weeklyUncovers: i18next.t("text.weekly-uncovers"),
        dailyUncovers: i18next.t("text.daily-uncovers"),
        everyHour: i18next.t("text.every-hour"),
        totalNumbers: i18next.t("text.total-24-numbers"),
        designedThrough: i18next.t("text.designed-through"),
        numberSystem: i18next.t("text.number-system"),
        randomGenerator: i18next.t("text.random-generator"),
        opportunityGetNum: i18next.t("text.opportunity-get-num"),
        playGame: i18next.t("text.play-game"),
        thenClick: i18next.t("text.then-click"),
        receiveOptions: i18next.t("text.receive-options"),
        twoOptions: i18next.t("text.two-options"),
        receiveNotification: i18next.t("text.receive-notification"),
        clickFourNumber: i18next.t("text.click-4-number"),
        videoEnds: i18next.t("text.video-ends"),
        iconWas: i18next.t("text.icon-was"),
        fewSeconds: i18next.t("text.few-seconds"),
        bottomPage: i18next.t("text.bottom-page"),
        nationalCharitableCause: i18next.t("text.national-charitable-cause"),
        moreLifelines: i18next.t("text.more-lifelines"),
        firstOptions: i18next.t("text.first-options"),
        winningPatternTwo: i18next.t("text.winning-pattern-two"),
        secondOptionPick: i18next.t("text.second-option-pick"),
        playingFor: i18next.t("text.playing-for"),
        beginsMonday: i18next.t("text.begins-at-monday"),
        pastMidnight: i18next.t("text.past-midnight"),
        finishAt: i18next.t("text.finish-at"),
        saturdayNight: i18next.t("text.saturday-night"),
        sixPM: i18next.t("text.six-pm"),
        winningColor: i18next.t("text.winning-color"),
        becomeRed: i18next.t("text.become-red"),
        matchOne: i18next.t("text.match-one"),
        placeNumber: i18next.t("text.place-your-number"),
        payoutStructure: i18next.t("text.payout-structure"),
        causeTwo: i18next.t("text.cause-two"),
        soPlease: i18next.t("text.so-please"),
        inviteFriends: i18next.t("text.invite-all-friends"),
        playOurGame: i18next.t("text.play-our-game"),
        oneJackpotPrize: i18next.t("text.one-jackpot-prize"),
        oneJackpot: i18next.t("text.one-jackpot"),
        fairPlay: i18next.t("text.fair-players"),
        gameFirst: i18next.t("text.daily-game-first"),
        newDay: i18next.t("text.new-day"),
        endDay: i18next.t("text.end-day"),
        andWill: i18next.t("text.and-will"),
        typeAds: i18next.t("text.type-of-advertisments"),
        completeProf: i18next.t("text.complete-profile"),
        somethingInterested: i18next.t("text.something-interested"),
        peopleAsk: i18next.t("text.people-ask"),
        simpleExplain: i18next.t("text.simple-explain"),
        ourSponsors: i18next.t("text.our-sponsors"),
        beginYoutube: i18next.t("text.begin-youtube"),
        reviewGame: i18next.t("text.review-game-rules"),
        maximumBenefit: i18next.t("text.maximum-benefit"),
        matchIt: i18next.t("text.match-it"),
        goodLuckTwo: i18next.t("text.good-luck-two"),
        thankPlaying: i18next.t("text.thank-you-playing"),
        briefAds: i18next.t("text.brief-advertisement"),
        ourGameTwo: i18next.t("text.our-game-two"),
        matchGame: i18next.t("text.match-game"),
        bonusPool: i18next.t("text.bonus-pool"),
        announcedSoon: i18next.t("text.announced-soon"),
        genius: i18next.t("text.piece-of-genius"),
        why: i18next.t("text.why?"),
        hangOn: i18next.t("text.hang-on"),
        playWin: i18next.t("text.i-Play-we-Win"),
        divSplit: i18next.t("text.division-split"),
        multiplePeople: i18next.t("text.multiple-people"),
        exampleCircle: i18next.t("text.example-winning-circle"),
        freeBegin: i18next.t("text.free-to-begin"),
        winsMoney: i18next.t("text.wins-money"),
        friendsFree: i18next.t("text.friends-free"),
        lifelineAbove: i18next.t("text.2-lifelines-above"),
        lifelineBelow: i18next.t("text.2-winning-below"),
        sixtyPercent: i18next.t("text.60-payout-of-50-winnings"),
        example100: i18next.t("text.based-on-100"),
        payoutDiagram: i18next.t("text.payout-diagram"),
        thirtyPayout: i18next.t("text.30-payout"),
        threeLevelsAbove: i18next.t("text.winner-received-donations"),
        fundraisingCharity: i18next.t("text.fundraising-charity"),
        breakdownSixty: i18next.t("text.breakdown-60"),
        rememberWhen: i18next.t("text.remember-when"),
        lifelines: i18next.t("text.lifelines"),
        lifelinesWinMoney: i18next.t("text.lifelines-win-money"),
        yourPersonalFoundation: i18next.t("text.your-personal-foundation"),
        whatCall: i18next.t("text.this-is-what-we-call"),
        andAll: i18next.t("text.and-all"),
        freeToPlay: i18next.t("text.free-to-play"),
        thousandsDonated: i18next.t("text.thousands-donated"),
        donateCause: i18next.t("text.donate-your-cause"),
        receiveDonations: i18next.t("text.receive-donations"),
        portionShared: i18next.t("text.portion-of-shared-winnings"),
        stayBonus: i18next.t("text.stay-in-bonus-pool"),
        donateLifelines: i18next.t("text.donate-up-to"),
        threeLifelines: i18next.t("text.three-lifelines"),
        personallyInvite: i18next.t("text.personally-invite"),
        justPlayer: i18next.t("text.just-player"),
        threeLifelinesAboveYou: i18next.t("text.3-lifelines-above-you"),
        aboveYou: i18next.t("text.above-you"),
        donateFifty: i18next.t("text.donate-50"),
        everyTimeYou: i18next.t("text.every-time-you"),
        pickedAt: i18next.t("text.picked-at"),
        lifelineOne: i18next.t("text.lifeline-1"),
        lifelineTwo: i18next.t("text.lifeline-2"),
        lifelineThree: i18next.t("text.lifeline-3"),
        causeReceives: i18next.t("text.cause-receives"),
        belowYou: i18next.t("text.below-you"),
        fromAll: i18next.t("text.from-all"),
        fundraisingExplanation: i18next.t("text.fundraising-explanation"),
        fromAll: i18next.t("text.from-all"),
        excitingGame: i18next.t("text.exciting-game"),
        neverEnding: i18next.t("text.never-ending-basis"),
        fundraisingProgram: i18next.t("text.fundraising-program"),
        otherFifty: i18next.t("text.other-50"),
        sharedFifty: i18next.t("text.shared-winnings-50"),
        dividedFollows: i18next.t("text.divided-follows"),
        thirtyPaid: i18next.t("text.30-paid"),
        friendsPersonalCharities: i18next.t("text.friends-personal-charities"),
        tenStay: i18next.t("text.10-stay"),
        winnersCircleBonusPool: i18next.t("text.winners-circle-bonus-pool"),
        sixtyDivided: i18next.t("text.60-divided"),
        betweenTwo: i18next.t("text.between-two"),
        you: i18next.t("text.you"),
        receiveTwenty: i18next.t("text.receive-20"),
        balanceTwenty: i18next.t("text.20-of-balance"),
        becomeTheir: i18next.t("text.become-their"),
        askLifeline: i18next.t("text.ask-to-be-lifeline"),
        balanceWinnings: i18next.t("text.balance-winnings"),
        inAddition: i18next.t("text.in-addition"),
        askSomebody: i18next.t("text.also-ask-somebody"),
        sameIndividual: i18next.t("text.same-individual"),
        ofTheBalance: i18next.t("text.of-the-balance"),
        greatestFundraising: i18next.t("text.greatest-fundraising"),
        greatest: i18next.t("text.greatest"),
        everDesigned: i18next.t("text.ever-designed"),
        callOrphans: i18next.t("text.call-orphans"),
        orphans: i18next.t("text.orphans"),
        see: i18next.t("text.see"),
        gameRules: i18next.t("text.game-rules"),
        qualifications: i18next.t("text.game-rules-qualifications"),
        activatePhone: i18next.t("text.activate-phone"),
        alsoaskSomebody: i18next.t("text.ask-somebody"),
        roundRobin: i18next.t("text.round-robin"),
        assigningOrphans: i18next.t("text.assigning-orphans"),
        playUnder: i18next.t("text.play-under-you"),
        personalOrg: i18next.t("text.personal-organization-wins-money"),
        winsMoneyTwo: i18next.t("text.wins-money-two"),
        howeverBetter: i18next.t("text.however-better"),
        nonProfit: i18next.t("text.nonprofit-organization"),
        winningDonations: i18next.t("text.receive-winning-donations"),
        aroundWorld: i18next.t("text.around-the-world"),
        contribution5050: i18next.t("text.contribution-50-50"),
        foundationContribution: i18next.t("text.foundation-contribution"),
        lifelineContribution: i18next.t("text.lifeline-contribution"),
        circleContribution: i18next.t("text.circle-contribution"),
        shareOfWinnings: i18next.t("text.share-of-winnings"),
        balanceContribution: i18next.t("text.balance-contribution"),
        sharedLifelineWinnings: i18next.t("text.shared-lifeline-winnings"),
        causeContributions: i18next.t("text.cause-contributions"),
        topActivePlayers: i18next.t("text.top-active-players"),
        topQualifiedPlayers: i18next.t("text.top-qualified-players"),
        minimumToQualify: i18next.t("text.minimum-to-qualify"),
        numbersPicked: i18next.t("text.numbers-picked"),
        threeAccepted: i18next.t("text.3-accepted-help-a-friend"),
        continuePlaying: i18next.t("text.continue-playing"),
        threeInviteReq: i18next.t("text.three-invite-requirement"),
        acceptInvitation: i18next.t("text.accept-invitation"),
        ourShare: i18next.t("text.our-share"),
        personalLifelines: i18next.t("text.personal-lifelines"),
        personalFundraisingTwo: i18next.t("text.your-personal-fundraising"),
        necessityReceive: i18next.t("text.necessity-to-receive"),
        secondRequirements: i18next.t(
          "text.second-requirement-receive-winnings"
        ),
        forExample: i18next.t("text.for-example"),
        claimSix: i18next.t("text.claim-atleast-6num"),
        youMust: i18next.t("text.you-must"),
        twentyFiveMore: i18next.t("text.25-or-more"),
        mustClaim: i18next.t("text.must-claim-25"),
        eligibleReceive: i18next.t("text.eligible-to-receive"),
        missGame: i18next.t("text.miss-game"),
        note: i18next.t("text.note"),
        meetReq: i18next.t("text.meet-requirements"),
        soleResponsibility: i18next.t("text.sole-responsibility"),
        linkIn: i18next.t("text.link-in"),
        subjectChange: i18next.t("text.requirements-subject-change"),
        settingsReview: i18next.t("text.settings-review"),
        sharedBelow: i18next.t("text.shared-winnings-below-you"),
        firstReq: i18next.t("text.first-requirements"),
        sharedWinningsReq: i18next.t("text.shared-winnings-requirements"),
        personalWinningsReq: i18next.t("text.personal-winnings-requirements"),
        fundraisingReq: i18next.t("text.fundraising-group-requirements"),
        biggerPrizes: i18next.t("text.bigger-prizes-two"),
        someReq: i18next.t("text.some-requirements"),
        probabilityGrowth: i18next.t("text.probability-of-growth"),
        weeklyMatchItGame: i18next.t("text.weekly-matchit-game"),
        dailyMatchItGame: i18next.t("text.daily-matchit-game"),
        threeWeeksFree: i18next.t("text.three-weeks-free-second"),
        oneWeekFree: i18next.t("text.one-week-free"),
        noRequirements: i18next.t("text.no-requirements"),
        areQualified: i18next.t("text.are-qualified"),
        aMinimumAccepted: i18next.t("text.a-minimum-accepted"),
        areNotQualified: i18next.t("text.are-not-qualified"),
        twoPersonalLifelines: i18next.t("text.two-personal-lifelines"),
        twoPersonalMatchIt: i18next.t("text.two-personal-matchit-invites"),
        willReceive: i18next.t("text.will-receive"),
        willNotReceive: i18next.t("text.will-not-receive"),
        anyCombo: i18next.t("text.any-combo"),
        toReceive: i18next.t("text.to-receive"),
        previouslyStated: i18next.t("text.previously-stated-requirements"),
        willBeQualified: i18next.t("text.will-be-qualified"),
        willNotBeQualified: i18next.t("text.will-not-be-qualified"),
        oneWeekFollowingReq: i18next.t("text.1-week-following-requirements"),
        forThreeWeeks: i18next.t("text.for-three-weeks"),
        oneWithout: i18next.t("text.one-week-without"),
        QualifedPlay: i18next.t("text.qualified-to-play"),
        playMatchIt: i18next.t("text.play-matchit"),
        afterThree: i18next.t("text.after-three-weeks"),
        andOneWeek: i18next.t("text.and-one-week"),
        requiredHave: i18next.t("text.required-have"),
        helpFriend: i18next.t("text.help-a-friends-playing"),
        noPersonalLifelines: i18next.t("text.no-two-personal-lifelines"),
        minimumTwo: i18next.t("text.minimum-of-two"),
        each: i18next.t("text.each"),
        playingLifeline: i18next.t("text.playing-lifeline"),
        gameReceive: i18next.t("text.game-receive"),
        fromLifeline: i18next.t("text.from-lifeline-tree"),
        noExceptions: i18next.t("text.no-exceptions"),
        personalGroup: i18next.t("text.personal-group"),
        receiveSharedWinnings: i18next.t("text.receive-shared-winnings"),
        charitableFoundation: i18next.t(
          "text.not-qualified-charitable-foundation"
        ),
        choosePattern: i18next.t("text.choose-pattern"),
        choosePatternAgain: i18next.t("text.choose-pattern-again"),
        placePattern: i18next.t("text.place-pattern"),
        blockOf6: i18next.t("text.weekly-block-of-6"),
        horizontal6: i18next.t("text.weekly-6-horizontally"),
        horizontal7: i18next.t("text.weekly-7-horizontally"),
        game7: i18next.t("text.weekly-7-number-game"),
        gameY7: i18next.t("text.weekly-y-7-number-game"),
        game8: i18next.t("text.weekly-y-8-number-game"),
        bullseye: i18next.t("text.weekly-bulls-eye"),
        saving: i18next.t("text.saving"),
        disabled: i18next.t("text.disabled"),
        noGames: i18next.t("text.no-game-sunday"),
        startOn: i18next.t("text.start-on"),

        seven1: i18next.t("text.seven-1"),
        seven2: i18next.t("text.seven-2"),

        nine1: i18next.t("text.nine-1"),
        nine2: i18next.t("text.nine-2"),
        nine3: i18next.t("text.nine-3"),
        nine4: i18next.t("text.nine-4"),

        ten1: i18next.t("text.ten-1"),
        ten2: i18next.t("text.ten-2"),
        ten3: i18next.t("text.ten-3"),
        ten4: i18next.t("text.ten-4"),

        eleven1: i18next.t("text.eleven-1"),
        eleven2: i18next.t("text.eleven-2"),
        eleven3: i18next.t("text.eleven-3"),

        twelve1: i18next.t("text.twelve-1"),
        twelve2: i18next.t("text.twelve-2"),
        twelve3: i18next.t("text.twelve-3"),
        twelve4: i18next.t("text.twelve-4"),
        twelve5: i18next.t("text.twelve-5"),
        twelve6: i18next.t("text.twelve-6"),

        // Game page
        back: i18next.t("text.back"),
        iPlay: i18next.t("text.i-Play-we-Win"),
        playForOthers: i18next.t("text.play-for-others"),
        congratulations: i18next.t("text.congratulations"),
        redirectToSite: i18next.t("text.redirected-to-site"),
        closeSponsor: i18next.t("text.close-sponsor-website"),
        saveSelection: i18next.t("text.save-selection"),
        selectYourNumber: i18next.t("text.select-your-number"),
        chooseMyself: i18next.t("text.choose-myself"),
        emptyBoard: i18next.t("text.empty-board"),
        clickHereToPlay: i18next.t("text.click-here-to-play"),
        chooseNumber: i18next.t("text.choose-number"),
        clickPattern: i18next.t("text.click-pattern"),
        chooseForMe: i18next.t("text.choose-for-me"),
        preSelected: i18next.t("text.pre-selected"),
        click4Number: i18next.t("text.click-4-number"),
        sponsorAd: i18next.t("text.sponsor-ad"),
        sponsorAdIOS: i18next.t("text.sponsor-ad-ios"),
        choose: i18next.t("text.choose"),
        numbers: i18next.t("text.numbers"),

        // Info page
        welcomeMatchit: i18next.t("text.welcome-matchit"),
        playItForward: i18next.t("text.play-it-forward"),
        fundraisingProject: i18next.t("text.fundraising-project"),
        noCostToPlay: i18next.t("text.no-cost-to-play"),
        increaseProbability: i18next.t("text.increase-probability"),
        winWinWin: i18next.t("text.win-win-win"),
        wantCash: i18next.t("text.want-cash"),
        forOthers: i18next.t("text.for-others"),
        whenPlayWin: i18next.t("text.when-play-win"),
        downloadApp: i18next.t("text.download-app"),
        shareOurApp: i18next.t("text.share-our-app"),
        howPossible: i18next.t("text.how-possible"),
        howThatPossible: i18next.t("text.how-that-possible"),
        sharingFreeApp: i18next.t("text.sharing-free-app"),
        generateThousand: i18next.t("text.generate-thousand"),
        biggerProbabiility: i18next.t("text.bigger-probabiility"),
        readHowToPlay: i18next.t("text.read-how-to-play"),
        gameLoading: i18next.t("text.game-is-loading"),
        previousNumber: i18next.t("text.previous-number"),
      });

      sessionStorage.setItem(
        "gameTranslations",
        JSON.stringify({
          iPlay: this.props.translations.iPlay,
          playForOthers: this.props.translations.playForOthers,
          congratulations: this.props.translations.congratulations,
          redirectToSite: this.props.translations.redirectToSite,
          closeSponsor: this.props.translations.closeSponsor,
          weeklyGameNotif: this.props.translations.weeklyGameNotif,
          click4Number: this.props.translations.click4Number,
          gameLoading: this.props.translations.gameLoading,
          previousNumber: this.props.translations.previousNumber,
          sponsorAd: this.props.translations.sponsorAd,
          sponsorAdIOS: this.props.translations.sponsorAdIOS,
        })
      );

      sessionStorage.setItem(
        "chooseForMe",
        JSON.stringify({
          choosePattern: this.props.translations.choosePattern,
          placePattern: this.props.translations.placePattern,
          blockOf6: this.props.translations.blockOf6,
          horizontal6: this.props.translations.horizontal6,
          horizontal7: this.props.translations.horizontal7,
          game7: this.props.translations.game7,
          gameY7: this.props.translations.gameY7,
          game8: this.props.translations.game8,
          bullseye: this.props.translations.bullseye,
          noGames: this.props.translations.noGames,
          startOn: this.props.translations.startOn,
          startOn: this.props.translations.startOn,
          seven1: this.props.translations.seven1,
          seven2: this.props.translations.seven2,
          nine1: this.props.translations.nine1,
          nine2: this.props.translations.nine2,
          nine3: this.props.translations.nine3,
          nine4: this.props.translations.nine4,
          ten1: this.props.translations.ten1,
          ten2: this.props.translations.ten2,
          ten3: this.props.translations.ten3,
          ten4: this.props.translations.ten4,
          eleven1: this.props.translations.eleven1,
          eleven2: this.props.translations.eleven2,
          eleven3: this.props.translations.eleven3,
          twelve1: this.props.translations.twelve1,
          twelve2: this.props.translations.twelve2,
          twelve3: this.props.translations.twelve3,
          twelve4: this.props.translations.twelve4,
          twelve5: this.props.translations.twelve5,
          twelve6: this.props.translations.twelve6,
        })
      );
    });
  };

  handleUpdateEmail = (id, email) => {
    axios
      .post(`https://matchit.apptechgateway.com/api/user/get-user-data`, {
        token: "02DFK2fj294jgf9234Oe23395oP",
        user_id: id,
        email: email,
      })
      .then((res) => {
        this.setState({
          userData: res.data.data,
        });
        res.data.data.avazoo_email === null &&
          this.setState({
            needToUpdateEmail: !this.state.needToUpdateEmail,
          });
      })
      .catch((err) => console.log(err));
  };

  componentDidMount = async () => {
    await this.props.HOC_SecuredAuthMethod();

    if (!sessionStorage.getItem("defaultLang")) {
      this.changeLang("en");
    } else {
      this.changeLang(sessionStorage.getItem("defaultLang"));
    }

    if (sessionStorage.getItem("authFullInfo")) {
      const mobile_id = JSON.parse(sessionStorage.getItem("authFullInfo")).data
        .mobile_id;
      this.setState({ mobileID: mobile_id });
      this.props.getMoney(mobile_id);
      this.props.transactionHistory({ mobile_id });
      this.props.lifelineStats({ mobile_id });
    }
    if (this.props.HOC_UserALLInfo.data) {
      const userData = this.props.HOC_UserALLInfo.data;
      // this.state.userData = this.props.HOC_UserALLInfo.data;
      // !userData.avazoo_email &&
      //   sessionStorage.setItem("hasUpdateAvazooEmail", false);

      !sessionStorage.getItem("hasUpdateAvazooEmail") &&
        this.handleUpdateEmail(userData.id, userData.email);

      const { id } = this.props.HOC_UserALLInfo.data;
      await this.props.fetchAllGame({ id });
      this.setState({
        ...this.state,
      });
    }
  };

  componentDidUpdate() {
    if (this.props.HOC_UserALLInfo.data) {
      const userData = this.props.HOC_UserALLInfo.data;
      this.handleUpdateEmail(userData.id, userData.email);
    }
  }

  updateEmailModal = () => {
    this.setState({
      needToUpdateEmail: !this.state.needToUpdateEmail,
    });
  };

  dailyQualifiedModal = () => {
    this.setState({
      showDailyQualified: !this.state.showDailyQualified,
    });
  };

  closeDailyQualified = () => {
    this.setState({
      showDailyQualified: !this.state.showDailyQualified,
    });
  };

  weeklyQualifiedModal = () => {
    this.setState({
      showWeeklyQualified: !this.state.showWeeklyQualified,
    });
  };

  closeWeeklyQualified = () => {
    this.setState({
      showWeeklyQualified: !this.state.showWeeklyQualified,
    });
  };

  dailyWinningsModal = () => {
    this.setState({
      showDailyWinnings: !this.state.showDailyWinnings,
    });
  };

  weekWinningsModal = () => {
    this.setState({
      showWeeklyWinnings: !this.state.showWeeklyWinnings,
    });
  };

  languageModal = () => {
    this.setState({
      showLanguage: !this.state.showLanguage,
    });
  };

  closeLanguage = () => {
    this.setState({
      showLanguage: !this.state.showLanguage,
    });
  };

  expand = () => {
    this.setState((prevState) => ({
      areExpanded: !prevState.areExpanded,
    }));
  };

  playsNow = () => {
    const playsNow = true;
    this.props.checkUserInactivity({ playsNow });
  };

  componentDidUpdate = async () => {
    if (this.props.HOC_IsLoading) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
        getTransactionHistory,
        getLifelineStats,
        isTransactionLoading,
        isLifelineLoading,
        allGameData,
      } = this.props,
      { balance } = this.props.HOC_GetMoney,
      {
        showDailyQualified,
        showWeeklyQualified,
        showDailyWinnings,
        showWeeklyWinnings,
        showLanguage,
        needToUpdateEmail,
      } = this.state,
      newBal = parseFloat(balance).toFixed(2),
      dailyCount = getLifelineStats.dailyQualifiedCount,
      weeklyCount = getLifelineStats.weeklyQualifiedCount,
      mID = this.state.mobileID,
      is_sunday =
        allGameData.data && allGameData.data.is_sunday === 1 ? true : false;

    const queryValues = queryString.parse(this.props.location.search);

    return (
      <React.Fragment>
        {queryValues.rotate && queryValues.rotate === "false" && (
          <>
            {document.body.classList.add("disable")}
            <div className="modal-trap">
              {" "}
              <img src="/images/tongue.svg" /> We caught you Good Bye! LOL{" "}
            </div>
          </>
        )}
        <Link
          to=""
          className="nav__header nav__app nav__back"
          onClick={() => {
            window.open(
              "https://apptech.page.link/?link=https://www.apptechgateway.com/",
              "_blank"
            );
          }}
        >
          {this.props.translations.backToApp}
        </Link>
        <div className="container gamelist">
          {/*
            <div>
              <h4 className="gamelist__title">${balance == '' ? '0.00' : newBal}</h4>
              <p className="gamelist__label"> Your FREE Winnings </p>
            </div>
          */}

          <div className="how language">
            {/* <Link to="/guide">
              <img src="/images/guideVid2.png" alt="Guide Video" />
            </Link> */}
            <div className="language-inner">
              <Link to="/guide">
                <img src="/images/play-btn.svg" />{" "}
                <h6> {this.props.translations.viewVideo} </h6>
              </Link>
            </div>
            <div className="language-inner">
              <p> {this.props.translations.language} </p>
              <div className="countries">
                <button
                  className={this.state.isEn ? "selected" : ""}
                  onClick={() => {
                    this.changeLang("en");
                  }}
                >
                  {" "}
                  <img src="/images/uk-flag.png" alt="United Kingdom" />{" "}
                </button>
                <button
                  className={this.state.isEn ? "" : "selected"}
                  onClick={() => {
                    this.changeLang("es");
                  }}
                >
                  {" "}
                  <img src="/images/mexican-flag.png" alt="Mexico" />{" "}
                </button>
              </div>
            </div>
          </div>

          <div
            className={
              this.state.areExpanded
                ? "gamelist__infos gamelist__infos1 gamelist__infos--expanded"
                : "gamelist__infos gamelist__infos1"
            }
          >
            {(isTransactionLoading && isLifelineLoading && (
              <>
                <ion-icon
                  name={this.state.areExpanded ? "remove-circle" : "add-circle"}
                  onClick={this.expand}
                ></ion-icon>
                <div className="gamelist__info">
                  <img src="/images/people2.svg" alt="" />
                  <p>
                    {getLifelineStats && getLifelineStats.winnerLifelineCount
                      ? getLifelineStats.winnerLifelineCount
                      : "0"}
                  </p>
                  <h4> {this.props.translations.totalLifelineWins} </h4>
                </div>
                <div className="gamelist__info">
                  <img src="/images/gross2.svg" alt="" />
                  <p>
                    $
                    {getLifelineStats.totalTreeWinnings
                      ? parseFloat(getLifelineStats.totalTreeWinnings).toFixed(
                          2
                        )
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.grossLifeline} </h4>
                </div>
                <div className="gamelist__info">
                  <img src="/images/hand.svg" alt="" />
                  <p>
                    $
                    {getTransactionHistory.fiftyFiftyTotal
                      ? parseFloat(
                          getTransactionHistory.fiftyFiftyTotal
                        ).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.charityDonation} </h4>
                </div>
                <div className="gamelist__info">
                  <img src="/images/personal-charity.svg" alt="" />
                  <p>
                    $
                    {getTransactionHistory.personalCharityTotal
                      ? parseFloat(
                          getTransactionHistory.personalCharityTotal
                        ).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.personalCharity} </h4>
                </div>
              </>
            )) || <img src={logo} className="statLoader" />}
          </div>

          <div
            className={
              this.state.areExpanded
                ? "gamelist__infos gamelist__infos2 gamelist__infos--expanded"
                : "gamelist__infos  gamelist__infos2"
            }
          >
            <div className="gamelist__info" onClick={this.dailyWinningsModal}>
              <span className="expandStat">
                <ion-icon name="add-circle"></ion-icon>
              </span>
              <img src="/images/winnings.svg" alt="" />
              <p>
                $
                {getTransactionHistory &&
                getTransactionHistory.dailyLifelineWinnings
                  ? parseFloat(
                      getTransactionHistory.dailyLifelineWinnings
                    ).toFixed(2)
                  : "0.00"}
              </p>
              <h4> {this.props.translations.dailyBreakdown} </h4>
            </div>
            <div className="gamelist__info" onClick={this.weekWinningsModal}>
              <span className="expandStat">
                <ion-icon name="add-circle"></ion-icon>
              </span>
              <img src="/images/winning-tree.svg" alt="" />
              <p>
                $
                {getLifelineStats.totalWeeklyTreeWinnings
                  ? parseFloat(
                      getLifelineStats.totalWeeklyTreeWinnings
                    ).toFixed(2)
                  : "0.00"}
              </p>
              <h4> {this.props.translations.weeklyTreeWinnings} </h4>
            </div>
            <div className="gamelist__info">
              {/* <Link to="/transaction"> */}
              <img src="/images/winnings.svg" alt="" />
              <p>
                $
                {getTransactionHistory &&
                getTransactionHistory.walletTransactionsGroupList
                  ? parseFloat(getTransactionHistory.transactionTotal).toFixed(
                      2
                    )
                  : "0.00"}
              </p>
              <h4> {this.props.translations.totalLifelineWinnings} </h4>
              {/* </Link> */}
            </div>
            <div className="gamelist__info">
              <img src="/images/deposit.svg" alt="" />
              <p>
                $
                {getTransactionHistory && getTransactionHistory.ownWinning
                  ? parseFloat(getTransactionHistory.ownWinning).toFixed(2)
                  : "0.00"}
              </p>
              <h4> {this.props.translations.personalGameWinnings} </h4>
            </div>
          </div>

          <div
            className={
              this.state.areExpanded
                ? "gamelist__infos gamelist__infos3 gamelist__infos--expanded"
                : "gamelist__infos  gamelist__infos3"
            }
          >
            <div className="gamelist__info" onClick={this.dailyQualifiedModal}>
              <span className="expandStat">
                <ion-icon name="add-circle"></ion-icon>
              </span>
              <div
                className={
                  dailyCount > 2
                    ? "gamelist__info--icon"
                    : "gamelist__info--icon gamelist__info--icon--error"
                }
              >
                {dailyCount > 2 ? (
                  <ion-icon name="checkmark-circle"></ion-icon>
                ) : (
                  <ion-icon name="close-circle"></ion-icon>
                )}
                <img src="/images/player2.svg" alt="" />
              </div>
              {getLifelineStats && dailyCount ? (
                <p> {dailyCount} </p>
              ) : (
                <p className="error">0</p>
              )}
              <h4> {this.props.translations.dailyQualifiedLifeline} </h4>
            </div>
            <div className="gamelist__info" onClick={this.weeklyQualifiedModal}>
              <span className="expandStat">
                <ion-icon name="add-circle"></ion-icon>
              </span>
              <div
                className={
                  weeklyCount >= 2
                    ? "gamelist__info--icon"
                    : "gamelist__info--icon gamelist__info--icon--error"
                }
              >
                {weeklyCount >= 2 ? (
                  <ion-icon name="checkmark-circle"></ion-icon>
                ) : (
                  <ion-icon name="close-circle"></ion-icon>
                )}
                <img src="/images/player.svg" alt="" />
              </div>
              {getLifelineStats && weeklyCount ? (
                <p> {weeklyCount} </p>
              ) : (
                <p className="error">0</p>
              )}
              <h4> {this.props.translations.weeklyQualifiedLifeline} </h4>
            </div>
            <div
              className="gamelist__info"
              onClick={() => {
                window.open(
                  `https://admin.apptechgateway.com/apptech-admin/matchitv2/viewUserInvitations?mobileClientId=${mID}`,
                  "_blank"
                );
              }}
            >
              <span className="expandStat">
                <ion-icon name="add-circle"></ion-icon>
              </span>
              <img src="/images/your-lifeline.svg" alt="" />
              <p>
                {getLifelineStats && getLifelineStats.threeLevelLifeline
                  ? getLifelineStats.threeLevelLifeline
                  : "0"}
              </p>
              <h4>{this.props.translations.lifelinePlayingForYou}</h4>
            </div>
            <div className="gamelist__info">
              <img src="/images/lifeline-total.svg" alt="" />
              <p>
                {getLifelineStats && getLifelineStats.totalLifeline
                  ? getLifelineStats.totalLifeline
                  : "0"}
              </p>
              <h4>{this.props.translations.totalPlayersLifeline}</h4>
            </div>
          </div>

          {/* <div className="language language-inner language-sched language-top4 language-nogame">
            <h6>
              {" "}
              <img src="/images/warning-icon.svg" alt="" />{" "}
              {this.props.translations.noGames}{" "}
            </h6>
          </div> */}

          <div className="language language-inner language-sched language-top">
            <h6> {this.props.translations.weeklyGameNotif} </h6>
          </div>

          <div className="box">
            <div className="box__inner">
              <ul>
                {(() => {
                  if (this.state.isLoading) {
                    return (
                      <div>
                        <div className="placeholder">
                          <span className="placeholder-anim"></span>
                          <span>
                            <span className="placeholder-anim"></span>
                            <span className="placeholder-anim"></span>
                          </span>
                        </div>
                      </div>
                    );
                  } else if (
                    this.props.allGameData &&
                    this.props.allGameData.data.can_play_games === "all"
                  ) {
                    const restrict = this.props.allGameData.data.can_play_games;
                    const { games, number_notif } = this.props.allGameData.data;
                    // const restrict = this.props.HOC_AllGameData.data.can_play_games;
                    // const { games } = this.props.HOC_AllGameData.data;
                    // const { number_notif } = this.props.HOC_AllGameData.data;
                    const { id } = this.props.HOC_UserALLInfo.data;

                    return games.map((game, key) => {
                      if (game.number_picking_cycle !== 1) {
                        return game.number_picking_cycle === 6 ? (
                          <li
                            names={key}
                            className="gamelist__items ard"
                            key={game.id}
                          >
                            <DisplayAllGames
                              id={game.id}
                              name={game.name}
                              status={game.status}
                              boardSize={game.board_size}
                              user_id={id}
                              all={restrict}
                              notification={number_notif[game.id]}
                              numberPickingCycle={game.number_picking_cycle}
                              start_date={game.start_date}
                              sunday={is_sunday}
                            />
                          </li>
                        ) : (
                          <></>
                        );
                      } else {
                        // return <p className="upcoming"> Upcoming game </p>;
                      }
                    });
                  }
                })()}
              </ul>
            </div>
          </div>

          <div className="language language-inner language-sched">
            <h6> {this.props.translations.dailyGameNotif} </h6>
          </div>

          <div className="box">
            <div className="box__inner">
              <ul>
                {(() => {
                  if (this.state.isLoading) {
                    return (
                      <div>
                        <div className="placeholder">
                          <span className="placeholder-anim"></span>
                          <span>
                            <span className="placeholder-anim"></span>
                            <span className="placeholder-anim"></span>
                          </span>
                        </div>
                      </div>
                    );
                  } else if (
                    this.props.allGameData &&
                    this.props.allGameData.data.can_play_games === "all"
                  ) {
                    const restrict = this.props.allGameData.data.can_play_games;
                    const { games, number_notif } = this.props.allGameData.data;
                    // const restrict = this.props.HOC_AllGameData.data.can_play_games;
                    // const { games } = this.props.HOC_AllGameData.data;
                    // const { number_notif } = this.props.HOC_AllGameData.data;
                    const { id } = this.props.HOC_UserALLInfo.data;

                    var counterPickingCycle1 = 0;

                    for (const game2222 of games) {
                      if (game2222.number_picking_cycle == 1) {
                        counterPickingCycle1 = 1;
                      }
                    }

                    return games.map((game, key) => {
                      // if (game.number_picking_cycle !== 6) {
                      if (counterPickingCycle1 == 1) {
                        return game.number_picking_cycle === 1 ? (
                          <>
                            <li
                              names={key}
                              className="gamelist__items ard"
                              key={game.id}
                            >
                              <DisplayAllGames
                                id={game.id}
                                name={game.name}
                                status={game.status}
                                boardSize={game.board_size}
                                user_id={id}
                                all={restrict}
                                notification={number_notif[game.id]}
                                numberPickingCycle={game.number_picking_cycle}
                                start_date={game.start_date}
                                sunday={is_sunday}
                              />
                            </li>
                          </>
                        ) : (
                          <></>
                        );
                      } else {
                        return (
                          <p className="upcoming upcoming-full">
                            {" "}
                            Hang tight... Due to the coronavirus breakout and
                            its effect on the advertisers, we are making some
                            changes to the Daily Game. We will be back soon.
                            Thank you for your patience.{" "}
                          </p>
                        );
                      }
                    });
                  }
                })()}
              </ul>
            </div>
          </div>
        </div>{" "}
        <Menu />
        {showDailyQualified ? <DailyQualified /> : null}
        {showWeeklyQualified ? <WeeklyQualified /> : null}
        {showDailyWinnings ? <DailyWinnings /> : null}
        {showWeeklyWinnings ? <WeeklyWinnings /> : null}
        {showLanguage ? <Language /> : null}
        {needToUpdateEmail ? (
          <UpdateEmail userInfo={this.state.userData} />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allGameData: state.allGame.games,
  isTransactionLoading: state.transactionHistory.loading,
  isLifelineLoading: state.lifelineStats.loading,
  getTransactionHistory: state.transactionHistory.transactionHistory,
  getLifelineStats: state.lifelineStats.lifelineStats,
  translations: state.translationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllGame: (payload) => dispatch(displayAllgame(payload)),
  getMoney: (payload) => dispatch(getMoney(payload)),
  transactionHistory: (payload) => dispatch(transactionHistory(payload)),
  lifelineStats: (payload) => dispatch(lifelineStats(payload)),
  changeTranslation: (payload) => dispatch(applyTranslation(payload)),
});

export default authHOC(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
);
