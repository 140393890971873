import React, { PureComponent } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

class RequestPayout extends PureComponent {
  state = {
    stats: this.props.myState,
    component: ""
  };

  onContinue = () => {
    this.setState({ ...this.state, component: "continue" });
  };

  onDoPayout = () => {
    this.setState({ ...this.state, component: "doPayout" });
  };

  onSuccess = () => {
    this.setState({ ...this.state, component: "success" });
  };

  onClose = () => {
    this.setState({ stats: !this.state.stats });
  };

  render() {
    const { myState } = this.props;
    const { stats, component } = this.state;
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={stats}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalHeader>
              <Button variant="secondary" onClick={this.onClose}>
                X
              </Button>
              <p> Payout </p>
            </ModalHeader>
            <ModalBody>
              <div className="transaction__amount">
                <p> $150.20 </p>
                <span> Current Balance </span>
              </div>
              <ul className="settings">
                <li onClick={this.onContinue.bind(this, myState)}>
                  <p>Request Payout</p>
                  <i class="ion ion-ios-arrow-forward"></i>
                </li>
                <li>
                  <p>History</p>
                  <i class="ion ion-ios-arrow-forward"></i>
                </li>
              </ul>
            </ModalBody>
          </Modal>
        </div>
        {(() => {
          if (component === "continue") {
            console.log("contienue");
            return (
              <div>
                <Modal
                  isOpen={stats}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <ModalHeader>
                    <Button variant="secondary" onClick={this.onClose}>
                      X
                    </Button>
                    <p> Payout </p>
                  </ModalHeader>
                  <ModalBody>
                    <div className="transaction__amount">
                      <p> $150.20 </p>
                      <span> Current Balance </span>
                    </div>
                    <div className="transaction__input">
                      <label forHtml="amount"> Amount for Payout </label>
                      <input type="number" id="amount" name="amount" />
                    </div>
                    <button class="modal-submit" onClick={this.onDoPayout}>
                      Continue
                    </button>
                  </ModalBody>
                </Modal>
              </div>
            );
          } else if (component === "doPayout") {
            return (
              <div>
                <Modal
                  isOpen={stats}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <ModalHeader>
                    <Button variant="secondary" onClick={this.onClose}>
                      X
                    </Button>
                    <p> Payout </p>
                  </ModalHeader>
                  <ModalBody>
                    <ul className="transaction__breakdown">
                      <li>
                        <span> Amount for payout </span>
                        <p> $10.00 </p>
                      </li>
                      <li>
                        <span>
                          {" "}
                          PayPal fee <a href="/"> what’s this? </a>{" "}
                        </span>
                        <p> + $0.25</p>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li>
                        <span> Total </span>
                        <p> $10.25</p>
                      </li>
                    </ul>
                    <button onClick={this.onSuccess} class="modal-submit">
                      Do Payout
                    </button>
                  </ModalBody>
                </Modal>
              </div>
            );
          } else if (component === "success") {
            return (
              <div>
                <Modal
                  isOpen={stats}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <ModalHeader>
                    <Button variant="secondary" onClick={this.onClose}>
                      X
                    </Button>
                    <p> Success </p>
                  </ModalHeader>
                  <ModalBody>
                    <div className="transaction__success">
                      <i class="ion ion-ios-checkmark-circle"></i>
                      <p> Payout success! </p>
                      <button onClick={this.onClose}>
                        Return to game list
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}
export default RequestPayout;
