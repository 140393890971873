import {
  TICK_TIME,
  GET_SINGLE_GAME_DATA,
  GET_SINGLE_GAME_DATA_CHOOSE_MYSELF,
  CHOOSE_FOR_ME,
  SET_FALSE_THE_LOADING,
  SET_FIRST_CLICK,
  SET_FIRST_CLICK_STATUS,
  SET_FIRST_CLICK_ADS_MATCH_STATUS,
  SET_FIRST_CLICK_DELAY,
  GET_CHOOSE_FOR_ME_PATTERN,
  CLEAR_CHOOSE_FOR_ME_PATTERN,
  DONE_CHOOSE_PATTERN,
  CLOSE_CHOOSE_PATTERN,
  CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND,
  CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND_FALSE
} from "../actions/types";

const initialState = {
  singleGameData: [],
  loading: false,
  loadingGame: false,
  isStatus: "",
  arrFirstClick: [],
  firstClickDrawId: null,
  firstClickStatus: false,
  firstClickAdsMatch: null,
  firstClickDelay: null,
  chooseForMePattern: {},
  chooseForMePatternStatus: false,
  doneChoosePattern : false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_FIRST_CLICK:
      const firstClickArr = {
        drawId: parseInt(action.payload.drawId),
        firstClickStatus: true,
        firstClickAdsMatch: parseInt(action.payload.isMatched),
        gameId: parseInt(action.payload.id)
      };
      return {
        ...state,
        arrFirstClick: [
          ...state.arrFirstClick, firstClickArr
        ],
        firstClickDrawId: parseInt(action.payload.drawId),
        firstClickStatus: true,
        firstClickAdsMatch: parseInt(action.payload.isMatched)
      };
    case SET_FIRST_CLICK_STATUS:
      return {
        ...state,
        firstClickStatus: false,
        firstClickDrawId: null
      };
    case SET_FIRST_CLICK_ADS_MATCH_STATUS:
      return {
        ...state,
        firstClickAdsMatch: null
      };
    case SET_FIRST_CLICK_DELAY:
      return {
        ...state,
        firstClickDelay: action.payload === false ? action.payload : true
      };
    case TICK_TIME: {
      const { payload, timeValue, drawPosition, drawId, id, user_id } = action;
      const picked_numbers = payload.picked_numbers.map(pn => {
        return pn.number;
      });

      const four_numbers_draw = payload.four_numbers_draw.map(id => {
        return id.data.id;
      });

      if (timeValue) {
        const newly_picked_numbers = {
          draw_number: parseInt(timeValue),
          draw_position: drawPosition,
          game_id: id,
          user_id: user_id,
          number_draw_id: parseInt(drawId),
          is_match: picked_numbers.includes(parseInt(timeValue)) ? 1 : 0
        };

        const newSingleData = [...state.singleGameData.all_draw_numbers].map(
          nsd => {
            const temp = { ...nsd };
            return temp;
          }
        );
        const newFourNumbersDrawData = [
          ...state.singleGameData.four_numbers_draw
        ].map(nfndd => {
          const temp = { ...nfndd };

          if (nfndd.data.id == drawId) {
            temp.picked_or_already_drawn = true;
          }
          return temp;
        });
        return {
          ...state,
          singleGameData: {
            ...state.singleGameData,
            four_numbers_draw: newFourNumbersDrawData,
            all_draw_numbers: newSingleData.concat(newly_picked_numbers)
          }
        };
      }
    }
    case GET_SINGLE_GAME_DATA: {
      const { payload, status } = action;
      return {
        ...state,
        singleGameData: payload,
        loading: action.loading,
        isStatus: status || "null"
      };
    }
    case GET_SINGLE_GAME_DATA_CHOOSE_MYSELF: {
      return {
        ...state,
        singleGameData: action.payload.data.data,
        loadingGame: action.loadingGame
      };
    }
    case CHOOSE_FOR_ME: {
      return {
        ...state,
        singleGameData: action.payload.data.data,
        loadingGame: action.loadingGame
      };
    }
    case GET_CHOOSE_FOR_ME_PATTERN: {
      return {
        ...state,
        // chooseForMePattern: [[action.payload.pattern].toString().split(","), true],
        chooseForMePattern: [action.payload.pattern, true, action.game_id, action.payload.pattern2],
        chooseForMePatternStatus: true
      }
    }
    case CLEAR_CHOOSE_FOR_ME_PATTERN: {
      return {
        ...state,
        chooseForMePattern: [],
        chooseForMePatternStatus: false
      }
    }

    case SET_FALSE_THE_LOADING: {
      return {
        ...state,
        loadingGame: false
      };
    }
    case DONE_CHOOSE_PATTERN : {
      return {
        ...state,
        doneChoosePattern: true
      }
    }
    case CLOSE_CHOOSE_PATTERN : {
      return {
        ...state,
        doneChoosePattern: false
      }
    }
    case CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND: {
      return {
        ...state,
        chooseForMePatternStatus: true
      }
    }
    case CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND_FALSE: {
      return {
        ...state,
        chooseForMePatternStatus: false
      }
    }
    default: {
      return { ...state };
    }
  }
  
}
