import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Prizes from "../modal/Prizes";
import WinnersCircle from "../modal/WinnersCircle";
import Settings from "../modal/Settings";
import Help from "../modal/Help";
import HowToPlay from "../modal/HowToPlay";

class Menu extends PureComponent {
  state = {
    showModalPrizes: false,
    showModalWinners: false,
    showModalSettings: false,
    showModalHelp: false,
    showModalPlay: false
  };
  prizesStateTofalse = () => {
    this.setState({
      showModalPrizes: !this.state.showModalPrizes
    });
  };
  winnersStateTofalse = () => {
    this.setState({
      showModalWinners: !this.state.showModalWinners
    });
  };
  settingsStateTofalse = () => {
    this.setState({
      showModalSettings: !this.state.showModalSettings
    });
  };
  helpStateTofalse = () => {
    this.setState({
      showModalHelp: !this.state.showModalHelp
    });
  };
  playStateTofalse = () => {
    this.setState({
      showModalPlay: !this.state.showModalPlay
    });
  };
  render() {
    const {
      showModalPrizes,
      showModalWinners,
      showModalSettings,
      showModalHelp,
      showModalPlay
    } = this.state;
    return (
      <div className="tabbar">
        <div>
          <button
            className="tabbar__btn"
            onClick={() => {
              window.open(
                "https://apptech.page.link/?link=https://www.apptechgateway.com/?action=matchitshare",
                "_blank"
              );
            }}
          >
            <img src="/images/share.svg" alt="" />
            <span> {this.props.translations.share} </span>
          </button>
          <button
            className="tabbar__btn"
            onClick={() =>
              this.setState({ showModalPlay: !this.state.showModalPlay })
            }
          >
            <img src="/images/play.svg" alt="" />
            <span> {this.props.translations.howToPlay} </span>
          </button>
          <button
            className="tabbar__btn"
            onClick={() =>
              this.setState({ showModalWinners: !this.state.showModalWinners })
            }
          >
            <img src="/images/winning-circle.svg" alt="" />
            <span> {this.props.translations.winnersCircle} </span>
          </button>
          <button
            className="tabbar__btn"
            onClick={() =>
              this.setState({ showModalPrizes: !this.state.showModalPrizes })
            }
          >
            <img src="/images/prizes.svg" alt="" />
            <span> {this.props.translations.prizes} </span>
          </button>
          <button
            className="tabbar__btn"
            onClick={() =>
              this.setState({
                showModalSettings: !this.state.showModalSettings
              })
            }
          >
            <img src="/images/settings.svg" alt="" />
            <span> {this.props.translations.settings} </span>
          </button>
          <button
            className="tabbar__btn"
            onClick={() =>
              this.setState({ showModalHelp: !this.state.showModalHelp })
            }
          >
            <img src="/images/help.svg" alt="" />
            <span> {this.props.translations.info} </span>
          </button>
        </div>
        {showModalPrizes ? (
          <Prizes closeHelp={this.prizesStateTofalse} />
        ) : null}
        {showModalWinners ? (
          <WinnersCircle closeHelp={this.winnersStateTofalse} />
        ) : null}
        {showModalSettings ? (
          <Settings closeHelp={this.settingsStateTofalse} />
        ) : null}
        {showModalHelp ? <Help closeHelp={this.helpStateTofalse} /> : null}
        {showModalPlay ? <HowToPlay closeHelp={this.playStateTofalse} /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translationReducer
});

export default connect(mapStateToProps, null)(Menu);
