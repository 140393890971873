import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as enTranslations from "./locales/en";
import * as esTranslations from "./locales/es";
import { initReactI18next, I18nextProvider } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";

const resources = {
  en: { messages: enTranslations },
  es: { messages: esTranslations },
};

const i18n = i18next.use(LanguageDetector).use(initReactI18next);

i18n.init({
  react: {
    wait: true,
  },
  resources: resources,
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  ns: ['messages'],
  defaultNS: 'messages',
  fallbackNS: [],
});


const rootElement = document.getElementById('root')
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  rootElement,
);
