import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

class Transaction extends PureComponent {
  state = {
    amountInput: 0,
    balance: 0,
    noAmountEntered: false,
    cantPayout: false
  };

  handleClick = e => {
    const { amountInput } = this.state;
    const { balance } = this.props.history.location.state;

    if (amountInput < 25) {
      this.setState({cantPayout: true})
    } else {
      if (amountInput > balance) {
      
      } else if (amountInput < 0) {
        alert("Invalid amount!");
      } else if (
        amountInput == "" ||
        amountInput == null ||
        amountInput == undefined ||
        amountInput == NaN
      ) {
        this.setState({noAmountEntered: true})
      } else {
        this.props.history.push("/transaction-breakdown", {
          amountInput,
          balance
        });
      }
    }
    // this.props.history.push('/transaction-breakdown', {amountInput, balance});
  };

  handleOnChange = e => {
    this.setState({ amountInput: parseFloat(e.target.value) || 0,  noAmountEntered: false, cantPayout: false});
  };

  render() {
    const { balance } = this.props.history.location.state;
    return (
      <div className="transaction__page">
        <Link to="/transaction" className="nav__header">
          <i className="ion ion-ios-arrow-back"></i>
          Back
        </Link>

        <div className="transaction__innerpage">
          <div className="box__inner">
            <h1 className="transaction__title">Request Payout</h1>
            <div className="transaction__amount">
              <p> ${balance} </p>
              <span> Current FREE Winnings </span>
            </div>
            <div className={balance < 25 ? 'd-none' : 'transaction__input'}>
              <label forhtml="amount"> Amount for Payout </label>
              <input
                type="number"
                id="amount"
                name="amount"
                onChange={this.handleOnChange}
                // disabled
              />
            </div>
            <div className={balance < 25 ? 'alert alert-danger': 'd-none'} role="alert" style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
              Your "Current FREE Winnings" must be at least $25.00!
            </div>
            <div className={this.state.amountInput > balance ? 'alert alert-danger': 'd-none'} role="alert" style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
              The amount you have entered exceeds your "Current FREE Winnings" balance!
            </div>
            <div className={this.state.cantPayout == true ? 'alert alert-danger': 'd-none'} role="alert" style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
              You can't payout with less than $25.00!
            </div>
            <div className={this.state.amountInput < 0 ? 'alert alert-danger': 'd-none'} role="alert" style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
              Invalid Amount!
            </div>
            <div className={this.state.noAmountEntered ? 'alert alert-danger': 'd-none'} role="alert" style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
              No Amount Entered!
            </div>
            <button
              to="/transaction-breakdown"
              className={balance < 25.00 ? 'd-none' : 'modal-submit'}
              onClick={this.handleClick}
            >
              Continue
            </button>
            <Link className="box__back mt-3" to="/">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Transaction;
