export const GET_MATCHIT_DATA = "GET_MATCHIT_DATA";
export const GET_MODAL_CONST = "GET_MODAL_CONST";
export const TICK_TILE = "TICK_TILE";
export const TICK_TIME = "TICK_TIME";
export const AUTHENTICATION = "AUTHENTICATION";
export const IDLE_TIME = "IDLE_TIME";
export const DONE_POPUP = "DONE_POPUP";
export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const DISPLAY_ALL_GAME = "DISPLAY_ALL_GAME";
export const GET_SINGLE_GAME_DATA = "GET_SINGLE_GAME_DATA";
export const CHOOSE_FOR_ME = "CHOOSE_FOR_ME";
export const CHOOSE_MYSELF = "CHOOSE_MYSELF";
export const PICKED_NUMBERS = "PICKED_NUMBERS";
export const REMOVE_STATE_CHOOSE = "REMOVE_STATE_CHOOSE";
export const GET_SINGLE_GAME_DATA_CHOOSE_MYSELF =
  "GET_SINGLE_GAME_DATA_CHOOSE_MYSELF";
export const GET_USERINFO_FROM_URL = "GET_USERINFO_FROM_URL";
export const SET_FALSE_THE_LOADING = "SET_FALSE_THE_LOADING";
export const FETCH_EXISTING_USER_INFO = "FETCH_EXISTING_USER_INFO";
export const SEND_LOCATION = "SEND_LOCATION";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_MODAL = "SET_MODAL";
export const GET_MONEY = "GET_MONEY";
export const SET_FIRST_CLICK = "SET_FIRST_CLICK";
export const SET_FIRST_CLICK_STATUS = "SET_FIRST_CLICK_STATUS";
export const SET_FIRST_CLICK_ADS_MATCH_STATUS =
  "SET_FIRST_CLICK_ADS_MATCH_STATUS";
export const SET_FIRST_CLICK_DELAY = "SET_FIRST_CLICK_DELAY";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const CHECK_PENDING = "CHECK_PENDING";
export const POST_EMAIL = "POST_EMAIL";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const PAYOUT = "PAYOUT";
export const GET_CHOOSE_FOR_ME_PATTERN = "GET_CHOOSE_FOR_ME_PATTERN";
export const CHOOSE_YOUR_GAME_MODAL = "CHOOSE_YOUR_GAME_MODAL";
export const CHOOSE_YOUR_GAME_MODAL_TRUE = "CHOOSE_YOUR_GAME_MODAL_TRUE";
export const CLEAR_CHOOSE_FOR_ME_PATTERN = "CLEAR_CHOOSE_FOR_ME_PATTERN";
export const DONE_CHOOSE_PATTERN = "DONE_CHOOSE_PATTERN";
export const CLOSE_CHOOSE_PATTERN = "CLOSE_CHOOSE_PATTERN";
export const CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND =
  "CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND";
export const CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND_FALSE =
  "CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND_FALSE";
export const CLEAR_CHOOSE_MYSELF_GAME_ID = "CLEAR_CHOOSE_MYSELF_GAME_ID";
export const CLEAR_WINNERS_DATA_FROM_CHOOSEFORME_STATE =
  "CLEAR_WINNERS_DATA_FROM_CHOOSEFORME_STATE";
export const CHANGE_BACKGROUND = "CHANGE_BACKGROUND";
export const GET_LIFELINE_STATS = "GET_LIFELINE_STATS";
export const TRANSLATION = "TRANSLATION";
