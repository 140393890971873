import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  checkEmail,
  checkPending,
  postEmail,
  payout
} from "../../../actions/actions";

class TransactionBreakdown extends PureComponent {
  state = {
    email: "",
    formStatus: true,
    total: 0,
    pendingTransactions: false
  };

  componentDidMount() {
    const mobile_id = JSON.parse(sessionStorage.getItem("authFullInfo")).data
      .mobile_id;
    this.props.checkEmail({ mobile_id });
    this.props.checkPending({ mobile_id });
  }

  handleClick = () => {
    const mobile_id = JSON.parse(sessionStorage.getItem("authFullInfo")).data
      .mobile_id;
    const { balance, amountInput } = this.props.location.state;
    const { receiver } = this.props.getEmail;
    const { getPending } = this.props;

    if (receiver === null || receiver === "") {
      alert("Set an Email to Proceed!");
      this.setState({ email: "", formStatus: false });
    } else if (getPending === true) {
      this.setState({ pendingTransactions: true });
    } else {
      this.setState(
        {
          total: parseFloat(amountInput) - 1.00
        },
        () => {
          const { total } = this.state;
          this.props.payout({ total, mobile_id });
          this.props.history.push("/transaction-success");
        }
      );
    }
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  handleClickEmail = e => {
    e.preventDefault();
    const mobile_id = JSON.parse(sessionStorage.getItem("authFullInfo")).data
      .mobile_id;
    const { email, total } = this.state;

    if (email === "") {
      alert("Please provide an email!");
    } else {
      this.props.postEmail({ email, mobile_id });
      if (this.props.getEmailStatus) {
        alert("Success Email has been save!");
        this.setState({ email: "", formStatus: true });
      }
    }
  };

  render() {
    const { balance, amountInput } = this.props.location.state;
    const { receiver } = this.props.getEmail;
    const { formStatus, pendingTransactions } = this.state;
    return (
      <div className="transaction__page">
        <div className="nav__header">
          <i className="ion ion-ios-arrow-back"></i>
          <Link
            to={{
              pathname: "/transaction-payout",
              state: { balance: this.props.location.state.balance }
            }}
            className="text-light"
          >
            Back
          </Link>
        </div>

        <div className="transaction__innerpage">
          <div className="box__inner">
            <h1 className="transaction__title">Request Payout Breakdown</h1>
            <div className="transaction__amount">
              <p> ${balance} </p>
              <span> Current FREE Winnings </span>
            </div>
            <ul className="transaction__breakdown">
              <li>
                <span> Amount Requested </span>
                <p> ${parseFloat(amountInput).toFixed(2)} </p>
              </li>
              <li>
                {/* <span> PayPal fee <a href="/"> what’s this? </a> </span> */}
                <span> Transaction Fee</span>
                <p> $1.00 </p>
              </li>
              <li>
                <hr />
              </li>
              <li>
                <span> Total </span>
                <p> ${amountInput - 1.00}</p>
              </li>
            </ul>

            <form
              className="form-inline"
              onSubmit={this.handleClickEmail}
              style={{ display: formStatus ? "none" : "" }}
            >
              <span className="email-setup">
                Your ATG account seems to have no paypal email, Set up now!
              </span>
              <input
                type="email"
                className="emailSetup_input"
                onChange={this.handleChange}
              />
              <button type="submit" value="Set Email">
                Set Email
              </button>
            </form>
            <div
              className={
                pendingTransactions == true
                  ? "alert alert-danger mt-3"
                  : "d-none"
              }
              role="alert"
              style={{ width: "90%", margin: "auto", textAlign: "center" }}
            >
              You have pending transactions. Please try again!
            </div>
            <button
              to="/transaction-success"
              className="modal-submit"
              onClick={this.handleClick}
            >
              OK
            </button>
            <Link className="box__back" to="/">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getEmail: state.userMoneyDetails.checkEmail,
  getPending: state.userMoneyDetails.checkPending,
  getEmailStatus: state.userMoneyDetails.postEmailStatus
});

const mapDispatchToProps = dispatch => ({
  checkEmail: payload => dispatch(checkEmail(payload)),
  checkPending: payload => dispatch(checkPending(payload)),
  postEmail: payload => dispatch(postEmail(payload)),
  payout: payload => dispatch(payout(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionBreakdown);
