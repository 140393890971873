import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Transaction extends PureComponent {
  render() {
    const { email, mobileClientID, timezone, token } = JSON.parse(
      sessionStorage.getItem("auth")
    );
    const { city, phone_number, state, country, name, avatar } = JSON.parse(
      sessionStorage.getItem("authFullInfo")
    ).data;

    return (
      <div className="transaction__page">
        <Link
          to={`/?email=${email}&mobileClientID=${mobileClientID}&timezone=${timezone}&token=${token}`}
          className="nav__header"
        >
          <i className="ion ion-ios-arrow-back"></i>
          Back
        </Link>

        <div className="transaction__innerpage">
          <div className="box__inner">
            <div className="profile">
              <div className="profile__info">
                <img
                  className="profile__pic"
                  src={avatar ? avatar : "/images/no-image-icon.png"}
                  alt=""
                />
                <div>
                  <small> {this.props.translations.name} </small>
                  <p> {name} </p>
                </div>
              </div>

              <div className="profile__info">
                <small> {this.props.translations.email} </small>
                <p> {email} </p>
              </div>

              <div className="profile__info__group">
                <div className="profile__info">
                  <small> {this.props.translations.phoneNumber} </small>
                  <p> {phone_number} </p>
                </div>
                <div className="profile__info">
                  <small> {this.props.translations.timezone} </small>
                  <p> {timezone} </p>
                </div>
              </div>
              <div className="profile__info__group">
                <div className="profile__info">
                  <small> {this.props.translations.city} </small>
                  <p> {city} </p>
                </div>
                <div className="profile__info">
                  <small> {this.props.translations.state} </small>
                  <p> {state} </p>
                </div>
              </div>

              <div className="profile__info">
                <small> {this.props.translations.country} </small>
                <p> {country} </p>
              </div>
            </div>

            <button className="modal-submit">
              {this.props.translations.updateProfile}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translationReducer
});

export default connect(mapStateToProps, null)(Transaction);
