import { combineReducers } from "redux";
import matchItReducers from "./matchItReducer";
import modalReducer from "./modalReducer";
import gameReducer from "./gameReducer";
import authReducer from "./authReducer";
import gamesInHomePageReducer from "./gamesInHomePageReducer";
import chooseForMeOrNot from "./chooseForMeOrNot";
import userMoneyDetails from "./userMoneyDetailsReducer";
import transactionHistory from "./transactionReducer";
import backgroundReducer from "./backgroundReducer";
import lifelineStats from "./lifelineStatsReducer";
import translationReducer from "./translation";


export default combineReducers({
  matchIt: matchItReducers,
  modal: modalReducer,
  game: gameReducer,
  authentication: authReducer,
  allGame: gamesInHomePageReducer,
  chooseForMeOrNot: chooseForMeOrNot,
  userMoneyDetails: userMoneyDetails,
  transactionHistory,
  backgroundReducer,
  lifelineStats,
  translationReducer
});
