import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  pickedTotalNumbers,
  fecthSingleGame,
  doneChoosePattern,
  closeChoosePattern,
  clearChooseForMePatternSecondFalse,
  clearChooseForMePattern,
  clearChooseMySelfGameId
} from "../../actions/actions";
import logo from "../../static/gif/load_alt.svg";
import {
  removeChooseState,
  chooseYourGameModalTrue
} from "../../actions/actions";
import ChooseForMePattern from "../modal/chooseForMePattern/ChooseForMePattern";

class HeartShape extends PureComponent {
  state = {
    user_choice_state: {},
    picking_count: 0,
    numbers_picked_int: [],
    fromChoosePatternState: [],
    fromChoosePatternState2: [],
    isClicked: false,
    onChooseForMeSelfLoading: false,
    pickedGivenNumbers: false,
    fromPatternGameId: 0,
    onTileClickEmptyPatterState: false,
    chooseAgain: false,
    choosePattern: false,
    cantPickOnChooseAgain: false,
    buttonText: 'Save Selection'
  };

  componentDidUpdate = () => {
    const { user_choice } = this.props;
    this.setState({ user_choice_state: user_choice });
  };
  componentWillUnmount() {
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ onChooseForMeSelfLoading: false });

    const { dataFromPattern } = this.props;

    if (this.props.doneChoosePatternState === false) {
      if (dataFromPattern !== "" && dataFromPattern[0] !== undefined) {
        if (this.state.onTileClickEmptyPatterState === false) {
          const arrData = dataFromPattern[0];
          const arrData2 = dataFromPattern[3];
          this.setState(
            {
              fromChoosePatternState: arrData,
              fromChoosePatternState2: arrData2,
              fromPatternGameId: dataFromPattern[2],
              cantPickOnChooseAgain: true
            },
            () => {
              this.choosePatternEnableTiles();
            }
          );
        }
      }
    }
    if (this.props.checkChooseForMePatternStatus === true) {
      if (this.state.onTileClickEmptyPatterState === true) {
        const arrData = dataFromPattern[0];
        const arrData2 = dataFromPattern[3];
        this.setState(
          {
            fromChoosePatternState: arrData,
            fromChoosePatternState2: arrData2,
            fromPatternGameId: dataFromPattern[2]
          },
          () => {
            this.clearAndChoosePatternEnableTiles();
          }
        );
      }
    }

  }

  render() {
    const {
      gameCycle,
      gameData,
      pickedNumbers,
      allDrawnNumbers,
      user_choice,
      picking_numbers_from_allgames,
      userId,
      apiToken,
      email,
      mobileId,
      timezone
    } = this.props;

    const {
      user_choice_state,
      picking_count,
      numbers_picked_int,
      fromChoosePatternState,
      fromChoosePatternState2
    } = this.state;

    console.log(gameCycle)

    const vals = pickedNumbers.map(value => {
      return value.number;
    });

    const vals2 = pickedNumbers.map(value => {
      return {number: value.number, isColored: value.is_colored }
    });

    const allDrawnNumbersVals = allDrawnNumbers.map(value => {
      return parseInt(value.draw_number);
    });

    const matched_game = picking_numbers_from_allgames.filter(game => {
      if (
        game.id === user_choice_state.game_id ||
        game.id === this.state.fromPatternGameId
      ) {
        return game;
      }
    });

    this.clearAndChoosePatternEnableTiles = async () => {
      const positionValue = fromChoosePatternState
        ? fromChoosePatternState.map(res =>
          document
            .querySelectorAll(`[position='${res}']`)[0]
            .getAttribute("value")
        )
        : null;

      const positionValue2 = fromChoosePatternState2
        ? fromChoosePatternState2.map(res =>
          document
            .querySelectorAll(`[position='${res}']`)[0]
            .getAttribute("value")
        ) 
        : null;

      this.setState(
        {
          numbers_picked_int: positionValue.concat(positionValue2),
          picking_count: positionValue.length + positionValue2.length,
          pickedGivenNumbers: true,
        },
        () => {
          this.state.numbers_picked_int.map(res => {
            if (
              res ==
              document
                .querySelectorAll(`[value='${res}']`)[0]
                .getAttribute("value")
            ) {
              gameCycle === 1 
               ? document
                  .querySelectorAll(`[value='${res}']`)[0]
                  .classList.add("pattern")
               : document
                  .querySelectorAll(`[value='${res}']`)[0]
                  .classList.add("violet");

              positionValue2.map(res => {
                document
                  .querySelectorAll(`[value='${res}']`)[0]
                  .classList.add("violet")
              });
                
            }
          });
        }
      );

      document
        .querySelectorAll("[name^=tile]")
        .forEach(item => {
          if (item.classList.contains("violet") || item.classList.contains("pattern")) {
            item.classList.remove("violet") 
            item.classList.remove("pattern") 
          }
        }
        );
    };

    this.choosePatternEnableTiles = () => {
      const positionValue = fromChoosePatternState
        ? fromChoosePatternState.map(res =>
          document
            .querySelectorAll(`[position='${res}']`)[0]
            .getAttribute("value")
        ) 
        : null;

      const positionValue2 = fromChoosePatternState2
        ? fromChoosePatternState2.map(res =>
          document
            .querySelectorAll(`[position='${res}']`)[0]
            .getAttribute("value")
        ) 
        : null;
        

      this.setState(
        {
          numbers_picked_int: positionValue.concat(positionValue2),
          picking_count: positionValue.length + positionValue2.length,
          pickedGivenNumbers: true
        },
        () => {
          this.state.numbers_picked_int.map(res => 
            gameCycle === 1 
            ?
              document
                .querySelectorAll(`[value='${res}']`)[0]
                .classList.add("pattern") 
            :
              document
                .querySelectorAll(`[value='${res}']`)[0]
                .classList.add("violet")
          );
        }
      );
      
      positionValue2.map(res => {
          document
            .querySelectorAll(`[value='${res}']`)[0]
            .classList.add("violet") 
      });
        
    };

    this.onTileClick = e => {
      if (this.state.cantPickOnChooseAgain === false) {

        if (
          e.target.getAttribute("name") &&
          !numbers_picked_int.includes(e.target.getAttribute("value"))
        ) {
          e.target.classList.remove("gray");
          e.target.classList.add("violet");
          this.setState({
            picking_count: picking_count + 1,
            numbers_picked_int: [
              ...numbers_picked_int,
              e.target.getAttribute("value")
            ]
          });
        } else if (e.target.getAttribute("name")) {
          e.target.classList.add("gray");
          e.target.classList.remove("violet");
          const filtered_numbers = this.state.numbers_picked_int.filter(npi => {
            if (npi !== e.target.getAttribute("value")) {
              return npi;
            }
          });

          this.setState({
            numbers_picked_int: filtered_numbers,
            picking_count: this.state.numbers_picked_int.length - 1
          });
        }
        if (matched_game[0]) {
          if (matched_game[0].number_picking === this.state.picking_count + 1) {
            this.setState({ pickedGivenNumbers: true });
          }
        }
      }
    };

    this.onTileClickFalse = e => {
      this.setState({
        fromChoosePatternState: [],
        onTileClickEmptyPatterState: true
      });
      if (this.state.cantPickOnChooseAgain === false) {
        if (
          matched_game[0] &&
          this.state.pickedGivenNumbers === false &&
          this.state.picking_count === matched_game[0].number_picking
        ) {
          this.setState({ pickedGivenNumbers: true });
        } else {
          this.setState({ pickedGivenNumbers: false });
        }

        if (this.state.pickedGivenNumbers && e.target.getAttribute("name")) {
          if (!numbers_picked_int.includes(e.target.getAttribute("value"))) {
            return null;
          } else {
            e.target.classList.add("gray");
            e.target.classList.remove("violet");
            const filtered_numbers = this.state.numbers_picked_int.filter(npi => {
              if (npi !== e.target.getAttribute("value")) {
                return npi;
              }
            });
            this.setState({
              numbers_picked_int: filtered_numbers,
              picking_count: this.state.numbers_picked_int.length - 1
            });
          }
        }
      }
    };

    this.onSave = async () => {
      this.setState({
        onChooseForMeSelfLoading: true,
        buttonText: 'Saving...',
      });
      this.props.doneChoosePattern();
      this.props.onBackClicked();
      this.props.clearChooseForMePattern();
      this.props.clearChooseMySelfGameId();

      const total_numbers = numbers_picked_int.toString();
      const id = user_choice.game_id;
      await this.props.pickedTotalNumbers({
        total_numbers,
        user_choice,
        userId,
        fromPatternGameId: this.state.fromPatternGameId,
        apiToken,
        id,
        email,
        mobileId,
        timezone
      });
      this.setState({
        isClicked: true,
        numbers_picked_int: [],
        fromChoosePatternState: [],
        pickedGivenNumbers: false,
        picking_count: 0,
        fromPatternGameId: 0
      });
    };

    this.chooseAgain = async () => {
      await this.props.chooseYourGameModalTrue();
      await this.props.clearChooseForMePatternSecondFalse();
    };

    return (
      <React.Fragment>
        {(() => {
          if (this.state.onChooseForMeSelfLoading) {
            return (
              <div>
                <img className="game__loader" src={logo} alt="" />
              </div>
            );
          } else {
            return (
              <div
                className="game-tiles"
                onClick={
                  (user_choice.mySelf === true ||
                    this.state.fromPatternGameId !== 0) &&
                    matched_game[0] &&
                    this.state.picking_count <= matched_game[0].number_picking - 1
                    ? this.onTileClick
                    : this.onTileClickFalse
                }
              >
                {(this.state.user_choice_state.mySelf ||
                  this.state.fromPatternGameId) &&
                  matched_game[0] &&
                  !this.state.isClicked ? (
                    <h1> {this.props.translations.choose} {matched_game[0].number_picking} {this.props.translations.numbers}</h1>
                  ) : null}

                <div className="flex justify-content-center">
                  <div className="invisible disabled"></div>
                  <div
                    name="tile_1"
                    value={gameData[0].number}
                    position={gameData[0].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[0].number) && !allDrawnNumbersVals.includes(gameData[0].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[0].number).isColored === 1 ? "border-game-tile-top red pattern" : "border-game-tile-top red violet"
                      :
                        vals2.find(fruit => fruit.number === gameData[0].number) && allDrawnNumbersVals.includes(gameData[0].number) && vals2.find(fruit => fruit.number === gameData[0].number).isColored === 0 && gameCycle === 1
                          ? 
                            "border-game-tile-top red match2"
                          :  
                            vals2.find(fruit => fruit.number === gameData[0].number) && allDrawnNumbersVals.includes(gameData[0].number)
                            ? 
                              "border-game-tile-top red match"
                            :
                              allDrawnNumbersVals.includes(gameData[0].number)
                                ? "border-game-tile-top red red"
                                : "border-game-tile-top red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[0].number} </strong>{" "}
                    </span>
                  </div>
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                  <div
                    name="tile_2"
                    value={gameData[1].number}
                    position={gameData[1].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[1].number) && !allDrawnNumbersVals.includes(gameData[1].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[1].number).isColored === 1 ? "border-game-tile-top red pattern" : "border-game-tile-top red violet"
                      :
                        vals2.find(fruit => fruit.number === gameData[1].number) && allDrawnNumbersVals.includes(gameData[1].number) && vals2.find(fruit => fruit.number === gameData[1].number).isColored === 0 && gameCycle === 1
                          ? 
                            "border-game-tile-top red match2"
                          : 
                            vals2.find(fruit => fruit.number === gameData[1].number) && allDrawnNumbersVals.includes(gameData[1].number)
                            ? "border-game-tile-top red match"
                            :  
                              allDrawnNumbersVals.includes(gameData[1].number)
                                ? "border-game-tile-top red red"
                                : "border-game-tile-top red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[1].number} </strong>{" "}
                    </span>
                  </div>
                  <div className="invisible disabled"></div>
                </div>
                <div className="flex justify-content-center">
                  <div
                    name="tile_3"
                    value={gameData[2].number}
                    position={gameData[2].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[2].number) && !allDrawnNumbersVals.includes(gameData[2].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[2].number).isColored === 1 ? "border-game-tile-top red pattern" : "border-game-tile-top red violet"
                      :
                        vals2.find(fruit => fruit.number === gameData[2].number) && allDrawnNumbersVals.includes(gameData[2].number) && vals2.find(fruit => fruit.number === gameData[2].number).isColored === 0 && gameCycle === 1
                          ? 
                            "border-game-tile-top red match2"
                          :  
                            vals2.find(fruit => fruit.number === gameData[2].number) && allDrawnNumbersVals.includes(gameData[2].number)
                            ? 
                              "border-game-tile-top red match"
                            :
                              allDrawnNumbersVals.includes(gameData[2].number)
                                ? "border-game-tile-top red red"
                                : "border-game-tile-top red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[2].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_4"
                    value={gameData[3].number}
                    position={gameData[3].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[3].number) && !allDrawnNumbersVals.includes(gameData[3].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[3].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[3].number) && allDrawnNumbersVals.includes(gameData[3].number) && vals2.find(fruit => fruit.number === gameData[3].number).isColored === 0 && gameCycle === 1
                        ? 
                          "border-game-tile-top red match2"
                        : 
                          vals2.find(fruit => fruit.number === gameData[3].number) && allDrawnNumbersVals.includes(gameData[3].number)
                            ? 
                              "border-game-tile-top red match"
                            :
                              allDrawnNumbersVals.includes(gameData[3].number)
                                  ? "red red"
                                  : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[3].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_5"
                    value={gameData[4].number}
                    position={gameData[4].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[4].number) && !allDrawnNumbersVals.includes(gameData[4].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[4].number).isColored === 1 ? "border-game-tile-top red pattern" : "border-game-tile-top red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[4].number) && allDrawnNumbersVals.includes(gameData[4].number) && vals2.find(fruit => fruit.number === gameData[4].number).isColored === 0 && gameCycle === 1
                        ? 
                          "border-game-tile-top red match2"
                        : 
                          vals2.find(fruit => fruit.number === gameData[4].number) && allDrawnNumbersVals.includes(gameData[4].number)
                            ? 
                              "border-game-tile-top red match"
                            : 
                            allDrawnNumbersVals.includes(gameData[4].number)
                              ? "border-game-tile-top red red"
                              : "border-game-tile-top red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[4].number} </strong>{" "}
                    </span>
                  </div>
                  <div className="invisible disabled"></div>
                  <div
                    name="tile_6"
                    value={gameData[5].number}
                    position={gameData[5].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[5].number) && !allDrawnNumbersVals.includes(gameData[5].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[5].number).isColored === 1 ? "border-game-tile-top red pattern" : "border-game-tile-top red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[5].number) && allDrawnNumbersVals.includes(gameData[5].number) && vals2.find(fruit => fruit.number === gameData[5].number).isColored === 0 && gameCycle === 1
                        ? 
                          "border-game-tile-top red match2"
                        :  
                          vals2.find(fruit => fruit.number === gameData[5].number) && allDrawnNumbersVals.includes(gameData[5].number)
                          ? 
                            "border-game-tile-top red match"
                          : 
                            allDrawnNumbersVals.includes(gameData[5].number)
                              ? "border-game-tile-top red red"
                              : "border-game-tile-top red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[5].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_7"
                    value={gameData[6].number}
                    position={gameData[6].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[6].number) && !allDrawnNumbersVals.includes(gameData[6].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[6].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[6].number) && allDrawnNumbersVals.includes(gameData[6].number) && vals2.find(fruit => fruit.number === gameData[6].number).isColored === 0 && gameCycle === 1
                        ? 
                          "red match2"
                        :   
                          vals2.find(fruit => fruit.number === gameData[6].number) && allDrawnNumbersVals.includes(gameData[6].number)
                          ? 
                            "red match"
                          :
                            allDrawnNumbersVals.includes(gameData[6].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[6].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_8"
                    value={gameData[7].number}
                    position={gameData[7].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[7].number) && !allDrawnNumbersVals.includes(gameData[7].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[7].number).isColored === 1 ? "border-game-tile-top red pattern" : "border-game-tile-top red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[7].number) && allDrawnNumbersVals.includes(gameData[7].number) && vals2.find(fruit => fruit.number === gameData[7].number).isColored === 0 && gameCycle === 1
                        ? 
                          "border-game-tile-top red match2"
                        :  
                          vals2.find(fruit => fruit.number === gameData[7].number) && allDrawnNumbersVals.includes(gameData[7].number)
                          ? 
                            "border-game-tile-top red match"
                          :
                            allDrawnNumbersVals.includes(gameData[7].number)
                              ? "border-game-tile-top red red"
                              : "border-game-tile-top red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[7].number} </strong>{" "}
                    </span>
                  </div>
                </div>
                <div className="flex justify-content-center">
                  <div className="border-game-tile-lb">
                    <div
                      name="tile_9"
                      value={gameData[8].number}
                      position={gameData[8].position}
                      className={
                        vals2.find(fruit => fruit.number === gameData[8].number) && !allDrawnNumbersVals.includes(gameData[8].number) 
                        ? 
                          vals2.find(fruit => fruit.number === gameData[8].number).isColored === 1 ? "red pattern" : "red violet"
                        : 
                          vals2.find(fruit => fruit.number === gameData[8].number) && allDrawnNumbersVals.includes(gameData[8].number) && vals2.find(fruit => fruit.number === gameData[8].number).isColored === 0 && gameCycle === 1
                            ? 
                              "red match match2"
                            : 
                              vals2.find(fruit => fruit.number === gameData[8].number) && allDrawnNumbersVals.includes(gameData[8].number)
                              ? 
                                "red match" 
                              : 
                                allDrawnNumbersVals.includes(gameData[8].number)
                                  ? "red red"
                                  : "red gray"
                      }
                    >                      
                      <span>
                        {" "}
                        <strong> {gameData[8].number} </strong>{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    name="tile_10"
                    value={gameData[9].number}
                    position={gameData[9].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[9].number) && !allDrawnNumbersVals.includes(gameData[9].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[9].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[9].number) && allDrawnNumbersVals.includes(gameData[9].number) && vals2.find(fruit => fruit.number === gameData[9].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          :  
                          vals2.find(fruit => fruit.number === gameData[9].number) && allDrawnNumbersVals.includes(gameData[9].number)
                          ? 
                            "red match"
                          :  
                            allDrawnNumbersVals.includes(gameData[9].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[9].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_11"
                    value={gameData[10].number}
                    position={gameData[10].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[10].number) && !allDrawnNumbersVals.includes(gameData[10].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[10].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[10].number) && allDrawnNumbersVals.includes(gameData[10].number) && vals2.find(fruit => fruit.number === gameData[10].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          :  
                          vals2.find(fruit => fruit.number === gameData[10].number) && allDrawnNumbersVals.includes(gameData[10].number)
                          ? 
                            "red match"
                          :  
                            allDrawnNumbersVals.includes(gameData[10].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[10].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_12"
                    value={gameData[11].number}
                    position={gameData[11].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[11].number) && !allDrawnNumbersVals.includes(gameData[11].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[11].number).isColored === 1 ? "border-game-tile-top red pattern" : "border-game-tile-top red violet"
                      :
                        vals2.find(fruit => fruit.number === gameData[11].number) && allDrawnNumbersVals.includes(gameData[11].number) && vals2.find(fruit => fruit.number === gameData[11].number).isColored === 0 && gameCycle === 1
                            ? 
                              "border-game-tile-top red match2"
                            :  
                              vals2.find(fruit => fruit.number === gameData[11].number) && allDrawnNumbersVals.includes(gameData[11].number)
                              ? 
                                "border-game-tile-top red match"
                              : 
                                allDrawnNumbersVals.includes(gameData[11].number)
                                  ? "border-game-tile-top red red"
                                  : "border-game-tile-top red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[11].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_13"
                    value={gameData[12].number}
                    position={gameData[12].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[12].number) && !allDrawnNumbersVals.includes(gameData[12].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[12].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[12].number) && allDrawnNumbersVals.includes(gameData[12].number) && vals2.find(fruit => fruit.number === gameData[12].number).isColored === 0 && gameCycle === 1
                        ? 
                          "red match2"
                        : 
                          vals2.find(fruit => fruit.number === gameData[12].number) && allDrawnNumbersVals.includes(gameData[12].number)
                          ? 
                            "red match"
                          :  
                            allDrawnNumbersVals.includes(gameData[12].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[12].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_14"
                    value={gameData[13].number}
                    position={gameData[13].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[13].number) && !allDrawnNumbersVals.includes(gameData[13].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[13].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[13].number) && allDrawnNumbersVals.includes(gameData[13].number) && vals2.find(fruit => fruit.number === gameData[13].number).isColored === 0 && gameCycle === 1
                        ? 
                          "red match2"
                        : 
                          vals2.find(fruit => fruit.number === gameData[13].number) && allDrawnNumbersVals.includes(gameData[13].number)
                          ? 
                            "red match"
                          : 
                            allDrawnNumbersVals.includes(gameData[13].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[13].number} </strong>{" "}
                    </span>
                  </div>
                  <div className="border-game-tile-rb">
                    <div
                      name="tile_15"
                      value={gameData[14].number}
                      position={gameData[14].position}
                      className={
                        vals2.find(fruit => fruit.number === gameData[14].number) && !allDrawnNumbersVals.includes(gameData[14].number) 
                        ? 
                          vals2.find(fruit => fruit.number === gameData[14].number).isColored === 1 ? "red pattern" : "red violet"
                        : 
                          vals2.find(fruit => fruit.number === gameData[14].number) && allDrawnNumbersVals.includes(gameData[14].number) && vals2.find(fruit => fruit.number === gameData[14].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          :  
                            vals2.find(fruit => fruit.number === gameData[14].number) && allDrawnNumbersVals.includes(gameData[14].number)
                            ? 
                              "red match"
                            :
                              allDrawnNumbersVals.includes(gameData[14].number)
                                ? "red red"
                                : "red gray"
                      }
                    >
                      <span>
                        {" "}
                        <strong> {gameData[14].number} </strong>{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-content-center">
                  <div className="invisible disabled"></div>
                  <div className="border-game-tile-lb">
                    <div
                      name="tile_16"
                      value={gameData[15].number}
                      position={gameData[15].position}
                      className={
                        vals2.find(fruit => fruit.number === gameData[15].number) && !allDrawnNumbersVals.includes(gameData[15].number) 
                        ? 
                          vals2.find(fruit => fruit.number === gameData[15].number).isColored === 1 ? "red pattern" : "red violet"
                        : 
                          vals2.find(fruit => fruit.number === gameData[15].number) && allDrawnNumbersVals.includes(gameData[15].number) && vals2.find(fruit => fruit.number === gameData[15].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          :  
                            vals2.find(fruit => fruit.number === gameData[15].number) && allDrawnNumbersVals.includes(gameData[15].number)
                            ? 
                              "red match"
                            :
                              allDrawnNumbersVals.includes(gameData[15].number)
                                ? "red red"
                                : "red gray"
                      }
                    >
                      <span>
                        {" "}
                        <strong> {gameData[15].number} </strong>{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    name="tile_17"
                    value={gameData[16].number}
                    position={gameData[16].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[16].number) && !allDrawnNumbersVals.includes(gameData[16].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[16].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[16].number) && allDrawnNumbersVals.includes(gameData[16].number) && vals2.find(fruit => fruit.number === gameData[16].number).isColored === 0 && gameCycle === 1
                        ? 
                          "red match2"
                        :  
                          vals2.find(fruit => fruit.number === gameData[16].number) && allDrawnNumbersVals.includes(gameData[16].number)
                          ? 
                            "red match"
                          :
                            allDrawnNumbersVals.includes(gameData[16].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[16].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_18"
                    value={gameData[17].number}
                    position={gameData[17].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[17].number) && !allDrawnNumbersVals.includes(gameData[17].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[17].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[17].number) && allDrawnNumbersVals.includes(gameData[17].number) && vals2.find(fruit => fruit.number === gameData[17].number).isColored === 0 && gameCycle === 1
                        ? 
                          "red match2"
                        :  
                          vals2.find(fruit => fruit.number === gameData[17].number) && allDrawnNumbersVals.includes(gameData[17].number)
                          ? 
                            "red match"
                          :
                            allDrawnNumbersVals.includes(gameData[17].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[17].number} </strong>{" "}
                    </span>
                  </div>
                  <div
                    name="tile_19"
                    value={gameData[18].number}
                    position={gameData[18].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[18].number) && !allDrawnNumbersVals.includes(gameData[18].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[18].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[18].number) && allDrawnNumbersVals.includes(gameData[18].number) && vals2.find(fruit => fruit.number === gameData[18].number).isColored === 0 && gameCycle === 1
                        ? 
                          "red match2"
                        :  
                          vals2.find(fruit => fruit.number === gameData[18].number) && allDrawnNumbersVals.includes(gameData[18].number)
                          ? 
                            "red match"
                          :
                            allDrawnNumbersVals.includes(gameData[18].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[18].number} </strong>{" "}
                    </span>
                  </div>
                  <div className="border-game-tile-rb">
                    <div
                      name="tile_20"
                      value={gameData[19].number}
                      position={gameData[19].position}
                      className={
                        vals2.find(fruit => fruit.number === gameData[19].number) && !allDrawnNumbersVals.includes(gameData[19].number) 
                        ? 
                          vals2.find(fruit => fruit.number === gameData[19].number).isColored === 1 ? "red pattern" : "red violet"
                        : 
                          vals2.find(fruit => fruit.number === gameData[19].number) && allDrawnNumbersVals.includes(gameData[19].number) && vals2.find(fruit => fruit.number === gameData[19].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          :  
                            vals2.find(fruit => fruit.number === gameData[19].number) && allDrawnNumbersVals.includes(gameData[19].number)
                            ? 
                              "red match"
                            :
                              allDrawnNumbersVals.includes(gameData[19].number)
                                ? "red red"
                                : "red gray"
                      }
                    >
                      <span>
                        {" "}
                        <strong> {gameData[19].number} </strong>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="invisible disabled"></div>
                </div>
                <div className="flex justify-content-center">
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                  <div className="border-game-tile-lb">
                    <div
                      name="tile_21"
                      value={gameData[20].number}
                      position={gameData[20].position}
                      className={
                        vals2.find(fruit => fruit.number === gameData[20].number) && !allDrawnNumbersVals.includes(gameData[20].number) 
                        ? 
                          vals2.find(fruit => fruit.number === gameData[20].number).isColored === 1 ? "red pattern" : "red violet"
                        : 
                          vals2.find(fruit => fruit.number === gameData[20].number) && allDrawnNumbersVals.includes(gameData[20].number) && vals2.find(fruit => fruit.number === gameData[20].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          : 
                            vals2.find(fruit => fruit.number === gameData[20].number) && allDrawnNumbersVals.includes(gameData[20].number)
                            ? 
                              "red match"
                            : 
                              allDrawnNumbersVals.includes(gameData[20].number)
                                ? "red red"
                                : "red gray"
                      }
                    >
                      <span>
                        {" "}
                        <strong> {gameData[20].number} </strong>{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    name="tile_22"
                    value={gameData[21].number}
                    position={gameData[21].position}
                    className={
                      vals2.find(fruit => fruit.number === gameData[21].number) && !allDrawnNumbersVals.includes(gameData[21].number) 
                      ? 
                        vals2.find(fruit => fruit.number === gameData[21].number).isColored === 1 ? "red pattern" : "red violet"
                      : 
                        vals2.find(fruit => fruit.number === gameData[21].number) && allDrawnNumbersVals.includes(gameData[21].number) && vals2.find(fruit => fruit.number === gameData[21].number).isColored === 0 && gameCycle === 1
                        ? 
                          "red match2"
                        : 
                          vals2.find(fruit => fruit.number === gameData[21].number) && allDrawnNumbersVals.includes(gameData[21].number)
                          ? 
                            "red match"
                          : 
                            allDrawnNumbersVals.includes(gameData[21].number)
                              ? "red red"
                              : "red gray"
                    }
                  >
                    <span>
                      {" "}
                      <strong> {gameData[21].number} </strong>{" "}
                    </span>
                  </div>
                  <div className="border-game-tile-rb">
                    <div
                      name="tile_23"
                      value={gameData[22].number}
                      position={gameData[22].position}
                      className={
                        vals2.find(fruit => fruit.number === gameData[22].number) && !allDrawnNumbersVals.includes(gameData[22].number) 
                        ? 
                          vals2.find(fruit => fruit.number === gameData[22].number).isColored === 1 ? "red pattern" : "red violet"
                        : 
                          vals2.find(fruit => fruit.number === gameData[22].number) && allDrawnNumbersVals.includes(gameData[22].number) && vals2.find(fruit => fruit.number === gameData[22].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          :  
                            vals2.find(fruit => fruit.number === gameData[22].number) && allDrawnNumbersVals.includes(gameData[22].number)
                            ? 
                              "red match"
                            :
                              allDrawnNumbersVals.includes(gameData[22].number)
                                ? "red red"
                                : "red gray"
                      }
                    >
                      <span>
                        {" "}
                        <strong> {gameData[22].number} </strong>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                </div>
                <div className="flex justify-content-between">
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                  <div className="border-game-tile-bottom">
                    <div
                      name="tile_24"
                      value={gameData[23].number}
                      position={gameData[23].position}
                      className={
                        vals2.find(fruit => fruit.number === gameData[23].number) && !allDrawnNumbersVals.includes(gameData[23].number) 
                        ? 
                          vals2.find(fruit => fruit.number === gameData[23].number).isColored === 1 ? "red pattern" : "red violet"
                        : 
                          vals2.find(fruit => fruit.number === gameData[23].number) && allDrawnNumbersVals.includes(gameData[23].number) && vals2.find(fruit => fruit.number === gameData[23].number).isColored === 0 && gameCycle === 1
                          ? 
                            "red match2"
                          :  
                            vals2.find(fruit => fruit.number === gameData[23].number) && allDrawnNumbersVals.includes(gameData[23].number)
                            ? 
                              "red match"
                            :
                              allDrawnNumbersVals.includes(gameData[23].number)
                                ? "red red"
                                : "red gray"
                      }
                    >
                      <span>
                        {" "}
                        <strong> {gameData[23].number} </strong>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                  <div className="invisible disabled"></div>
                </div>
                {matched_game[0] &&
                  !this.props.dataFromPattern[1] &&
                  this.state.picking_count >= matched_game[0].number_picking &&
                  !this.state.isClicked ? (
                    <button
                      className={
                        "modal-submit  " +
                        (this.state.buttonText === this.props.translations.saving ? this.props.translations.disabled : "")
                      }
                      onClick={this.onSave}
                    >
                      {this.props.translations.saveSelection}
                    </button>
                  ) : null}
                {this.props.dataFromPattern[1] === true &&
                  matched_game[0] &&
                  this.state.picking_count >= matched_game[0].number_picking &&
                  !this.state.isClicked ? (
                    <button
                      className={
                        "modal-submit " +
                        (this.state.buttonText === this.props.translations.saving ? this.props.translations.disabled : "")
                      }
                      onClick={this.onSave}
                    >
                      {this.state.buttonText}
                    </button>
                  ) : null}
                {this.props.dataFromPattern[1] === true &&
                  matched_game[0] &&
                  this.state.picking_count >= matched_game[0].number_picking &&
                  !this.state.isClicked ? (
                    <button className="modal-submit" onClick={this.chooseAgain}>
                      {this.props.translations.choosePatternAgain}
                    </button>
                  ) : null}

                {this.props.modalStatus && !matched_game[0] === '' ? (
                  <ChooseForMePattern
                    game_id={this.props.gameId}
                    number_picking={this.state.picking_count}
                    api_token={this.props.apiToken}
                    user_id={this.props.userId}
                  />
                ) : null}
              </div>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user_choice: state.chooseForMeOrNot,
  picking_numbers_allgames: state.allGame,
  isLoading: state.game.loadingGame,
  dataFromPattern: state.game.chooseForMePattern
    ? state.game.chooseForMePattern
    : [],
  chooseYourGameState: state.modal.chooseYourGameState,
  doneChoosePatternState: state.game.doneChoosePattern,
  modalStatus: state.modal.chooseYourGameState,
  checkChooseForMePatternStatus: state.game.chooseForMePatternStatus,
  translations: state.translationReducer
});

const mapDispatchToProps = dispatch => ({
  pickedTotalNumbers: payload => dispatch(pickedTotalNumbers(payload)),
  pageRefreshes: payload => dispatch(fecthSingleGame(payload)),
  onBackClicked: () => dispatch(removeChooseState()),
  closeChoosePattern: () => dispatch(closeChoosePattern()),
  doneChoosePattern: () => dispatch(doneChoosePattern()),
  chooseYourGameModalTrue: () => dispatch(chooseYourGameModalTrue()),
  clearChooseForMePatternSecondFalse: () => dispatch(clearChooseForMePatternSecondFalse()),
  clearChooseForMePattern: () => dispatch(clearChooseForMePattern()),
  clearChooseMySelfGameId: () => dispatch(clearChooseMySelfGameId())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeartShape);