import React, { PureComponent } from "react";
import auth from "../../auth/Auth";
import queryString from "query-string";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  idleTimeAction,
  fetchUserInfoAction,
  sendUserLocationAction,
  displayAllgame,
  fetchExistingUserInfoAction,
} from "../../actions/actions";

const authHOC = (AuthenticationComponent) => {
  class AuthHOC extends PureComponent {
    state = {
      idle: 300, //5 minutes abd 20 seconds
      isMounted: false,
      reload: false,
    };

    componentWillUnmount() {
      this.setState({ isMounted: false });
      clearInterval(this.interval);
    }
    componentDidMount() {
      this.setState({ isMounted: true });
      // const url = queryString.parse(this.props.location.search);
    }

    screenTime = async () => {
      // this.setState({ isMounted: true });
      // this.isMounted = true
      // clearInterval(this.interval);
      // if (this.state.isMounted === true) {
      //   this.resetTimer = () => {
      //     this.setState({ ...this.state, idle: 300 });
      //   };
      //   this.movements = {
      //     movement: [
      //       document.addEventListener("mousemove", this.resetTimer, false),
      //       document.addEventListener("mousedown", this.resetTimer, false),
      //       document.addEventListener("keypress", this.resetTimer, false),
      //       document.addEventListener("touchmove", this.resetTimer, false)
      //     ]
      //   };
      // }
    };

    componentDidUpdate() {
      if (
        !sessionStorage.getItem("allGameData") &&
        window.location.pathname !== "/"
      ) {
        sessionStorage.setItem(
          "allGameData",
          JSON.stringify(this.props.allGameData)
        );
      }
    }

    securedAuthMethod = async () => {
      // this.screenTime();

      const queryValues = queryString.parse(this.props.location.search);

      if (!sessionStorage.getItem("auth")) {
        sessionStorage.setItem("auth", JSON.stringify(queryValues));
        if (queryValues && window.location.pathname === "/") {
          await this.props.fetchUserInfo({
            urlOriginState: JSON.parse(
              sessionStorage.getItem("auth"),
              queryValues
            ),
          });

          if (
            sessionStorage.getItem("authorizedGeoLocation") == null ||
            sessionStorage.getItem("authorizedGeoLocation") == ""
          ) {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                successFunction,
                errorFunction
              );
            }
            //Get latitude and longitude;
            function successFunction(position) {
              var clientLatitude = position.coords.latitude;
              var clientLongitude = position.coords.longitude;

              sessionStorage.setItem("long", clientLongitude);
              sessionStorage.setItem("lat", clientLatitude);
              sessionStorage["authorizedGeoLocation"] = 1;
            }
            function errorFunction() {
              sessionStorage["authorizedGeoLocation"] = 0;
            }
            function checkauthorizedGeoLocation() {
              if (
                typeof sessionStorage["authorizedGeoLocation"] == "undefined" ||
                sessionStorage["authorizedGeoLocation"] == "0"
              )
                return false;
              else return true;
            }
          }

          sessionStorage.setItem(
            "authFullInfo",
            JSON.stringify(this.props.authData)
          );
        }
      } else {
        const existingUserInfo = JSON.parse(
          sessionStorage.getItem("authFullInfo")
        );
        this.props.fetchExistingUserInfo(existingUserInfo);
      }

      const status = JSON.parse(sessionStorage.getItem("authFullInfo"));
      const isLogout = sessionStorage.getItem("isLogout");

      if (status && status.status === "success" && isLogout !== "true") {
        sessionStorage.setItem("status", "success");
        auth.login(() => {
          // if (this.state.isMounted === true) {
          this.interval = setInterval(() => {
            // logout the user when idle for 8 minutes
            this.setState((prevState) => ({
              idle:
                prevState.idle === 0
                  ? (0,
                    auth.logout(() => {
                      this.props.history.push("/pagenotavailable");
                      sessionStorage.clear();
                      sessionStorage.clear();
                      sessionStorage.setItem("isLogout", "true");
                    }))
                  : prevState.idle - 1,
            }));
          }, 1000);
          // }
        });
      } else {
        auth.logout(() => {
          sessionStorage.clear();
          sessionStorage.clear();
          sessionStorage.setItem("isLogout", "true");
          if (window.location.pathname === "/") {
            this.props.history.push("/errorboundary");
          } else {
            this.props.history.push("/pagenotavailable");
          }
        });
      }
    };

    render() {
      // const { hostname } = window.location;
      // const newUrl = 'apptech-matchit-v2.web.app';
      // if (hostname !== newUrl)
      //   window.location.href = `https://${newUrl}/${this.props.location.search}`;
      const { securedAuthMethod } = this;
      return (
        <AuthenticationComponent
          HOC_IntervalId={this.state.interval}
          HOC_Params={this.props.match}
          HOC_AllGameData={this.props.allGameData}
          HOC_SecuredAuthMethod={securedAuthMethod}
          HOC_UserALLInfo={this.props.authData}
          HOC_IsLoading={this.props.isLoading}
          HOC_GameIsLoading={this.props.gameIsLoading}
          HOC_URLOrigin={this.state.urlOriginState}
          HOC_GetMoney={this.props.getMoney}
        />
      );
    }
  }
  return AuthHOC;
};
const mapDispatchToProps = (dispatch) => ({
  checkUserInactivity: (payload) => dispatch(idleTimeAction(payload)),
  fetchUserInfo: (payload) => dispatch(fetchUserInfoAction(payload)),
  sendLocation: (payload) => dispatch(sendUserLocationAction(payload)),
  fetchAllGame: (payload) => dispatch(displayAllgame(payload)),
  fetchExistingUserInfo: (payload) =>
    dispatch(fetchExistingUserInfoAction(payload)),
});

const mapStateToProps = (state) => ({
  authData: state.authentication.info,
  donePopUp: state.authentication.donePopUp,
  allGameData: state.allGame.games,
  isLoading: state.allGame.loading,
  gameIsLoading: state.game.loadingGame,
  getMoney: state.userMoneyDetails,
});

export default compose(connect(mapStateToProps, mapDispatchToProps), authHOC);
