import React, { PureComponent } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Carousel from "nuka-carousel";
import { connect } from "react-redux";
import { closeModal } from "../../../actions/actions";

class WinnersCircleWinnings extends PureComponent {
  state = { modalState: true };

  onClose = () => {
    this.props.closeModal();
    this.setState({ modalState: !this.state.modalState });
  };

  render() {
    return (
      <Modal
        isOpen={this.state.modalState}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <Button variant="secondary" onClick={this.onClose}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3138 11.8998L16.8488 8.36382C16.9443 8.27157 17.0205 8.16123 17.0729 8.03922C17.1253 7.91722 17.1529 7.786 17.1541 7.65322C17.1552 7.52044 17.1299 7.38876 17.0796 7.26587C17.0294 7.14297 16.9551 7.03132 16.8612 6.93742C16.7673 6.84353 16.6557 6.76928 16.5328 6.719C16.4099 6.66872 16.2782 6.64342 16.1454 6.64457C16.0126 6.64572 15.8814 6.67331 15.7594 6.72572C15.6374 6.77813 15.5271 6.85431 15.4348 6.94982L11.8988 10.4848L8.36382 6.94982C8.27157 6.85431 8.16123 6.77813 8.03922 6.72572C7.91722 6.67331 7.786 6.64572 7.65322 6.64457C7.52044 6.64342 7.38876 6.66872 7.26587 6.719C7.14297 6.76928 7.03132 6.84353 6.93742 6.93742C6.84353 7.03132 6.76928 7.14297 6.719 7.26587C6.66872 7.38876 6.64342 7.52044 6.64457 7.65322C6.64572 7.786 6.67331 7.91722 6.72572 8.03922C6.77813 8.16123 6.85431 8.27157 6.94982 8.36382L10.4848 11.8988L6.94982 15.4348C6.85431 15.5271 6.77813 15.6374 6.72572 15.7594C6.67331 15.8814 6.64572 16.0126 6.64457 16.1454C6.64342 16.2782 6.66872 16.4099 6.719 16.5328C6.76928 16.6557 6.84353 16.7673 6.93742 16.8612C7.03132 16.9551 7.14297 17.0294 7.26587 17.0796C7.38876 17.1299 7.52044 17.1552 7.65322 17.1541C7.786 17.1529 7.91722 17.1253 8.03922 17.0729C8.16123 17.0205 8.27157 16.9443 8.36382 16.8488L11.8988 13.3138L15.4348 16.8488C15.5271 16.9443 15.6374 17.0205 15.7594 17.0729C15.8814 17.1253 16.0126 17.1529 16.1454 17.1541C16.2782 17.1552 16.4099 17.1299 16.5328 17.0796C16.6557 17.0294 16.7673 16.9551 16.8612 16.8612C16.9551 16.7673 17.0294 16.6557 17.0796 16.5328C17.1299 16.4099 17.1552 16.2782 17.1541 16.1454C17.1529 16.0126 17.1253 15.8814 17.0729 15.7594C17.0205 15.6374 16.9443 15.5271 16.8488 15.4348L13.3138 11.8988V11.8998Z"
                fill="white"
              />
            </svg>
          </Button>
          <img src="/images/matchit-heart.svg" alt="" />
          <span> {this.props.translations.winningCirclePayout} </span>
        </ModalHeader>
        <ModalBody className="winners winners__winnings">
          <Carousel>
            <div>
              {/* <p> The Winners Circle winnings are paid out to 5 lifelines in the following methods: </p>
                <p>1 - 50% to a 50/50 Charity</p>
                <p>2 - A portion goes to your personal charity or cause.</p>
                <p>3 - A portion goes to you and the 5 people above you.</p>
                <p>4 - 15% from Winners Circle Bonus Pool - Qualification to be announced at live launch.</p> */}
              {/* <p>
                Remember, you're playing for a charity. Please make sure you
                pick the charity you are playing for.
              </p>
              <p>
                50% of your winnings automatically go to that charity. *Default
                charity in place.
              </p>
              <p>
                Make sure to add your personal charity or fundraiser, where up
                to 30% of your lifeline's winnings will be ongoing to the
                personal charity or fundraiser of your choice.
              </p> */}
              <p>
                {this.props.translations.everyTimeYou}
                <span>{this.props.translations.donateFifty}</span>{" "}
                {this.props.translations.pickedAt}
              </p>
              <p>
                {this.props.translations.otherFifty}{" "}
                <span>{this.props.translations.sharedFifty}</span>{" "}
                {this.props.translations.dividedFollows}
              </p>
              <p>
                {" "}
                <span>{this.props.translations.sixtyDivided}</span>{" "}
                {this.props.translations.betweenTwo}{" "}
                <span>{this.props.translations.lifelines}</span>
              </p>
              <p>
                <span>{this.props.translations.thirtyPaid}</span>{" "}
                {this.props.translations.friendsPersonalCharities}
              </p>
              <p>
                <span>{this.props.translations.tenStay}</span>{" "}
                {this.props.translations.winnersCircleBonusPool}
              </p>
            </div>
            <div>
              {/* <p>
                The balance of your winnings are broken down as illustrated in
                the Winners Circle Diagram.
              </p>
              <p>
                {" "}
                The more friends that you invite to be in your lifeline to play
                Matchit-It, the more everyone wins{" "}
              </p>
              <p>
                {" "}
                The more money you win, the more money that goes to a recognized
                charity and your personal charity. The more you invite, the more
                everyone wins!
              </p> */}
              <p className="winners_text">
                {this.props.translations.breakdownSixty}{" "}
              </p>
              <p>
                {this.props.translations.you}{" "}
                <span>{this.props.translations.receiveTwenty}</span>{" "}
                {this.props.translations.balanceTwenty}
              </p>
              <p>
                {this.props.translations.becomeTheir}
                <span>{this.props.translations.askLifeline}</span>{" "}
                {this.props.translations.balanceWinnings}
              </p>
              <p>
                {this.props.translations.inAddition}
                <span>{this.props.translations.lifelines}</span>,{" "}
                {this.props.translations.askSomebody}
                <span>{this.props.translations.lifelines}</span>{" "}
                {this.props.translations.sameIndividual}
                <span>{this.props.translations.receiveTwenty}</span>{" "}
                {this.props.translations.ofTheBalance}
              </p>
            </div>
          </Carousel>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal())
});

const mapStateToProps = state => ({
  translations: state.translationReducer
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WinnersCircleWinnings);
