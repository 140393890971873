import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fecthSingleGame, changeBackground } from "../../actions/actions";
import CantPlayTheGame from "../modal/CantPlayTheGame";

class DisplayAllGames extends PureComponent {
  state = {
    clickMe: false
  };

  clickMe = () => {
    this.setState({ clickMe: !this.state.clickMe });
  };
  test = (id, status, user_id) => {
    // change number of background index
    this.props.changeBackground(this.props.backgroundRandomNumber);
    this.setState({ oneTimeCallOnly: true });
    this.props.fecthSingleGameId(id, status, user_id);

    // Reset adsnumber everytime player clicks play
    if (!sessionStorage.getItem("adsNumber")) {
      sessionStorage.setItem("adsNumber", 0);
    } else {
      sessionStorage.removeItem("adsNumber");
      sessionStorage.setItem("adsNumber", 0);
    }
    // End Reset adsnumber everytime player clicks play
  };

  render() {
    const {
      id,
      name,
      status,
      boardSize,
      notification,
      all,
      user_id,
      allGameArray,
      numberPickingCycle,
      play,
      start_date,
      sunday
    } = this.props;
    return (
      <React.Fragment>
        <p className="gamelist__name">
          Game
          J-{(numberPickingCycle === 1 ? "D" : "W") + id}
          <span>{start_date.slice(0, 10)}</span>
          <span className="sunday"> {sunday && JSON.parse(sessionStorage.getItem("chooseForMe")).noGames }</span>
        </p>
        <div className="gamelist__button">
          {(() => {
            if (!all && allGameArray[0].id !== id) {
              return <button onClick={this.clickMe}> {this.props.play} </button>;
            } else if (all) {
              return (
                <div className="gamelist__notif">
                  {notification <= 2 && notification !== 0 ? (
                    <span className="gamelist__notifcount">{notification}</span>
                  ) : notification === 3 ? (
                    <span className="gamelist__notifcount">New</span>
                  ) : null}

                  <Link
                    type="button"
                    to={{ pathname: `game/${id}/${user_id}` }}
                    onClick={this.test.bind(this, { id, status, user_id })}
                  >
                    {this.props.translations.play}
                  </Link>
                </div>
              );
            }
          })()}
          {this.state.clickMe ? <CantPlayTheGame /> : null}
          {/* <span className="gamelist__board"> Board Size {boardSize} </span> */}
          <span className="gamelist__board">
            {numberPickingCycle === 1 ? this.props.translations.dailyGame : this.props.translations.weeklyGame}{" "}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allGameArray: state.allGame.games.data.games,
  backgroundRandomNumber: state.backgroundReducer.backgroundRandomNumber,
  translations: state.translationReducer
});
const mapDispatchToProps = dispatch => ({
  fecthSingleGameId: payload => dispatch(fecthSingleGame(payload)),
  changeBackground: number => dispatch(changeBackground(number))
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayAllGames);
