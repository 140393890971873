import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from "../layout/Menu";

class NewGameNumbers extends Component {

    render() {
        return (
            <React.Fragment>
                <Link
                    to="/newgame"
                    className="nav__header"
                    onClick=""
                >
                    <i className="ion ion-ios-arrow-back"></i>
                    Back
                </Link>
                <div className="game-tiles newgame__game-tiles">
                    <h1> Please select your numbers </h1>  
                    
                    <div className="flex justify-content-center">
                        <div className="red pattern">
                            <span> <strong>1</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>2</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>3</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>4</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>5</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>6</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>7</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>8</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>9</strong> </span>
                        </div>
                    </div>

                    <div className="flex justify-content-center">
                        <div className="red pattern">
                            <span> <strong>10</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>11</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>12</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>13</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>14</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>15</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>16</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>17</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>18</strong> </span>
                        </div>
                    </div>

                    <div className="flex justify-content-center">
                        <div className="red pattern">
                            <span> <strong>19</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>20</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>21</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>22</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>23</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>24</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>25</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>26</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>27</strong> </span>
                        </div>
                    </div>

                    <div className="flex justify-content-center">
                        <div className="red pattern">
                            <span> <strong>28</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>29</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>30</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>31</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>32</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>33</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>34</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>35</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>36</strong> </span>
                        </div>
                    </div>

                    <div className="flex justify-content-center">
                        <div className="red pattern">
                            <span> <strong>37</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>38</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>39</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>40</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>41</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>42</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>43</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>44</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>45</strong> </span>
                        </div>
                    </div>

                    <div className="flex justify-content-center">
                        <div className="red pattern">
                            <span> <strong>46</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>47</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>48</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>49</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>50</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>51</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>52</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>53</strong> </span>
                        </div>
                        <div className="red pattern">
                            <span> <strong>54</strong> </span>
                        </div>
                    </div>

                    <div className="flex justify-content-center">
                    <div className="red pattern">
                        <span> <strong>55</strong> </span>
                    </div>
                    <div className="red pattern">
                        <span> <strong>56</strong> </span>
                    </div>
                    <div className="red pattern">
                        <span> <strong>57</strong> </span>
                    </div>
                    <div className="red pattern">
                        <span> <strong>58</strong> </span>
                    </div>
                    <div className="red pattern">
                        <span> <strong>59</strong> </span>
                    </div>
                    <div className="red pattern">
                        <span> <strong>60</strong> </span>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                
                    <button class="modal-submit ">Save Selection</button>

                </div>

            <Menu />
            </React.Fragment>
        )
    }
}

export default NewGameNumbers