import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getMoney } from "../../../actions/actions";
import { UncontrolledCollapse, Button } from "reactstrap";

class Transaction extends PureComponent {
  state = {
    arrowState: false
  };
  componentDidMount() {
    if (sessionStorage.getItem("authFullInfo")) {
      const mobile_id = JSON.parse(sessionStorage.getItem("authFullInfo")).data
        .mobile_id;
      this.props.getMoney(mobile_id);
    }
  }

  handleArrowClick = async () => {
    console.log("xxx");
    this.setState({ arrowState: !this.state.arrowState });
  };

  render() {
    const { email, mobileClientID, timezone, token } = JSON.parse(
      sessionStorage.getItem("auth")
    );
    const { getMoneyBalance } = this.props;
    return (
      <div className="transaction__page">
        <div className="nav__header">
          <i className="ion ion-ios-arrow-back"></i>
          <Link
            to={`/?email=${email}&mobileClientID=${mobileClientID}&timezone=${timezone}&token=${token}`}
            className="text-light"
          >
            Back
          </Link>
        </div>

        <div className="transaction__innerpage">
          <div className="box__inner">
            <h1 className="transaction__title">
              {this.props.translations.winnings}
            </h1>
            <div className="transaction__amount">
              <p>
                {" "}
                $
                {getMoneyBalance.balance == ""
                  ? "0.00"
                  : parseFloat(getMoneyBalance.balance).toFixed(2)}{" "}
              </p>
              <span> {this.props.translations.currentWinnings} </span>
            </div>
            <ul className="settings">
              <li id="toggler1">
                <p>{this.props.translations.requestPayout}</p>
                <i
                  className={
                    this.state.arrowState
                      ? "ion ion-ios-arrow-down"
                      : "ion ion-ios-arrow-down"
                  }
                ></i>
              </li>
              <UncontrolledCollapse
                toggler="#toggler1"
                className="history__details history__details__collapse"
              >
                <Link
                  to={{
                    pathname: "/transaction-payout",
                    state: {
                      balance: parseFloat(getMoneyBalance.balance).toFixed(2)
                    }
                  }}
                >
                  {this.props.translations.paypalVerification}
                </Link>
                <Link to="/transaction" className="text-muted" disabled>
                  {this.props.translations.chequeCheckout}
                </Link>
              </UncontrolledCollapse>
              <li>
                <Link to="/transaction-history">
                  <p>{this.props.translations.transactionHistory}</p>
                  <i className="ion ion-ios-arrow-forward"></i>
                </Link>
              </li>
            </ul>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getMoneyBalance: state.userMoneyDetails,
  translations: state.translationReducer
});
const mapDispatchToProps = dispatch => ({
  getMoney: payload => dispatch(getMoney(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
