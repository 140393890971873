import React, { PureComponent } from "react";
import axios from "axios";
import authHOC from "../HOC/AuthHOC";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class SaveYourEmail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: undefined,
      hasError: false,
      isProcessing: false,
      isDone: false,
      customMsg: "",
      userData: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUpdateEmail = (id, email) => {
    axios
      .post(`https://matchit.apptechgateway.com/api/user/get-user-data`, {
        token: "02DFK2fj294jgf9234Oe23395oP",
        user_id: id,
        email: email,
      })
      .then((res) => {
        this.setState({
          userData: res.data.data,
        });

        res.data.data.avazoo_email === null &&
          this.setState({
            needToUpdateEmail: !this.state.needToUpdateEmail,
          });
      })
      .catch((err) => console.log(err));
  };

  handleSubmit = async (evt) => {
    evt.preventDefault();

    if (this.state.inputValue === undefined) {
      this.setState({ customMsg: "Please type your email" });
    } else {
      const id = this.state.userData.id;
      const email = this.state.inputValue;
      this.setState({ isProcessing: true });
      // this.props.updateEmail({ id, email });
      axios
        .post(
          `https://matchit.apptechgateway.com/api/user/update-avazoo-email`,
          {
            user_id: `${id}`,
            avazoo_email: `${email}`,
          }
        )
        .then((res) => {
          this.setState({ isProcessing: false });
          this.setState({ isDone: true });
          if (res.data.status === "success") {
            this.setState({ customMsg: "Email successfully updated!" });
          } else {
            this.setState({
              customMsg:
                "There seems to be a problem with your email. Please make sure it's not yet taken.",
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  updateInputValue = (evt) => {
    const val = evt.target.value;

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(val)) {
      this.setState({
        inputValue: val,
      });
      this.setState({ hasError: false });
    } else {
      this.setState({ hasError: true });
      this.setState({ customMsg: "Invalid Email Address" });
    }
  };

  componentDidMount = async () => {
    if (sessionStorage.getItem("authFullInfo")) {
      const userData = JSON.parse(sessionStorage.getItem("authFullInfo")).data;
      this.handleUpdateEmail(userData.id, userData.email);
    }
  };

  render() {
    const { email, mobileClientID, timezone, token } = JSON.parse(
      sessionStorage.getItem("auth")
    );

    return (
      <div className="transaction__page">
        <Link
          to={`/?email=${email}&mobileClientID=${mobileClientID}&timezone=${timezone}&token=${token}`}
          className="nav__header"
        >
          <i className="ion ion-ios-arrow-back"></i>
          Back
        </Link>

        <div className="transaction__innerpage">
          <div className="box__inner">
            <form onSubmit={this.handleSubmit} className="email__info">
              <h3 className="email_title">
                Please Update Your Email to Play in Match-It!
              </h3>
              <small>
                If you are not an Avazoo Raffle Ticket holder, please re-enter
                the email you used to sign up for the AppTech Gateway program.
              </small>
              <small>
                If you are an Avazoo Raffle Ticket holder and would like to earn
                Free Entries each time you claim a number playing Match-It!,
                please enter the email you used to register for your Avazoo
                Raffle Ticket.
              </small>
              {this.state.hasError && (
                <p className="email__invalid"> {this.state.customMsg} </p>
              )}
              <input
                type="text"
                placeholder="Email here"
                className="email__input"
                value={this.state.inputValue}
                onChange={(evt) => this.updateInputValue(evt)}
              />
              <button
                className={`modal-submit ${
                  (this.state.isProcessing || this.state.hasError) &&
                  "email__processing"
                }`}
              >
                {" "}
                {this.state.isProcessing ? "Saving..." : "Save Email"}{" "}
              </button>

              <small className="email_center">
                To learn more about Avazoo's Billion Dollar Raffle and how to
                earn Free Entries for playing Match-It!, please click the link
                below.
              </small>
              <small className="email_center">
                <a
                  href="https://avazoo.com/user-lp/?bid=42&uid=2"
                  target="_blank"
                >
                  {" "}
                  Avazoo’s Billion Dollar Raffle
                </a>
              </small>
              <br />

              {this.state.isDone && (
                <div className="email__success">
                  <span> {this.state.customMsg} </span>
                  <br />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.translationReducer,
});

// export default  authHOC(connect(mapStateToProps, null)(SaveYourEmail));

export default authHOC(
  connect(mapStateToProps, null)(withRouter(SaveYourEmail))
);
