import { IDLE_TIME, DONE_POPUP, FETCH_USER_INFO, GET_USERINFO_FROM_URL, FETCH_EXISTING_USER_INFO } from "../actions/types";

const initialState = {
  info: {},
  donePopUp: {},
  userIdle: [{ idle: 240 }],
  urlOrigin: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_INFO: {
      const { payload } = action;
      return {
        ...state,
        info: payload
      };
    }
    case FETCH_EXISTING_USER_INFO: {
      const {payload} = action;
      return {
        ...state,
        info: payload
      }
    }
    case IDLE_TIME: {
      const { interValToAction } = action;

      const newIdleTime = [...state.userIdle].map(infoIdle => {
        if (interValToAction) {
          return { ...infoIdle, idle: interValToAction };
        }
        return infoIdle;
      });
      return { ...state, userIdle: newIdleTime };
    }
    case DONE_POPUP: {
      const { playsNow } = action;

      return {
        ...state,
        donePopUp: playsNow
      };
    }
    case GET_USERINFO_FROM_URL: {
      const { payload } = action;
      return {
        ...state,
        urlOrigin: payload
      }
    }

    default: {
      return state;
    }
  }
}
