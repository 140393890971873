import { CHANGE_BACKGROUND } from '../actions/types';

const initial_state = {
  backgroundRandomNumber: Math.floor(Math.random() * 22)
}

export default function (state = initial_state, action) {
  switch (action.type) {
    case CHANGE_BACKGROUND:
      return {
        ...state,
        backgroundRandomNumber: state.backgroundRandomNumber === 22 ? 0 : action.number + 1
      }
    default:
      return {
        ...state
      }
  }
}