import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../layout/Menu";

class NewGame extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <Link
                    to=""
                    className="nav__header"
                    onClick=""
                >
                    <i className="ion ion-ios-arrow-back"></i>
                    Back
                </Link>
                <Menu />
                <div className='gameboard newgame__gameboard'>
                    <h3>Match-It</h3>
                    <div className="newgame__wrapper">
                        <div className="newgame__house" data-num="?">
                            <svg class="newgame__housechimney" width="54" height="58" viewBox="0 0 54 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 54V55.6464L8.96099 54.8874L47.461 34.8874L48 34.6074V34V17.5352L52.0547 14.8321L52.5 14.5352V14V8.5V7.66921L51.6833 7.51694L22.1833 2.01694L21.8256 1.95025L21.5087 2.12902L2.00868 13.129L1.5 13.416V14V21.5V22.5H2.5H7.5V54Z" fill-opacity="0.4" stroke="#ABC6CD" stroke-width="2"/>
                            </svg>
                            <div className="newgame__housenumbers">
                                <button> 12 </button>
                                <button> 12 </button>
                                <button> 12 </button>
                                <button> 12 </button>
                                <button> 12 </button>
                            </div> 
                            <img src='/images/house.png' alt='' />
                        </div>
                        <div className="newgame__pole" data-num="?">
                            <svg width="55" height="221" viewBox="0 0 55 221" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 10.9911L10.9707 18.519C10.9707 18.519 11.5316 18.5609 12.4919 18.6255L18.2129 42.1374H17.2598V43.4659H19.4728C19.6792 43.9819 19.9271 44.588 20.1994 45.237H18.5879L19.4735 47.0083H20.9593C22.1213 49.654 23.4246 52.3219 23.9008 52.3219H24.7864C24.7864 52.3219 23.3195 49.9526 22.5724 48.3365C22.3872 47.936 22.1986 47.4787 22.0169 47.0083H30.7707C30.7121 47.4805 30.6376 47.9383 30.5427 48.3365C30.2193 49.695 29.3884 51.4993 28.9904 52.322H24.3432C24.0986 52.322 23.9004 52.5203 23.9004 52.7648C23.9004 53.0094 24.0986 53.2076 24.3432 53.2076H31.4283C31.6728 53.2076 31.8711 53.0094 31.8711 52.7648L31.8708 52.7502L32.0593 52.7744C32.0593 52.7744 33.2432 50.6583 33.8049 49.1697C34.066 48.4777 34.4925 47.7041 34.9228 47.0083H35.8562L36.299 46.1227L35.5679 46.0182C36.0155 45.3626 36.3598 44.9158 36.3598 44.9158L35.6169 44.8203L43.4919 18.6213C48.2236 18.2276 52 17.8155 52 17.8155L44.5 8.8154C44.5 8.8154 37.0067 6.6912 32.5 4.81517C30.3431 3.9173 27.3933 2.6391 26.1233 2.08671C25.746 1.9226 25.3149 2.00207 24.9761 2.2356C20.9825 4.98863 3 10.9911 3 10.9911ZM32.2837 43.8134C32.2496 44.1798 32.1857 44.8026 32.0879 45.5211L34.657 45.8881C34.6777 45.8562 34.6982 45.8248 34.7185 45.7938L32.2837 43.8134ZM33.9802 47.0083H31.8511C31.7349 47.6327 31.5947 48.2511 31.4284 48.7794C31.0529 49.9718 30.2742 51.561 29.8866 52.322H31.4283C31.4912 52.322 31.551 52.3351 31.6052 52.3588C31.973 51.6511 32.8208 49.9618 33.228 48.6701C33.3952 48.1397 33.6753 47.5571 33.9802 47.0083ZM21.3768 45.237C21.0395 44.2487 20.8067 43.4839 20.8012 43.4659H30.9856C30.9855 43.4839 30.9822 44.2499 30.9253 45.237H21.3768Z" fill-opacity="0.4"/>
                                <path d="M23.1289 53.2078C22.5766 53.2078 22.1289 53.6555 22.1289 54.2078V54.8646C22.1289 55.4169 22.5766 55.8646 23.1289 55.8646H25.6719V60.1987C24.6324 60.8859 23.9004 61.9902 23.9004 63.3923C23.9004 64.7944 24.6324 67.0631 25.6719 68.744V218.821H30.1V69.922C31.4122 68.4209 32.3139 65.5737 32.3139 63.8351C32.3139 62.0966 31.4122 60.592 30.1 59.8674V55.8646H32.6421C33.1944 55.8646 33.6421 55.4169 33.6421 54.8646V54.2078C33.6421 53.6555 33.1944 53.2078 32.6421 53.2078H23.1289Z" fill-opacity="0.4"/>
                            </svg>
                            <img src='/images/pole.png' alt='' />
                        </div>
                        <div className="newgame__person" data-num="?">
                            <svg width="68" height="175" viewBox="0 0 68 175" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M51.9999 168C50.5715 171.571 45.9999 173.5 43.9999 173C41.9999 172.5 41.901 171.2 41.4999 169.5C40.6065 165.713 43.6666 162.333 43.9999 159.5C44.3333 156.667 44.9999 150.6 44.9999 149C44.9999 147.4 41.4999 119 39.4999 109L38.4999 117C38.4999 117 38.8561 137.595 37.4999 151C37.183 154.132 36.4999 159 36.4999 159H30.9999C30.9999 159 27.9999 163.5 22.9999 164C17.9999 164.5 11.9999 164 13.9999 162C15.9999 160 24.9999 153.5 24.9999 153.5L26.9999 149C26.9999 149 25.9999 140.5 25.9999 132C25.9999 123.5 24.9999 112.5 24.9999 112.5L22.9999 85.9999L22.4999 83.9999C22.4999 83.9999 23.4999 72.4999 23.4999 71.4999C23.4999 70.4999 25.4999 58.4999 25.4999 58.4999C25.4999 58.4999 17.4104 65.7275 14.4999 64.9999C12.5 64.4999 11 59.9999 7.99994 57.4999C6.61696 56.3474 7 52.9999 7 52.9999C7 52.9999 2.00034 50.2225 2.49994 49.4999C3.72139 47.7331 7.99994 49.4999 7.99994 49.4999L16 52.9999L18.9999 50.9999C20.6666 44.1665 22.2999 31.9999 23.4999 29.9999C24.6999 27.9999 29.3333 26.1665 30.4999 26.4999L27 21.4999L25.4999 16.4999L23.9999 12.9999C23.9999 12.9999 22.7597 9.74895 23.9999 7.50008C25.3402 5.06992 27.5 2.79672 30.4999 1.99972C34.2077 1.01469 37.1906 1.5453 40 4.50008C43.141 7.8036 42.4999 16.4999 42.4999 16.4999C43.3333 17.4999 45.1999 19.7999 45.9999 20.9999C46.7999 22.1999 53.9999 25.0713 58.4999 26.9999C62.9999 28.9285 62.4999 34.4999 62.4999 34.4999C63.6666 42.1665 65.9999 57.9999 65.9999 59.9999C65.9999 61.9999 61.4999 79.4999 57.9999 85.9999L55.9999 85.4999V87.4999V89.9999L54.4999 105.5V152.5L51.9999 159C51.9999 161.667 52.9999 165.5 51.9999 168Z" fill-opacity="0.4"/>
                            </svg>
                            <img src='/images/person.png' alt='' />
                        </div>
                        <div className="newgame__motorcycle" data-num="?">
                            <svg width="79" height="106" viewBox="0 0 79 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M61 25C61 19.8 59.5 13 57 10C54.5 7 48.5 2 48.5 2L38.5 4.5L37 10.5L28.5 9.5V6.5L23 7C22 8.5 20.8 11.7 14 12.5C7.2 13.3 3.16667 18.1667 2 20.5V81.5L20 83.5L35 82.5C35 82.5 38 105.432 59 104C80 102.568 82.5 64.5 69 50.5C67.8 48.1 59.5 44.5 55.5 43V40C57.3333 37.1667 61 30.2 61 25Z" fill-opacity="0.4"/>
                            </svg>
                            <img src='/images/motorcycle.png' alt='' />
                        </div>
                        <div className="newgame__car" data-num="?">
                            <svg width="209" height="124" viewBox="0 0 209 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M48.5 98.42L15.5 91.92L2 31.92C2 31.92 16 21.92 28.5 13.92C41 5.92004 49 3.92004 49 3.92004C49 3.92004 65 0.919929 97.5 2.42004C130 3.92016 145 8.42004 145 8.42004C154.177 13.1063 177.5 33.42 186 34.92C194.5 36.42 201 40.42 201 40.42C201 40.42 204.27 46.2335 205.5 49.42C207.271 54.0068 207 60.42 207 60.42V73.92L205.5 85.92L207 89.92L202.5 100.42H197.5C197.5 111.42 193.5 120.42 185 121.92C181.667 121.92 174.439 121.222 171.5 120.42C166 118.92 166 111.92 163.5 108.92C161.5 106.52 148.333 105.92 142 105.92C133.333 105.92 115.3 105.62 112.5 104.42C109 102.92 102.5 105.92 98.5 105.92H82C80.5 110.253 80 117.045 75.5 120.42C73.5 121.92 61.097 123.057 56.5 118.92C51.5 114.42 49.1667 104.753 48.5 98.42Z" fill-opacity="0.4"/>
                            </svg>
                            <img src='/images/car.png' alt='' />
                        </div>
                    </div>
                    <div>
                        <div className="game-start-time">
                            <div class="container">
                                <div class="game-time-detail">
                                    <div class="game-time-detail-container flex justify-content-around">
                                        <div class="text-center number-of-hour">
                                            <p>12:01AM</p>
                                            <div class="number-container">
                                                <div class="midnight bg-style-time"></div>
                                                <h2></h2>
                                                <p class="chooseNum">Click 4 Number</p>
                                            </div>
                                        </div>
                                        <div class="text-center number-of-hour taken">
                                            <p>2:01AM</p>
                                            <div class="number-container">
                                                <div class="morning bg-style-time"></div>
                                                <h2></h2>
                                                <p class="chooseNum pt-2">-</p>
                                            </div>
                                        </div>
                                        <div class="text-center number-of-hour taken">
                                            <p>4:01PM</p>
                                            <div class="number-container">
                                                <div class="afternoon bg-style-time"></div>
                                                <h2></h2>
                                                <p class="chooseNum pt-2">-</p>
                                            </div>
                                        </div>
                                        <div class="text-center number-of-hour taken">
                                            <p>6:01PM</p>
                                            <div class="number-container">
                                                <div class="night bg-style-time"></div>
                                                <h2></h2>
                                                <p class="chooseNum pt-2">-</p>
                                            </div>
                                        </div>
                                        <div class="text-center number-of-hour taken">
                                            <p>8:01PM</p>
                                            <div class="number-container">
                                                <div class="night bg-style-time"></div>
                                                <h2></h2>
                                                <p class="chooseNum pt-2">-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="game-time">
                                    <div class="panel__main"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-text">
                        <h4>
                        {" "}
                        {
                            JSON.parse(sessionStorage.getItem("gameTranslations"))
                            .iPlay
                        }{" "}
                        </h4>
                        <h4>
                        {" "}
                        {
                            JSON.parse(sessionStorage.getItem("gameTranslations"))
                            .playForOthers
                        }{" "}
                        </h4>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => ({
    translations: state.translationReducer
});
  
  
export default connect(mapStateToProps, null)(NewGame);
  