import React, { PureComponent } from "react";
import { UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { transactionHistory } from "../../../actions/actions";
import Moment from "react-moment";

class TransactionHistory extends PureComponent {
  componentDidMount() {
    const mobile_id = JSON.parse(sessionStorage.getItem("authFullInfo")).data
      .mobile_id;
    this.props.transactionHistory({ mobile_id });
  }

  render() {
    const { getTransactionHistory } = this.props;
    return (
      <div className="transaction__page">
        <div className="nav__header">
          <Link to="/transaction" className="nav__header">
            <i className="ion ion-ios-arrow-back"></i>
            {this.props.translations.back}
          </Link>
        </div>

        <div className="transaction__innerpage">
          <div className="box__inner">
            <h1 className="transaction__title"> {this.props.translations.transactionHistory} </h1>
            <div className="transactionHistory__breakdown">
              <div className="transaction__icons">
                <img src="/images/personal-charity.svg" alt="" />
                <div>
                  <p>
                    ${getTransactionHistory.personalCharityTotal
                      ? parseFloat(
                        getTransactionHistory.personalCharityTotal
                      ).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.pCharity} </h4>
                  <small> {this.props.translations.amountGenerated} </small>
                </div>
              </div>

              <div className="transaction__icons">
                <img src="/images/hand.svg" alt="" />
                <div>
                  <p>
                    ${getTransactionHistory.fiftyFiftyTotal
                      ? parseFloat(getTransactionHistory.fiftyFiftyTotal).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.nationalCharity} </h4>
                  <small> {this.props.translations.chosenFiftyFifty} </small>
                </div>
              </div>

              <div className="transaction__icons">
                <img src="/images/personal-winnings.svg" alt="" />
                <div>
                  <p>
                    ${getTransactionHistory.ownWinning
                      ? parseFloat(getTransactionHistory.ownWinning).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.totalPersonalWinnings} </h4>
                  <small> {this.props.translations.personalyWon}  </small>
                </div>
              </div>
              <div className="transaction__icons">
                <img src="/images/winnings.svg" alt="" />
                <div>
                  <p>
                    ${getTransactionHistory.oneDayWinningTotal
                      ? parseFloat(getTransactionHistory.oneDayWinningTotal).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.dailyWinnings} </h4>
                  <small> {this.props.translations.generatedByLifeline} </small>
                </div>
              </div>
              <div className="transaction__icons">
                <img src="/images/weekly-winnings.svg" alt="" />
                <div>
                  <p>
                    ${getTransactionHistory.weekWinningTotal
                      ? parseFloat(getTransactionHistory.weekWinningTotal).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.weeklyWinnings} </h4>
                  <small> {this.props.translations.generatedWeekly} </small>
                </div>
              </div>

              <div className="transaction__icons">
                <img src="/images/total-winnings.svg" alt="" />
                <div>
                  <p>
                    ${getTransactionHistory && getTransactionHistory.walletTransactionsGroupList
                      ? parseFloat(
                        getTransactionHistory.walletTransactionsGroupList.reduce((currentTotal, item) => { return item.totalWinning + currentTotal }, 0)
                      ).toFixed(2)
                      : "0.00"}
                  </p>
                  <h4> {this.props.translations.totalWinnings} </h4>
                  <small> {this.props.translations.thisTotalWinnings} </small>
                </div>
              </div>

            </div>

            {/* <small className="scrollMore"> Scroll below to view history </small> */}

            <ul className="history">
              {(() => {
                if (
                  getTransactionHistory &&
                  getTransactionHistory != "" &&
                  getTransactionHistory.walletTransactionsGroupList != ""
                ) {
                  return getTransactionHistory.walletTransactionsGroupList.map(
                    (game, idx) => (
                      <li key={idx}>
                        <p id={`toggler${idx + 1}`}>
                          <em>
                            <span>
                              {" "}
                              <Moment
                                format="D MMM YYYY"
                                withTitle
                                aria-hidden={true}
                              >
                                {game.gameDate}
                              </Moment>
                            </span>
                            Game ID: {game.gameId}
                          </em>
                          <i className="ion ion-ios-arrow-down"></i>
                        </p>
                        <UncontrolledCollapse
                          toggler={`#toggler${idx + 1}`}
                          className="history__details"
                        >
                          {/* <small> Date and Time </small> */}
                          {/* <span>
                            {" "}
                            <Moment format="D MMM YYYY" withTitle aria-hidden={true}>
                              {game.gameDate}
                            </Moment>{" "}
                          </span> */}
                          {/* <small> Commission From</small> */}

                          {(() => {
                            return game.WalletTransactionList.map(
                              (transaction, key) => (
                                <span key={key}>
                                  <small>
                                    {transaction.description
                                      ? transaction.description.replace(
                                        "Commission",
                                        "Winnings"
                                      )
                                      : transaction.description}
                                  </small>{" "}
                                  {"   "}
                                  <span>
                                    {parseFloat(transaction.amount) < 0
                                      ? "-$" +
                                      parseFloat(
                                        transaction.amount
                                          .toString()
                                          .substr(1)
                                      ).toFixed(2)
                                      : "$" +
                                      parseFloat(transaction.amount).toFixed(
                                        2
                                      )}
                                  </span>
                                </span>
                              )
                            );
                          })()}
                        </UncontrolledCollapse>
                      </li>
                    )
                  );
                } else if (
                  getTransactionHistory &&
                  getTransactionHistory.walletTransactionsGroupList == ""
                ) {
                  return (
                    <p className="text-center mt-3 mb-5 muted">
                      {" "}
                      No Data Available!{" "}
                    </p>
                  );
                }
              })()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getTransactionHistory: state.transactionHistory.transactionHistory,
  translations: state.translationReducer
});

const mapDispatchToProps = dispatch => ({
  transactionHistory: payload => dispatch(transactionHistory(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
