import React, { PureComponent } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  baseURL,
  baseURL_staging,
  updateEmail,
  closeModal,
} from "../../../actions/actions";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "../../../static/gif/load.gif";

class UpdateEmail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalState: true.valueOf,
      userId: this.props.userInfo.id,
      inputValue: undefined,
      hasError: false,
      isProcessing: false,
      isDone: false,
      customMsg: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // componentDidUpdate() {
  //   if (this.props.getUpdateEmailStatus) {
  //     const { status } = this.props.getUpdateEmailStatus;
  //     if (status === 200) {
  //       this.setState({ isProcessing: false });
  //       this.setState({ isDone: true });
  //       this.setState({ customMsg: "Email successfully updated!" });
  //     } else if (status === "error") {
  //       this.setState({
  //         customMsg:
  //           "There seems to be a problem with your email. Please make sure it's not yet taken.",
  //       });
  //     }
  //   }
  // }

  onClose = () => {
    this.props.closeModal();
    this.setState({ modalState: !this.state.modalState });
    window.location.reload(false);
  };

  handleSubmit = async (evt) => {
    evt.preventDefault();

    if (this.state.inputValue === undefined) {
      this.setState({ customMsg: "Please type your email" });
    } else {
      const id = this.state.userId;
      const email = this.state.inputValue;
      this.setState({ isProcessing: true });
      // this.props.updateEmail({ id, email });
      axios
        .post(
          `https://matchit.apptechgateway.com/api/user/update-avazoo-email`,
          {
            user_id: `${id}`,
            avazoo_email: `${email}`,
          }
        )
        .then((res) => {
          this.setState({ isProcessing: false });
          this.setState({ isDone: true });

          if (res.data.status === "success") {
            this.setState({ customMsg: "Email successfully updated!" });
            sessionStorage.setItem("hasUpdateAvazooEmail", true);
          } else {
            this.setState({
              customMsg:
                "There seems to be a problem with your email. Please make sure it's not yet taken.",
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  updateInputValue = (evt) => {
    const val = evt.target.value;

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(val)) {
      this.setState({
        inputValue: val,
      });
      this.setState({ hasError: false });
    } else {
      this.setState({ hasError: true });
      this.setState({ customMsg: "Invalid Email Address" });
    }
  };

  render() {
    const { modalState } = this.state;

    return (
      <Modal
        isOpen={modalState}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="language__modal"
      >
        <ModalHeader>Please Update Your Email to Play in Match-It!</ModalHeader>
        <ModalBody>
          <form onSubmit={this.handleSubmit} className="email__info">
            <small>
              If you are not an Avazoo Raffle Ticket holder, please re-enter the
              email you used to sign up for the AppTech Gateway program.
            </small>
            <small>
              If you are an Avazoo Raffle Ticket holder and would like to earn
              Free Entries each time you claim a number playing Match-It!,
              please enter the email you used to register for your Avazoo Raffle
              Ticket.
            </small>
            {this.state.hasError && (
              <p className="email__invalid"> {this.state.customMsg} </p>
            )}
            <input
              type="text"
              placeholder="Email here"
              className="email__input"
              value={this.state.inputValue}
              onChange={(evt) => this.updateInputValue(evt)}
            />
            <button
              className={`modal-submit ${
                (this.state.isProcessing || this.state.hasError) &&
                "email__processing"
              }`}
            >
              {" "}
              {this.state.isProcessing ? "Saving..." : "Save Email"}{" "}
            </button>

            <small className="email_center">
              To learn more about Avazoo's Billion Dollar Raffle and how to earn
              Free Entries for playing Match-It!, please click the link below.
            </small>
            <small className="email_center">
              <a
                href="https://avazoo.com/user-lp/?bid=42&uid=2"
                target="_blank"
              >
                {" "}
                Avazoo’s Billion Dollar Raffle
              </a>
            </small>
            <br />

            {this.state.isDone && (
              <div className="email__success">
                <span> {this.state.customMsg} </span>
                <button onClick={this.onClose}> Close </button>
              </div>
            )}
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  getUpdateEmailStatus: state.userMoneyDetails.updateEmailStatus,
});

const mapDispatchToProps = (dispatch) => ({
  updateEmail: (payload) => dispatch(updateEmail(payload)),
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail);
