import React, {PureComponent} from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import authHOC from '../HOC/AuthHOC';

class MatchItYoutube extends PureComponent{
  componentDidMount() {
    this.props.HOC_SecuredAuthMethod();
  }

  render() {
    const { email, mobileClientID, timezone, token } = JSON.parse(
      sessionStorage.getItem("auth")
    );
    return (
      <React.Fragment>
  
        <div className="nav__header">
          <Link to={`/?email=${email}&mobileClientID=${mobileClientID}&timezone=${timezone}&token=${token}`} className="nav__header">
            <i className="ion ion-ios-arrow-back"></i>
            Back
          </Link>
        </div>
  
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            playsInline
            url="https://www.youtube.com/embed/fmMvC8egs0A?autoplay=1"
            width="100%"
            height="100%"
            playing
            controls
          />
  
        </div>
      </React.Fragment>
    );
  }

};

export default authHOC(MatchItYoutube);
