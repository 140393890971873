import React, { PureComponent } from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

class CantPlayTheGame extends PureComponent {
  state = { modalState: true };

  onClose = () => {
    this.setState({ modalState: !this.state.modalState });
  };

  render() {
    const link = 'https://apptech.page.link/?link=https://www.apptechgateway.com/?action=matchitshare'
    return (
      <Modal
        isOpen={this.state.modalState}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>Need 3 Intivtes To Play The Game!</ModalHeader>
        <ModalFooter>
          <Button variant="secondary" onClick={this.onClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={() => window.open(`${link}`, "_blank")}>
            Send Invites
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CantPlayTheGame;
