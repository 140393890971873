import {
  TICK_TIME,
  IDLE_TIME,
  DONE_POPUP,
  FETCH_USER_INFO,
  SEND_LOCATION,
  DISPLAY_ALL_GAME,
  GET_SINGLE_GAME_DATA,
  CHOOSE_FOR_ME,
  CHOOSE_MYSELF,
  PICKED_NUMBERS,
  REMOVE_STATE_CHOOSE,
  GET_SINGLE_GAME_DATA_CHOOSE_MYSELF,
  GET_USERINFO_FROM_URL,
  SET_FALSE_THE_LOADING,
  FETCH_EXISTING_USER_INFO,
  CLOSE_MODAL,
  SET_MODAL,
  GET_MONEY,
  SET_FIRST_CLICK,
  SET_FIRST_CLICK_STATUS,
  SET_FIRST_CLICK_ADS_MATCH_STATUS,
  SET_FIRST_CLICK_DELAY,
  GET_TRANSACTION_HISTORY,
  CHECK_EMAIL,
  CHECK_PENDING,
  POST_EMAIL,
  UPDATE_EMAIL,
  PAYOUT,
  GET_CHOOSE_FOR_ME_PATTERN,
  CHOOSE_YOUR_GAME_MODAL,
  CHOOSE_YOUR_GAME_MODAL_TRUE,
  CLEAR_CHOOSE_FOR_ME_PATTERN,
  DONE_CHOOSE_PATTERN,
  CLOSE_CHOOSE_PATTERN,
  CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND,
  CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND_FALSE,
  CLEAR_CHOOSE_MYSELF_GAME_ID,
  CLEAR_WINNERS_DATA_FROM_CHOOSEFORME_STATE,
  CHANGE_BACKGROUND,
  GET_LIFELINE_STATS,
  TRANSLATION,
} from "./types";

import axios from "axios";

export const baseURL = "https://matchit.apptechgateway.com";
export const baseURL_staging = "https://matchit.apptechgateway.com";
// const baseURL = 'https://matchit-staging.apptechgateway.com';
// const baseURL_staging = 'https://matchit-staging.apptechgateway.com';

const moneyToken =
  "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJBcHBUZWNoQVBJR2F0ZXdheSIsInVzZXJJZCI6IjEyMzQ1NjciLCJyb2xlIjoiYWRtaW4ifQ.i4YU9K8UZ2X1uAfXn2BMH8jUJ5zK_R4mjkJ2loSsy1_9Q7Wcmhitejl_93Kqw5QbZGNOhCZqHN6am5bUVC8ceA";
const proxyurl = "https://cors-anywhere.herokuapp.com/";
const origin = "https://apptech-matchit-v2.firebaseapp.com/";

export const transactionHistory = (payload) => async (dispatch) => {
  const res = await axios.get(
    `https://api.apptechgateway.com/mobile/user/wallet/transactions?mobileClientId=${payload.mobile_id}`,
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  dispatch({
    type: GET_TRANSACTION_HISTORY,
    payload: res.data,
    loading: true,
  });
};

export const lifelineStats = (payload) => async (dispatch) => {
  const res = await axios.get(
    `https://api.apptechgateway.com/mobile/lifeline/stats?mobileClientId=${payload.mobile_id}`,
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  dispatch({
    type: GET_LIFELINE_STATS,
    payload: res.data,
    loading: true,
  });
};

export const getMoney = (payload) => async (dispatch) => {
  const res = await axios.get(
    `https://api.apptechgateway.com/mobile/client/wallet/balance/user?mobileClientId=${payload}`,
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  dispatch({
    type: GET_MONEY,
    payload: res.data,
  });
};
export const payout = (payload) => async (dispatch) => {
  const { total, mobile_id } = payload;
  await axios.post(
    "https://api.apptechgateway.com/user/payout",
    {
      amount: `${total}`,
      mobileClientId: `${mobile_id}`,
      charityId: 0,
      charity: false,
    },
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  dispatch({
    type: PAYOUT,
  });
};

export const checkEmail = (payload) => async (dispatch) => {
  const res = await axios.get(
    `https://api.apptechgateway.com/payout/method/get?mobileClientId=${payload.mobile_id}`,
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  dispatch({
    type: CHECK_EMAIL,
    payload: res.data,
  });
};
export const postEmail = (payload) => async (dispatch) => {
  const { email, mobile_id } = payload;
  const res = await axios.post(
    "https://api.apptechgateway.com/payout/method",
    {
      mobileClientId: `${mobile_id}`,
      recipientType: "EMAIL",
      receiver: `${email}`,
      charityId: 0,
      charity: false,
    },
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  dispatch({
    type: POST_EMAIL,
    payload: res,
    postedEmail: email,
  });
};

export const updateEmail = (payload) => async (dispatch) => {
  const { id, email } = payload;
  const res = await axios.post(
    `https://matchit.apptechgateway.com/api/user/update-avazoo-email`,
    {
      user_id: `${id}`,
      avazoo_email: `${email}`,
    },
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  // .then((res) => {
  //   console.log(res);
  //   console.log(res.data);
  // });
  dispatch({
    type: UPDATE_EMAIL,
    payload: res,
  });
};

export const checkPending = (payload) => async (dispatch) => {
  const res = await axios.get(
    `https://api.apptechgateway.com/check/pending/payout?mobileClientId=${payload.mobile_id}`,
    { headers: { Authorization: `Bearer ${moneyToken}` } }
  );
  dispatch({
    type: CHECK_PENDING,
    payload: res.data,
  });
};

export const setFirstClick = (payload) => {
  return {
    type: SET_FIRST_CLICK,
    payload: payload,
  };
};
export const setFirstClickStatus = () => {
  return {
    type: SET_FIRST_CLICK_STATUS,
  };
};
export const setFirstClickAdsMatchStatus = () => {
  return {
    type: SET_FIRST_CLICK_ADS_MATCH_STATUS,
  };
};
export const setFirstClickDelay = (payload) => {
  return {
    type: SET_FIRST_CLICK_DELAY,
    payload: payload ? payload.setDelay : null,
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};
export const setModal = (payload) => {
  return {
    type: SET_MODAL,
    payload,
  };
};

export const getUserInfoFromUrl = (payload) => async (dispatch) => {
  const { queryValues } = payload;
  dispatch({
    type: GET_USERINFO_FROM_URL,
    payload: queryValues,
  });
};

export const sendUserLocationAction = (payload) => async (dispatch) => {
  const {
    mobileClientId,
    clientTimestamp,
    activityEntryTypeId,
    gameName,
    pickedNumber,
    weeklyGame,
    long,
    lat,
    win,
    winCombo,
    winPrize,
    ad,
  } = payload;

  console.log(win + " win?");
  console.log(winCombo + " combo");
  console.log(winPrize + " prize");
  console.log(ad + " ad");

  const res = await axios
    .post(
      "https://api.apptechgateway.com/mobile/location",
      {
        mobileClientId: `${mobileClientId}`,
        clientTimestamp: `${clientTimestamp}`,
        activityEntryTypeId: `14`,
        clientLongitude: `${long}`,
        clientLatitude: `${lat}`,
        matchItPickedNumber: `${pickedNumber}`,
        matchItGameName: `${gameName}`,
        matchItWeeklyGame: `${weeklyGame}`,
        matchItWin: `${win}`,
        matchItWinningCombination: `${winCombo}`,
        matchItWinningPrize: `${winPrize}`,
        matchItAdPlayed: `${ad}`,
      },
      { headers: { Authorization: `Bearer ${moneyToken}` } }
    )
    .then((res) => {
      console.log(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
  dispatch({
    type: SEND_LOCATION,
    payload: res,
  });
};

export const fetchUserInfoAction = (payload) => async (dispatch) => {
  const {
    email,
    mobileClientID,
    timezone,
    token,
    test,
    atgmembers,
    long,
    lat,
  } = payload.urlOriginState;
  const res = await axios.get(
    `${
      test ? baseURL_staging : baseURL
    }/api/user/login?email=${email}&mobileClientID=${mobileClientID}&timezone=${timezone}&token=${token}&atgmembers=${atgmembers}&long=${long}&lat=${lat}`
  );
  dispatch({
    type: FETCH_USER_INFO,
    payload: res.data,
  });
};

export const fetchExistingUserInfoAction = (payload) => async (dispatch) => {
  dispatch({
    type: FETCH_EXISTING_USER_INFO,
    payload,
  });
};

export const displayAllgame = (payload) => async (dispatch) => {
  const { id } = payload;
  const test = JSON.parse(sessionStorage.getItem("auth")).test;
  const rotate = JSON.parse(sessionStorage.getItem("auth")).rotate;
  const res = await axios.get(
    `${test ? baseURL_staging : baseURL}/api/games/${id}${
      test && rotate === "true" ? "/" + 1 : ""
    }`
    // `http://matchit.test/api/game/${id}`
  );
  dispatch({
    type: DISPLAY_ALL_GAME,
    payload: res.data,
    loading: true,
  });
};

export const postClickedNumbers = (payload, callback) => async (dispatch) => {
  const { drawId, apiToken, isMatched, id, user_id, timeValue } = payload;
  const game_ads_id = JSON.parse(sessionStorage.getItem("adsState"));
  const adsNumber = JSON.parse(sessionStorage.getItem("adsNumber"));
  const test = JSON.parse(sessionStorage.getItem("auth")).test;

  const res = await axios.post(
    `${
      test ? baseURL_staging : baseURL
    }/api/user/save-draw?draw_id=${drawId}&draw_number=${timeValue}&game_id=${id}&is_match=${isMatched}&api_token=${apiToken}&user_id=${user_id}&game_ad_id=${
      // isMatched === 1 ? null : parseInt(game_ads_id[adsNumber].id)
      parseInt(game_ads_id[adsNumber].id)
    }`
  );
  await dispatch({
    type: PICKED_NUMBERS,
    payload: res.data,
    isLoading: true,
    matchForTiles: true,
  });

  callback();
};

export const fecthSingleGame = (payload) => async (dispatch) => {
  const { id, user_id, timeValue, drawPosition, drawId, status } = payload;
  const gameSSId = sessionStorage.getItem("gameId");
  const test = JSON.parse(sessionStorage.getItem("auth")).test;

  const res = await axios.get(
    `${test ? baseURL_staging : baseURL}/api/game/${
      id || parseInt(gameSSId)
    }/${user_id}`
    // `http://matchit.test/api/game/${id}/${user_id}`
  );

  const { data } = res.data;
  if (timeValue) {
    dispatch({
      type: TICK_TIME,
      payload: data,
      timeValue,
      drawPosition,
      drawId,
      id,
      user_id,
      loading: true,
      status,
    });
  } else {
    dispatch({
      type: GET_SINGLE_GAME_DATA,
      payload: data,
      loading: true,
      status,
    });
  }
};

export const removeChooseState = () => {
  return {
    type: REMOVE_STATE_CHOOSE,
  };
};

export const chooseMySelf = (payload) => {
  const { game_id, mySelf } = payload;
  return {
    type: CHOOSE_MYSELF,
    game_id,
    mySelf,
  };
};

export const clearChooseMySelfGameId = () => {
  return {
    type: CLEAR_CHOOSE_MYSELF_GAME_ID,
  };
};

export const chooseForMe = (payload) => async (dispatch) => {
  const { game_id, api_token, user_id } = payload;
  const test = JSON.parse(sessionStorage.getItem("auth")).test;

  await axios.post(
    `${
      test ? baseURL_staging : baseURL
    }/api/user/choose-for-me?game_id=${game_id}&user_id=${user_id}&api_token=${api_token}`
  );
  const res = await axios.get(
    `${test ? baseURL_staging : baseURL}/api/game/${game_id}/${user_id}`
  );
  dispatch({
    type: CHOOSE_FOR_ME,
    payload: res,
    loadingGame: true,
  });
};

export const chooseForMePattern = (payload) => async (dispatch) => {
  const test = JSON.parse(sessionStorage.getItem("auth")).test;
  const { game_id, user_id, api_token, id } = payload;
  const res = await axios({
    method: "post",
    url: `${
      test ? baseURL_staging : baseURL
    }/api/user/generate-winning-combination`,
    data: {
      game_id: game_id,
      user_id: user_id,
      api_token: api_token,
      winning_combination_id: id,
    },
  });
  dispatch({
    type: GET_CHOOSE_FOR_ME_PATTERN,
    payload: res.data,
    game_id: game_id,
  });
};

export const setFalseToLoading = () => {
  return {
    type: SET_FALSE_THE_LOADING,
  };
};

export const pickedTotalNumbers = (payload) => async (dispatch) => {
  const { total_numbers, user_choice, userId, apiToken } = payload;
  const gameSSId = sessionStorage.getItem("gameId");
  const test = JSON.parse(sessionStorage.getItem("auth")).test;
  await axios.post(
    `${
      test ? baseURL_staging : baseURL
    }/api/user/choose-myself?user_id=${userId}&api_token=${apiToken}&player_picks=${total_numbers}&game_id=${
      user_choice.game_id || gameSSId
    }`
  );

  const res = await axios.get(
    `${test ? baseURL_staging : baseURL}/api/game/${
      user_choice.game_id || gameSSId
    }/${userId}`
  );
  dispatch({
    type: GET_SINGLE_GAME_DATA_CHOOSE_MYSELF,
    payload: res,
    loadingGame: true,
  });
};

export const idleTimeAction = (payload) => {
  const { interValToAction } = payload;
  return {
    type: IDLE_TIME,
    interValToAction,
  };
};

export const donePopUp = (payload) => {
  const { playsNow } = payload;
  return {
    type: DONE_POPUP,
    playsNow,
  };
};

export const chooseYourGameModal = () => {
  return {
    type: CHOOSE_YOUR_GAME_MODAL,
  };
};
export const chooseYourGameModalTrue = () => {
  return {
    type: CHOOSE_YOUR_GAME_MODAL_TRUE,
  };
};
export const clearChooseForMePattern = () => {
  return {
    type: CLEAR_CHOOSE_FOR_ME_PATTERN,
  };
};
export const clearChooseForMePatternSecond = () => {
  return {
    type: CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND,
  };
};
export const clearChooseForMePatternSecondFalse = () => {
  return {
    type: CLEAR_CHOOSE_FOR_ME_PATTERN_SECOND_FALSE,
  };
};
export const doneChoosePattern = () => {
  return {
    type: DONE_CHOOSE_PATTERN,
  };
};
export const closeChoosePattern = () => {
  return {
    type: CLOSE_CHOOSE_PATTERN,
  };
};

export const clearWinnersData = () => {
  return {
    type: CLEAR_WINNERS_DATA_FROM_CHOOSEFORME_STATE,
  };
};

// Background Random Number
export const changeBackground = (number) => {
  return {
    type: CHANGE_BACKGROUND,
    number,
  };
};

export const changeWinnerData = (payload) => {
  return {
    type: PICKED_NUMBERS,
    isLoading: payload.isLoading,
    matchForTiles: payload.matchForTiles,
    payload: [payload.data],
  };
};

export const applyTranslation = (translations) => {
  return {
    type: TRANSLATION,
    translations,
  };
};
