import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fecthSingleGame,
  postClickedNumbers,
  removeChooseState,
  setFalseToLoading,
  setFirstClick,
  setFirstClickStatus,
  setFirstClickAdsMatchStatus,
  setFirstClickDelay,
  clearChooseForMePattern,
  closeChoosePattern,
  sendUserLocationAction,
  baseURL,
  baseURL_staging,
  changeWinnerData
} from "../../actions/actions";
import authHOC from "../HOC/AuthHOC";
import Menu from "../layout/Menu";
import ChooseYourGame from "../modal/ChooseYourGame";
import HeartShape from "./HeartShape";
import PickNumbers from "./PickNumbers";
import Winners from "../modal/Winners";

import axios from "axios";

class Game extends Component {
  state = {
    timeValueGlobal: "temp",
    isLoading: true,
    clickedClass: false,
    gameIsLoading: true,
    oneTimeCallOnly: true,
    clickedBack: false,
    isMatchedBack: false,
    timeOut: null,
    secondClickChooseForMeLateRender: false,
    secondClickForNothing: false,
    drawIdHandle: 0,
    goodTryPopUp: false,
    congratulationsPopUp: false,
    redirectStillPlaysAds: false,
    notAvailable: false,
    bgIntervalState: 0,
    bg: [
      {
        id: 0,
        color: "test",
        image: "/images/gamebg.jpg"
      },
      {
        id: 1,
        color: "test",
        image: "/images/gamebg-2.jpg"
      },
      {
        id: 2,
        color: "test",
        image: "/images/gamebg-3.jpg"
      },
      {
        id: 3,
        color: "test",
        image: "/images/gamebg-4.jpg"
      },
      {
        id: 4,
        color: "test",
        image: "/images/gamebg-5.jpg"
      },
      {
        id: 5,
        color: "test",
        image: "/images/gamebg-6.jpg"
      },
      {
        id: 6,
        color: "test",
        image: "/images/gamebg-7.jpg"
      },
      {
        id: 7,
        color: "test",
        image: "/images/gamebg-8.jpg"
      },
      {
        id: 8,
        color: "test",
        image: "/images/gamebg-9.jpg"
      },
      {
        id: 9,
        color: "test",
        image: "/images/gamebg-10.jpg"
      },
      {
        id: 10,
        color: "test",
        image: "/images/gamebg-11.jpg"
      },
      {
        id: 11,
        color: "test",
        image: "/images/gamebg-12.jpg"
      },
      {
        id: 12,
        color: "test",
        image: "/images/gamebg-13.jpg"
      },
      {
        id: 13,
        color: "test",
        image: "/images/gamebg-14.jpg"
      },
      {
        id: 14,
        color: "test",
        image: "/images/gamebg-15.jpg"
      },
      {
        id: 15,
        color: "test",
        image: "/images/gamebg-16.jpg"
      },
      {
        id: 16,
        color: "test",
        image: "/images/gamebg-17.jpg"
      },
      {
        id: 17,
        color: "test",
        image: "/images/gamebg-18.jpg"
      },
      {
        id: 18,
        color: "test",
        image: "/images/gamebg-19.jpg"
      },
      {
        id: 19,
        color: "test",
        image: "/images/gamebg-20.jpg"
      },
      {
        id: 20,
        color: "test",
        image: "/images/gamebg-21.jpg"
      },
      {
        id: 21,
        color: "test",
        image: "/images/gamebg-22.jpg"
      },
      {
        id: 22,
        color: "test",
        image: "/images/gamebg-23.jpg"
      },
      {
        id: 23,
        color: "test",
        image: "/images/gamebg-24.jpg"
      }
    ],

    win: false,
    winCombo: "",
    winPrize: ""
  };

  timeOut = true;
  bgInterval = 0;

  rotateBg = () => {
    if (document.getElementById("backgroundInterval")) {
      let e = document.getElementById("backgroundInterval"),
        { bg } = this.state,
        // bgImage = bg[Math.floor(Math.random() * bg.length)],
        bgImage = bg[this.props.backgroundRandomNumber];

      e.style.backgroundImage = `url('${bgImage.image}')`;
      e.classList.add(`${bgImage.color}`);
    }
  };

  componentDidMount() {
    const { id, user_id } = this.props.HOC_Params.params;
    this.props.HOC_SecuredAuthMethod();
    if (this.props.onPageRefresh === "") {
      this.props.pageRefreshes({ id, user_id });
    }
    this.loadingSetToFalse();

    const rotateStatus = JSON.parse(sessionStorage.getItem("auth")).rotate;
  }

  componentDidUpdate() {
    // if (rotateStatus === "true") {
    //   this.rotateBg();
    // }
    this.rotateBg();
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.firstClickStatus === false) {
      this.props.setFirstClickDelay({ setDelay: false });
    }
  }

  loadingSetToFalse() {
    if (this.timeOut === true) {
      setTimeout(() => {
        this.setState({
          clickedClass: false,
          gameIsLoading: false,
          isLoading: false
        });
      }, 1500);
      clearTimeout(this.timeOut);
    }
  }
  componentWillUnmount() {
    this.timeOut = false;
    clearTimeout(this.timeOut);
    clearInterval(this.bgInterval);
  }

  iosTrigger = () => {
    sessionStorage.setItem("iosTrigger", true);
  };

  handleClick = async (
    { id, user_id, apiToken, name, number_picking_cycle },
    e
  ) => {
    e.persist();
    const timeValue = e.target.children[1].getAttribute("value");
    const drawId = e.target.children[1].getAttribute("numberid");
    const timeName = e.target.children[1].getAttribute("name");
    const drawPosition = e.target.children[1].getAttribute("isMatched");

    const gameName = name,
      weeklyGame = number_picking_cycle === 1 ? false : true,
      pickedNumber = timeValue;

    console.log(pickedNumber + " Selected Num");
    console.log(weeklyGame + " Weekly Game");
    console.log(gameName + " Game Name");

    this.setState({
      secondClickForNothing: true,
      drawIdHandle: parseInt(drawId),
      notAvailable: false
    });
    e.target.classList.add("lds-spinner");
    setTimeout(() => {
      e.target.classList.remove("lds-spinner");
    }, 1500);

    const singleGameVals = this.props.singleGame.picked_numbers.map(value => {
      return value.number;
    });
    const isMatched = singleGameVals.includes(parseInt(timeValue)) ? 1 : 0;

    this.props.clickTime({
      id,
      user_id,
      timeName,
      timeValue,
      drawId,
      drawPosition
    });

    this.props.postClickedNumbers(
      {
        id,
        user_id,
        timeValue,
        drawId,
        apiToken,
        isMatched
      },
      () => {
        console.log("Callback Here!");

        if (this.props.winners[0].data.winners != "") {
          this.setState({
            win: true,
            winCombo: this.props.winners[0].data.winners.name,
            winPrize: this.props.winners[0].data.winners.prize
          });
        }

        const clientTimestamp = new Date()
            .toISOString()
            .substr(0, 19)
            .replace("T", " "),
          mobileClientId = JSON.parse(sessionStorage.getItem("authFullInfo"))
            .data.mobile_id,
          lat = sessionStorage.getItem("lat"),
          long = sessionStorage.getItem("long"),
          win = this.state.win,
          winCombo = this.state.winCombo,
          winPrize = this.state.winPrize,
          ad = sessionStorage.getItem("adsLink");

        console.log("Check winners");
        console.log(this.props.checkWinner.winners);

        this.props.sendLocation({
          mobileClientId,
          clientTimestamp,
          gameName,
          pickedNumber,
          weeklyGame,
          long,
          lat,
          win,
          winCombo,
          winPrize,
          ad
        });
      }
    );
    // Handle ads state here
    const adsNum = sessionStorage.getItem("adsNumber");

    if (adsNum === "0") {
      sessionStorage.removeItem("adsNumber");
      sessionStorage.setItem("adsNumber", 1);
    } else if (adsNum === "1") {
      sessionStorage.removeItem("adsNumber");
      sessionStorage.setItem("adsNumber", 0);
    }
    //End Handle ads state here

    this.props.setFirstClickStatus();

    if (this.props.firstClickAdsMatch === 0) {
      this.setState({ goodTryPopUp: true, redirectStillPlaysAds: true });
      this.props.setFirstClickAdsMatchStatus();
      const adsRedirected = sessionStorage.getItem("adsRedirected");
      if (adsRedirected === "false") {
        const adsLink = sessionStorage.getItem("adsLink");
        if (this.timeOut === true) {
          this.timeout = setTimeout(() => {
            window.open(`${adsLink}`, "_blank");
            sessionStorage.removeItem("adsLink");
            sessionStorage.setItem("adsRedirected", "true");
            this.setState({
              goodTryPopUp: false,
              redirectStillPlaysAds: false
            });
          }, 6000);
          clearTimeout(this.timeOut);
        }
      }
    } else {
      this.setState({ congratulationsPopUp: true });
      if (this.state.timeOut == null || false) {
        setTimeout(() => {
          this.setState({ congratulationsPopUp: false });
        }, 6000);
      }
      clearTimeout(this.timeOut);
    }

    // if (
    //   this.props.winners &&
    //   this.props.winners[0].status === "success" &&
    //   this.props.winners[0].data.winners != ""
    // ) {
    //   this.setState({
    //     win: true,
    //     winCombo: this.props.winners[0].data.winners.name,
    //     winPrize: this.props.winners[0].data.winners.prize
    //   });
    // }

    // const clientTimestamp = new Date()
    //     .toISOString()
    //     .substr(0, 19)
    //     .replace("T", " "),
    //   mobileClientId = JSON.parse(sessionStorage.getItem("authFullInfo")).data
    //     .mobile_id,
    //   lat = sessionStorage.getItem("lat"),
    //   long = sessionStorage.getItem("long"),
    //   win = this.state.win,
    //   winCombo = this.state.winCombo,
    //   winPrize = this.state.winPrize,
    //   ad = sessionStorage.getItem("adsLink");

    // console.log(win + " " + winCombo + " " + winPrize + " " + ad);

    // this.props.sendLocation({
    //   mobileClientId,
    //   clientTimestamp,
    //   gameName,
    //   pickedNumber,
    //   weeklyGame,
    //   long,
    //   lat,
    //   win,
    //   winCombo,
    //   winPrize,
    //   ad
    // });
  };

  clickNumber = async payload => {
    const { drawId, apiToken, isMatched, id, user_id, timeValue } = payload;
    const game_ads_id = JSON.parse(sessionStorage.getItem("adsState"));
    const adsNumber = JSON.parse(sessionStorage.getItem("adsNumber"));
    const test = JSON.parse(sessionStorage.getItem("auth")).test;

    const res = await axios.post(
      `${
        test ? baseURL_staging : baseURL
      }/api/user/save-draw?draw_id=${drawId}&draw_number=${timeValue}&game_id=${id}&is_match=${isMatched}&api_token=${apiToken}&user_id=${user_id}&game_ad_id=${
        // isMatched === 1 ? null : parseInt(game_ads_id[adsNumber].id)
        parseInt(game_ads_id[adsNumber].id)
      }`
    );

    this.props.postWinnerData({
      isLoading: true,
      matchForTiles: true,
      data: res.data
    });

    // this.props.postClickedNumbers({
    //   id,
    //   user_id,
    //   timeValue,
    //   drawId,
    //   apiToken,
    //   isMatched
    // });

    // dispatch({
    //   type: PICKED_NUMBERS,
    //   payload: res.data,
    //   isLoading: true,
    //   matchForTiles: true
    // });
  };

  onFirstClick = ({ id, user_id, apiToken, clicked }, e) => {
    e.persist();

    var isSafari = false,
      ua = navigator.userAgent.toLowerCase();

    try {
      isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function(p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"]);
    } catch (err) {}
    isSafari =
      isSafari ||
      (ua.indexOf("safari") !== -1 &&
        !(ua.indexOf("chrome") !== -1) &&
        ua.indexOf("version/") !== -1);

    isSafari && this.iosTrigger();

    if (!sessionStorage.getItem("adsNumber")) {
      sessionStorage.setItem("adsNumber", 0);
    }
    e.target.classList.add("lds-spinner");
    if (this.timeOut === true) {
      setTimeout(() => {
        e.target.classList.remove("lds-spinner");
      }, 4000);

      this.timeOut = setTimeout(() => {
        this.setState({ clickedClass: false });
      }, 4000);
      clearTimeout(this.timeOut);
    }

    this.setState({ secondClickChooseForMeLateRender: true });
    if (e.target.children[1]) {
      const timeValue = e.target.children[1].getAttribute("value");
      const drawId = e.target.children[1].getAttribute("numberid");
      const singleGameVals = this.props.singleGame.picked_numbers.map(value => {
        return value.number;
      });

      const isMatched = singleGameVals.includes(parseInt(timeValue)) ? 1 : 0;
      const isMatchedBack = singleGameVals.includes(parseInt(timeValue))
        ? true
        : false;
      this.setState({
        clickedClass: true,
        clickedBack: clicked,
        isMatchedBack: isMatchedBack,
        timeValueGlobal: timeValue
      });

      this.props.setFirstClick({ drawId, isMatched, id });
    }
  };

  onNA = () => {
    this.setState({ notAvailable: true });
  };

  formatDate = string => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  datetoday = () => {
    let today = new Date();

    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = new Date(`${yyyy}-${mm}-${dd}`);
    return today.getTime();
  };

  onBack = () => {
    clearTimeout(this.timeOut);
    this.props.onBackClicked();
    this.props.setFalseToLoading();
    this.props.setFirstClickStatus();
    this.props.clearChooseForMePattern();
    this.props.closeChoosePattern();
    // this.props.setFirstClickStatus();
    this.setState({ oneTimeCallOnly: false });
    clearInterval(this.bgInterval);
  };

  render() {
    const { id } = this.props.HOC_Params.params;

    var ua = navigator.userAgent.toLowerCase();
    var isSafari = false;
    try {
      isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function(p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"]);
    } catch (err) {}
    isSafari =
      isSafari ||
      (ua.indexOf("safari") !== -1 &&
        !(ua.indexOf("chrome") !== -1) &&
        ua.indexOf("version/") !== -1);

    return (
      <React.Fragment>
        {(() => {
          if (
            sessionStorage.getItem("auth") &&
            (this.state.clickedBack === false ||
              this.state.isMatchedBack === true)
          ) {
            const { email, mobileClientID, timezone, token } = JSON.parse(
              sessionStorage.getItem("auth")
            );
            return (
              <Link
                to={`/?email=${email}&mobileClientID=${mobileClientID}&timezone=${timezone}&token=${token}`}
                className="nav__header"
                onClick={this.onBack}
              >
                <i className="ion ion-ios-arrow-back"></i>
                Back
              </Link>
            );
          }
        })()}

        <Menu />

        {(() => {
          if (this.state.isLoading || this.state.gameIsLoading) {
            return (
              <div className="loadingScreen">
                <img src="/images/choose-myself.svg" alt="" />
                <p>
                  {
                    JSON.parse(sessionStorage.getItem("gameTranslations"))
                      .gameLoading
                  }
                </p>
              </div>
            );
          } else if (this.props.HOC_UserALLInfo) {
            if (
              this.props.singleGame &&
              this.props.singleGame.board_orders &&
              this.props.singleGame.four_numbers_draw &&
              this.props.singleGame.all_draw_numbers
            ) {
              const vals = this.props.singleGame.picked_numbers.map(value => {
                return value.number;
              });
              const tvg = this.state.timeValueGlobal;
              const allGameDataFromSession = JSON.parse(
                sessionStorage.getItem("allGameData")
              );
              return [this.props.singleGame].map(value => (
                <div
                  className="container text-center gameboard"
                  id="backgroundInterval"
                  key={id}
                >
                  <div>
                    <div className="ads__cont">
                      <h3>Match-It</h3>
                      {(() => {
                        sessionStorage.setItem(
                          "adsState",
                          JSON.stringify(this.props.singleGame.game_ads)
                        );
                        sessionStorage.setItem(
                          "userId",
                          JSON.stringify(this.props.HOC_UserALLInfo.data.id)
                        );
                        sessionStorage.setItem(
                          "gameId",
                          JSON.stringify(value.game.id)
                        );
                        if (
                          (!vals.includes(parseInt(tvg)) ||
                            vals.includes(parseInt(tvg))) &&
                          tvg !== "temp"
                        ) {
                          if (this.props.singleGame.game_ads) {
                            setTimeout(() => {
                              this.props.history.push("/gameads", {
                                res: this.props.singleGame.game_ads,
                                userId: this.props.HOC_UserALLInfo.data.id,
                                gameId: value.game.id
                              });
                            }, 4000);

                            return (
                              <p className="ads__text">
                                <img src="/images/video.svg" alt="" />
                                {isSafari
                                  ? JSON.parse(
                                      sessionStorage.getItem("gameTranslations")
                                    ).sponsorAdIOS
                                  : JSON.parse(
                                      sessionStorage.getItem("gameTranslations")
                                    ).sponsorAd}
                              </p>
                            );
                          }
                        }
                      })()}

                      {this.state.goodTryPopUp ? (
                        <p className="ads__text">
                          <img src="/images/sad.svg" alt="" />
                          {
                            JSON.parse(
                              sessionStorage.getItem("gameTranslations")
                            ).redirectToSite
                          }
                          <small>
                            {
                              JSON.parse(
                                sessionStorage.getItem("gameTranslations")
                              ).closeSponsor
                            }
                          </small>
                        </p>
                      ) : null}

                      {this.state.congratulationsPopUp ? (
                        <p className="ads__text">
                          <img src="/images/confetti.svg" alt="" />{" "}
                          {
                            JSON.parse(
                              sessionStorage.getItem("gameTranslations")
                            ).congratulations
                          }
                        </p>
                      ) : null}

                      {this.state.notAvailable ? (
                        <p className="ads__text ads__announcement">
                          <img src="/images/cards.svg" alt="" />
                          {
                            JSON.parse(
                              sessionStorage.getItem("gameTranslations")
                            ).previousNumber
                          }
                        </p>
                      ) : null}
                    </div>

                    <HeartShape
                      // handleClick={this.onTilesClick}
                      gameCycle={value.game.number_picking_cycle}
                      gameData={value.board_orders}
                      pickedNumbers={value.picked_numbers}
                      allDrawnNumbers={value.all_draw_numbers}
                      picking_numbers_from_allgames={
                        this.props.HOC_AllGameData.data
                          ? this.props.HOC_AllGameData.data.games
                          : allGameDataFromSession.data.games
                      }
                      userId={this.props.HOC_UserALLInfo.data.id}
                      apiToken={this.props.HOC_UserALLInfo.data.api_token}
                      email={this.props.HOC_UserALLInfo.data.email}
                      mobileId={this.props.HOC_UserALLInfo.data.mobile_id}
                      timezone={this.props.HOC_UserALLInfo.data.timezone}
                      gameId={value.game.id}
                    />

                    <PickNumbers
                      isGameStarted={
                        this.datetoday() >=
                        new Date(value.game.start_date).getTime()
                          ? true
                          : false
                      }
                      handleSecondClick={this.handleClick}
                      numbersDraw={value.four_numbers_draw}
                      start_date={this.formatDate(value.game.start_date)}
                      id={value.game.id}
                      name={value.game.name}
                      user_id={value.user.id}
                      apiToken={this.props.HOC_UserALLInfo.data.api_token}
                      boardOrders={value.board_orders}
                      pickedNumbers={value.picked_numbers}
                      clickedClass={this.state.clickedClass}
                      firstClickStatus={this.props.firstClickStatus}
                      firstClickDrawId={this.props.firstClickDrawId}
                      handleFirstClick={this.onFirstClick}
                      handleNA={this.onNA}
                      firstClickDelay={this.props.firstClickDelay}
                      arrFirstClick={this.props.arrFirstClick}
                      drawIdHandle={this.state.drawIdHandle}
                      secondClickForNothing={this.state.secondClickForNothing}
                      secondClickChooseForMeLateRender={
                        this.state.secondClickChooseForMeLateRender
                      }
                      redirectStillPlaysAds={this.state.redirectStillPlaysAds}
                      number_picking={value.game.number_picking}
                      number_picking_cycle={value.game.number_picking_cycle}
                    />
                    {/* 
                    {this.props.checkWinner && this.props.checkWinner.winners &&
                      this.props.checkWinner.winners[0].data.winners && this.props.checkWinner.winners[0].data.winners.data && this.props.checkWinner.winners[0].data.winners.data.winners ? (
                        <Winners res={this.props.checkWinner.winners[0].data.winners} />
                      ) : null} */}

                    {this.props.checkWinner &&
                    this.props.checkWinner.winners &&
                    this.props.checkWinner.winners[0].status === "success" &&
                    this.props.checkWinner.winners[0].data.winners != "" ? (
                      <Winners
                        res={this.props.checkWinner.winners[0].data.winners}
                      />
                    ) : null}
                  </div>

                  <div className="ads__cont ads__cont-bot">
                    <h4>
                      {" "}
                      {
                        JSON.parse(sessionStorage.getItem("gameTranslations"))
                          .iPlay
                      }{" "}
                    </h4>
                    <h4>
                      {" "}
                      {
                        JSON.parse(sessionStorage.getItem("gameTranslations"))
                          .playForOthers
                      }{" "}
                    </h4>
                  </div>
                </div>
              ));
            } else if (
              this.props.singleGame &&
              this.props.singleGame.board_orders === "" &&
              this.props.singleGame.four_numbers_draw &&
              this.props.singleGame.all_draw_numbers
            ) {
              return <ChooseYourGame />;
            }
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tiles: state.game.tiles,
  singleGame: state.game.singleGameData,
  onPageRefresh: state.game.isStatus,
  firstClickStatus: state.game.firstClickStatus,
  firstClickDrawId: state.game.firstClickDrawId,
  firstClickAdsMatch: state.game.firstClickAdsMatch,
  firstClickDelay: state.game.firstClickDelay,
  arrFirstClick: state.game.arrFirstClick,
  checkWinner: state.chooseForMeOrNot,
  winners: state.chooseForMeOrNot.winners,
  backgroundRandomNumber: state.backgroundReducer.backgroundRandomNumber
});

const mapDispatchToProps = dispatch => ({
  pageRefreshes: payload => dispatch(fecthSingleGame(payload)),
  clickTime: payload => dispatch(fecthSingleGame(payload)),
  postClickedNumbers: (payload, callback) =>
    dispatch(postClickedNumbers(payload, callback)),
  onBackClicked: () => dispatch(removeChooseState()),
  setFalseToLoading: () => dispatch(setFalseToLoading()),
  setFirstClick: payload => dispatch(setFirstClick(payload)),
  setFirstClickStatus: () => dispatch(setFirstClickStatus()),
  setFirstClickAdsMatchStatus: () => dispatch(setFirstClickAdsMatchStatus()),
  setFirstClickDelay: payload => dispatch(setFirstClickDelay(payload)),
  clearChooseForMePattern: () => dispatch(clearChooseForMePattern()),
  closeChoosePattern: () => dispatch(closeChoosePattern()),
  sendLocation: payload => dispatch(sendUserLocationAction(payload)),
  postWinnerData: payload => dispatch(changeWinnerData(payload))
});

export default authHOC(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Game))
);
