import React, {PureComponent} from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import authHOC from '../HOC/AuthHOC';

class GameGuide extends PureComponent{
  componentDidMount() {
    this.props.HOC_SecuredAuthMethod();
  }

  render() {
    const { email, mobileClientID, timezone, token } = JSON.parse(
            sessionStorage.getItem("auth")
          );
    let ua = navigator.userAgent.toLowerCase(),
        isSafari = false;

    try {
      isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function(p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"]);
    } catch (err) {}
    isSafari =
      isSafari ||
      (ua.indexOf("safari") !== -1 &&
        !(ua.indexOf("chrome") !== -1) &&
        ua.indexOf("version/") !== -1);
        
        let userAgentString = navigator.userAgent;
        let safariAgent = userAgentString.indexOf("Safari") > -1; 
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        if ((chromeAgent) && (safariAgent)) safariAgent = false; 

    return (
      <React.Fragment>
  
        <div className="nav__header">
          <Link to={`/?email=${email}&mobileClientID=${mobileClientID}&timezone=${timezone}&token=${token}`} className="nav__header">
            <i className="ion ion-ios-arrow-back"></i>
            Back
          </Link>
        </div>
  
        <div className="player-wrapper">
          <video
            id="react-player"
            controls={true}
            autoPlay
            playsInline
            preload="auto"
            onPlay={this.onReady}
            muted={isSafari ? true : false}
          >
            <source id="video" src="http://d2td0we64v4cp8.cloudfront.net/12sec/matchit-video-gamelist.mp4" type="video/mp4" />
          </video>
      </div>
      </React.Fragment>
    );
  }

};

export default authHOC(GameGuide);
