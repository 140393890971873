import React, {PureComponent} from "react";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends PureComponent {
  componentDidMount() {
    window.history.pushState(null, null, this.props.location.pathname);
    window.onpopstate = function() {
      window.history.go(1);
    };
  }

  render() {
    return (
      <div>
        <h1 className="text-center text-light mt-5 display-4">
          Something went wrong!
        </h1>
        <p className="text-center text-light mt-3">
          Please open the game through your app to play!
        </p>
      </div>
    );
  }

};

export default withRouter(ErrorBoundary);
